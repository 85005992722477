import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef  } from '@angular/material/dialog';
 
 import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-popup-dialog',
  templateUrl: './product-popup-dialog.component.html',
  styleUrls: ['./product-popup-dialog.component.css']
})
export class ProductPopupDialogComponent {
  isSale: boolean | undefined;
  isSubscribe: boolean | undefined;
  mrpPrice: number | undefined;
  priceToShow!: number;
  variationDisplay: string | undefined;
  selectedVariation: any;
  variations: any[] = [];
 
  productDetails: any = {};
  productVariations: any[] = [];
 dataFromPreviousComponent: string | undefined;
  mainImage: string = '';
  selectedIndex: number = 0;
  selectedSize = '50 ML';
  isExpanded = false;
  variationid:any;
  productImages: { src: string[] } = { src: [] };
  sanitizedDescription: SafeHtml = '';
  activeTab = 'description';
  product_list_Id: any;
  additionaldetails: any;
  showMore: boolean = false;
  startIndex: number = 0;
  endIndex: number = 4;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  numVisible: number = 1;
 
 
     // Image sources for controls
     prevImageSrc: string = '../../assets/icon/carousel-down.svg';
     nextImageSrc: string = '../../assets/icon/carousel-down.svg';
 
  @ViewChild('tabSection', { static: false }) tabSection!: ElementRef;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private elementRef:ElementRef,
    private dialogRef: MatDialogRef<ProductPopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  )
  {
    this.ProductListCall(data.id);
    this.dataFromPreviousComponent =data.datafrom
    this.variationid  = data.variationId;
 
  }

  ngOnInit(): void {
    this.ProductListCall(this.data.id);
  }

  getKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }

  selectImage(index: number): void {
    this.mainImage = this.productImages.src[index];
    this.selectedIndex = index;
  }

  prevImage(): void {
   
    if (this.startIndex > 0) {
      this.startIndex--;
      this.endIndex--;
    }
    // if (this.startIndex > 0) {
    //   this.startIndex -= this.numVisible;
    // }
  }
 
  nextImage() {
    if (this.endIndex < this.productImages.src.length) {
      this.startIndex++;
      this.endIndex++;
   
    }
    // if (this.startIndex + this.numVisible < this.productImages.src.length) {
    //   this.startIndex += this.numVisible;
    // }
  }
  get visibleImages() {
    return this.productImages.src.slice(this.startIndex, this.startIndex + this.numVisible);
  }
 
  hoverImage(control: string, isHover: boolean) {
    if (control === 'prev') {
      this.prevImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    } else if (control === 'next') {
      this.nextImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    }
  }
 
  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    } else {
      // this.startX = e.touches[0].pageX - el.offsetLeft;
      // this.startY = e.touches[0].pageY - el.offsetTop;
    }
 
 
 
    this.scrollLeft = el.scrollLeft;
    // setTimeout(() => { this.isDragging = true }, 300);
  }
 
  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    // setTimeout(() => { this.isDragging = false });
    this.mouseDown = false;
  }
 
  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    // e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    } else {
      // x = e.touches[0].pageX - el.offsetLeft;
      // y = e.touches[0].pageY - el.offsetTop;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }
 
 
  onScroll(e: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, child: any) {
    if ((child.clientWidth - el.scrollLeft) <= el.clientWidth + 1) {
      // this.loadmore();
 
      // if (this.showMoreReview && !this.reviewLoading) {
      //   this.reviewLoading = true;
      //   this.loadmore();
      // }
    }
  }
 
  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }
  addToCart(): void {
    // alert(Added ${this.selectedSize} of ${this.productDetails.name} to the cart!);
  }
  scrollToBottom(container: HTMLElement): void {
    container.scrollTop = container.scrollHeight;
  }

  toggleShowMore() {
    this.showMore = !this.showMore;

    const tabSectionPosition = this.tabSection.nativeElement.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: tabSectionPosition - 200, // Adjust this value (-100) to control how far up you want to scroll
      behavior: 'smooth'
    });

  }

  selectSize(variation: any): void {
    this.selectedVariation = variation;
    this.productDetails.selectedVariation = variation.productvariationid;
    this.priceToShow = this.getPriceToShow(variation);
    this.mrpPrice = variation.mrpprice; // Update mrpPrice to match the selected variation
  }

  getPriceToShow(variation: any): number {
    if (variation.issubscribe && variation.issale) {
      if (variation.dataFromPreviousComponent === 'bestSelling') {
        this.priceToShow = variation.sellingprice; // Set priceToShow when dataFromPreviousComponent is 'bestSelling'
      } else if (variation.dataFromPreviousComponent === 'subscribeProduct') {
        this.priceToShow = variation.priceupto7days; // Set priceToShow when dataFromPreviousComponent is 'subscribe'
      } else {
        this.priceToShow = variation.priceupto7days; // Default to priceupto7days if both issubscribe and issale are true
      }
    } else if (variation.issubscribe) {
      this.priceToShow = variation.priceupto7days; // Set priceToShow if only issubscribe is true
    } else if (variation.issale ) {
      this.priceToShow = variation.sellingprice; // Set priceToShow if only issale is true
    } else {
      this.priceToShow = variation.mrpprice; // Default to mrpPrice if neither issubscribe nor issale is true
    }
    return this.priceToShow; // Return the updated priceToShow
  }
 
  
  ProductListCall(subcategoryId?: string): void {
    const id = subcategoryId || '';
 
    this.http.get(`${environment.apiUrl}/products/get?id=${id}`).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const element = this.elementRef.nativeElement.querySelector('.scroll_top');
          if (element) {
            window.scroll(0, 0);
          }
 
          this.productDetails = data.products;
          this.productDetails.variations = [];  
           
          if (this.productDetails.productvariations && this.productDetails.productvariations.length > 0) {
 
 
            this.productDetails.productvariations.forEach((x: any) => {
 
              if (this.dataFromPreviousComponent === 'subscribeProduct' && x.issubscribe === true) {
                // Filter for subscribeProduct
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice, sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale:x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent
 
                });
              } else if (this.dataFromPreviousComponent === 'bestSelling' && x.issale === true) {
                // Filter for bestSelling
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice,
                  sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale:x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent
 
                });
              }else if(this.dataFromPreviousComponent === '' && x.issale === true){
              this.productDetails.variations.push({
                variationvalue: x.variationvalue,
                variationshortname: x.variationshortname,
                productvariationid: x.productvariationid,
                variationid: x.variationid,
                mrpprice: x.mrpprice, sellingprice: x.sellingprice,
                priceupto7days: x.priceupto7days,
                issale:x.issale,
                issubscribe: x.issubscribe,
                dataFromPreviousComponent: this.dataFromPreviousComponent

              });
            }
            })
            
            if (this.variationid) {
              const mathProductvariationId =  this.productDetails.variations.find(
                (variation: any) => variation.productvariationid === this.variationid
              );
              this.selectedVariation = mathProductvariationId;
 
            }else if ( this.productDetails.variations.length > 0) {
              this.selectedVariation =  this.productDetails.variations[0]; // Set the first variation as default
 
            }
             else {
              this.selectedVariation = this.productDetails.productvariations[0];
 
 
            }
           
            // Set the default variation (first one)
            // this.selectedVariation = this.productDetails.productvariations[0];
 
            // Calculate the default price to show based on the first variation
            this.getPriceToShow(this.selectedVariation);
            this.mrpPrice = this.selectedVariation.mrpprice;
          }
 
          // Other code for description and image handling
          if (this.productDetails.description) {
            this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(this.productDetails.description);
          } else {
            this.sanitizedDescription = '';
          }
 
          let x = data.products.additionaldetails;
 
          try {
            if (x && x.trim() !== '') {
              this.additionaldetails = JSON.parse(x);
            } else {
              this.additionaldetails = {}; // or any default value
            }
          } catch (e) {
            console.error("Error parsing additionaldetails:", e);
            this.additionaldetails = {}; // Handle the error by assigning a default value
          }
 
          this.http.get(`${environment.apiUrl}/uploads/list-by-datafor?dataFor=products&dataForId=${this.productDetails.id}`).subscribe((datas: any) => {
            if (datas.status === "SUCCESS") {
              this.productImages.src = [];
 
              datas.uploads.forEach((upload: any, index: number) => {
                this.http.get(`${environment.apiUrl}/uploads/get?id=${upload.id}`).subscribe((res: any) => {
                  if (res.status === "SUCCESS") {
                    this.productImages.src.push(res.uploads.base64data);
                    if (index === 0) { // Set the first image as the default main image
                      this.mainImage = this.productImages.src[0];
                      this.selectedIndex = 0;
                    }
                  }
                });
              });
            }
          });
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }

  navigateToProductPage(): void {
    this.router.navigate(['/product-pages', this.data.id]);
    this.dialogRef.close();
 
  }
 
}
