import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { ProductPopupDialogComponent } from '../product-popup-dialog/product-popup-dialog.component';
import { ProductService } from '../services/product.service';
import { Options } from '@angular-slider/ngx-slider';
import { Subscription } from 'rxjs';
 
@Component({
  selector: 'app-product-list-pages',
  templateUrl: './product-list-pages.component.html',
  styleUrls: ['./product-list-pages.component.css']
})
export class ProductListPagesComponent implements OnInit, OnDestroy {
  showProductList: boolean = true;
  showHr: boolean = false;
  showFilterSection: boolean = false;
  isGroceryMenuOpen: boolean = false;
  isFrozenFoodOpen: boolean = false;
  outOfStockIncluded: boolean = false;
  foodTypeIncluded: boolean = false;
  loadMoreRecentlyViewed: boolean = false;
  isLoadingRecentlyViewed: boolean = false;
  activeButton: number = 1;
  isSortMenuOpen: boolean = false;
  homeData: any;
  categoryKey: any;
  foodTypeList:any[] = [];
  categoryList: any[] = [];
  subCategories: any[] = [];
  subCategories_list: any[] = [];
  mainCategories: any[] = [];
  frozenFoodItems: string[] = [];
  foodCategories: string[] = [];
  isDropdownContentVisible: boolean[] = [];
 
  activeCategoryId: string = '';
  activeSubCategoryId: string = '';
  page: number = 1; // Initial page number
  count: number = 10;
  productsPerPage: number = 10;
  productList: any[] = [];
  selectedVariation: any;
  isLoggedIn: boolean = false;
  userId: string | null = null;
  add_cart: boolean = true;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
  isAvailability=false;
  isfoodType=false;
  isPriceVisible: boolean = false;
  minPrice: number = 0; // Minimum price
  maxPrice: number = 1000; // Maximum price
  sliderValue: number = 0; // Current value of the slider
  minValue: number = 0; // Minimum value for the slider
  maxValue: number = 1000; // Maximum value for the slider
  private subscription: Subscription = new Subscription();
  constructor(public auth: AuthService, public router: Router, private route: ActivatedRoute, private http: HttpClient, private productService: ProductService, private dialog: MatDialog, private elementRef: ElementRef, private cartService: CartService) {
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {
      if (data) {
        this.userId = data?.id;
        this.isLoggedIn = !!data;
      } else {
        this.isLoggedIn = false;  
      }
    });
  }
 
  ngOnInit(): void {
    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        const searchQuery = params['q'];
        this.categoryKey = params['category'];
        if (searchQuery) {
          this.ProductListCall(searchQuery, true);
        }
      })
    );
 
    this.categoriesListLevel_0(); // Fetch main categories
 
    // Subscribe to product data
    this.subscription.add(
      this.productService.getData().subscribe(data => {
        this.homeData = data;
 
        // Proceed only if homeData exists
        if (this.homeData) {
          const parentId = this.homeData.parentId;
 
          if (parentId) {
            this.categoriesListLevel_1(parentId);
          }
        } else {
          // Handle case where homeData is not set
          console.warn("homeData is not available.");
        }
      })
    );
  }
 
 
  ngOnDestroy(): void {
    this.subscription.unsubscribe(); // Clean up subscriptions
  }
 
 
  toggleFilterSection() {
    this.showProductList = !this.showProductList;
    this.showFilterSection = !this.showFilterSection;
  }
  onCategoryClick(item: any): void {
    // Toggle checkbox status when div is clicked
    item.isChecked = !item.isChecked;
 
    // Gather all selected categories
    const selectedCategories = this.subCategories_list
      .filter((category: any) => category.isChecked)
      .map((category: any) => category.id);
 
    // Call ProductListCall with selected categories
    this.ProductListCall(selectedCategories);
  }
 
  toggleSortMenu(): void {
    this.isSortMenuOpen = !this.isSortMenuOpen;
  }
 
  sortProducts(sortColumn: string, sort: string): void {
    this.ProductListCall([], false, sortColumn, sort);
  }
 
 
  openProductDialog(product: any, selectedVariationId: string): void {
 
    const variationId = this.selectedVariation || selectedVariationId;
 
    this.dialog.open(ProductPopupDialogComponent, {
      data: {
        id: product.id,
        datafrom: '',
        variationId: variationId
      },
      width: '90%',
      height: '90%',
      disableClose: true
    });
    this.selectedVariation = ''
  }
 
  grocery_click(productId: string, selectedVariation: string): void {
 
    const bestSelling = "bestSelling";
    const variationId = this.selectedVariation || selectedVariation;
 
    this.router.navigate(['/product-pages', productId], {
      state: { dataToPass: { datafrom: bestSelling, variationId: variationId } }
    });
 
  }
 
 
 
  //api call
  Category_Section(id: any, categorykey: any) {
    this.activeCategoryId = id;
    this.homeData = '';
    this.categoriesListLevel_1(id)
    this.categoryKeyCall(categorykey)
  }
 
  Category_menu(id: any, index: number) {
    this.categoriesListLevel_2(id)
    this.activeSubCategoryId = id;
    // this.ProductListCall( id);
  }
 
  toggleDropdownContent(event: MouseEvent, index: number, categoryId: any): void {
 
    event.stopPropagation();
 
    this.isDropdownContentVisible[index] = !this.isDropdownContentVisible[index];
    if (this.isDropdownContentVisible[index]) {
      for (let i = 0; i < this.isDropdownContentVisible.length; i++) {
        if (i !== index) {
          this.isDropdownContentVisible[i] = false;
        }
      }
    }
    if (this.isDropdownContentVisible[index]) {
      this.Category_menu(categoryId, index);
 
 
    }
 
  }
 
 
  categoriesListLevel_0(): void {
    const requestBody = {
      status: 1,
      parentId: '',
      level: '0',
    };
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
 
          // Scroll to the top of the page
          const element = this.elementRef.nativeElement.querySelector('.fixed-margin-top');
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest'
            });
          }
 
          // Only clear homeData if it's undefined or null
          if (this.categoryKey && !this.homeData) {
            const selectedCategory = data.categories_output.find((x: any) => x.categorykey === this.categoryKey);
            if (selectedCategory) {
              this.activeCategoryId = selectedCategory.id;
              this.categoriesListLevel_1(this.activeCategoryId);
              this.categoryKeyCall(this.categoryKey);
            }
          }
 
          // Set the main categories
          this.mainCategories = data.categories_output;
 
          // Handle homeData only if present
          if (this.homeData) {
            this.handleHomeData();
          }
        }
      }
    );
  }
 
  handleHomeData(): void {
    const parentId = this.homeData.parentId;
    this.categoriesListLevel_1(parentId);
  }
 
  categoriesListLevel_1(parentId: string): void {
    const requestBody = {
      status: 1,
      parentId: parentId,
      level: '1',
    };
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const element = this.elementRef.nativeElement.querySelector('.fixed-margin-top');
          if (element) {
            window.scroll(0, 0);
          }
          this.subCategories = data.categories_output;
          this.isDropdownContentVisible = new Array(this.subCategories.length).fill(false);
 
          if (this.subCategories.length > 0) {
            this.showHr = true;
          }
 
          if (this.homeData) {
            this.processHomeDataSubcategory();
          }
        }
      }
    );
  }
 
  processHomeDataSubcategory(): void {
    const subcategory_Id = this.homeData.subcategory_Id;
    const subCategoryIndex = this.subCategories.findIndex((x: any) => x.id === subcategory_Id);
 
    if (subCategoryIndex !== -1) {
      this.activeCategoryId = this.homeData.parentId;
      this.activeSubCategoryId = subcategory_Id;
      this.isDropdownContentVisible[subCategoryIndex] = true;
 
      this.categoriesListLevel_2(subcategory_Id);
    }
  }
 
  categoriesListLevel_2(parentId: string): void {
    const requestBody = {
      status: 1,
      parentId: parentId,
      level: '2',
    };
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.subCategories_list = data.categories_output;
          this.ProductListCall(this.subCategories_list[0].id);
        }
      }
    );
  }
 
 
  categoryKeyCall(categoryKey: string): void {
    const requestBody = {
      "status": 1,
      "categoryKey": categoryKey
    };
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.categoryList = data.categories_output;
          const categoryListIds: string[] = [];
          this.categoryList.forEach((category: any) => {
            const categoryList_id = category.id;
            categoryListIds.push(categoryList_id);
 
          });
          this.ProductListCall(categoryListIds);
        }
      },
    )
  }
 
 
  selectSize(id: any, variation: any): void {
    let productIndex = this.productList.findIndex((x: any) => x.id == id);
 
    if (productIndex != -1) {
      this.productList[productIndex].price = variation.priceToShow;
      this.productList[productIndex].originalPrice = variation.originalPrice;
      this.selectedVariation = variation.productvariationid;
    }
 
  }
  changePage(action: 'prev' | 'next' | number): void {
    if (action === 'prev' && this.page > 1) {
      this.page -= 1;
    } else if (action === 'next' && this.page < this.totalPages) {
      this.page += 1;
    } else if (typeof action === 'number') {
      this.page = action;
    }
 
    // Call the ProductList function with the new page
    this.loadMoreProducts();
  }
  loadMoreProducts(): void {
    const productIds: string[] = [];
    if (!this.isLoadingRecentlyViewed) {
      this.isLoadingRecentlyViewed = true;
      this.ProductListCall(productIds);
    }
  }
  get totalPages(): number {
    return Math.ceil(this.count / this.count);
  }
  get totalPagesArray(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  ProductListCall(query: string | string[], isSearchQuery: boolean = false, sortColumn: string = '', sort: string = ''): void {
 
    const requestBody: any = {
      status: 1,
      isSale: true,
      page: this.page,
      count: this.count,
     
    };
    // this.productList = this.getProductsForPage(this.page); // Fetch products based on current page
    // this.isLoadingRecentlyViewed = false;
    // Check if search query or category ids
    if (isSearchQuery) {
      requestBody.q = query as string;
    }  else if (this.foodTypeList.length > 0) {
      requestBody.foodType = this.foodTypeList; // Include only if selected
    }else if (query.length > 0) { // Only add categoryIds if the array is not empty
      requestBody.categoryIds = query as string[];
    }
   
    if (this.minPrice !== null) {
      requestBody.minPrice = this.minPrice;
    }
 
    if (this.maxPrice !== null) {
      requestBody.maxPrice = this.maxPrice;
    }
 
    if (this.userId) {
      requestBody.userId = this.userId;
    }
 
    if (this.outOfStockIncluded) {
      requestBody.outOfStock = 'include';
    }
    if (sortColumn && sort) {
      requestBody.sortColumn = sortColumn;
      requestBody.sort = sort;
    }
    // API call to fetch products
    this.http.post(environment.apiUrl + `/products/list`, requestBody).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const products = data.products_output;
 
          products.forEach((product: any) => {
           
            if (product.productvariations && product.productvariations.length > 0) {
              const firstVariation = product.productvariations[0];
 
              product.originalPrice = firstVariation.mrpprice;
              product.price = firstVariation.sellingprice;
              product.variationvalue = firstVariation.variationvalue;
              product.variationshortname = firstVariation.variationshortname;
              product.selectedVariation = firstVariation.productvariationid;
              product.cartitemsquantity = firstVariation.cartitemsquantity;
              product.cartid = firstVariation.cartid;
             
              product.variations = product.productvariations.map((variation: any) => ({
                originalPrice: variation.mrpprice,
                priceToShow: variation.sellingprice,
                variationvalue: variation.variationvalue,
                variationshortname: variation.variationshortname,
                productvariationid: variation.productvariationid,
              }));
             
             
            }
 
            // Fetch product images
            product.src = [];
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${product.id}`).subscribe((datas: any) => {
              if (datas.status === 'SUCCESS' && datas.uploads.length > 0) {
                this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`).subscribe((res: any) => {
                  if (res.status === 'SUCCESS') {
                    product.src.push(res.uploads.base64data);
                  }
                });
              }
            });
          });
 
          // Merge new products with the existing product list
          this.productList = [ ...products];
         
          // Enable/disable load more functionality
          this.loadMoreRecentlyViewed = data.count > this.productList.length;
          this.isLoadingRecentlyViewed = false;
 
          if (this.userId) {
            this.wish_list();
          }
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
// food type
toggleFoodType(){
  this.isfoodType = !this.isfoodType
}
 
getProductsForPage(page: number): any[] {
  // Mock function to get products. In real implementation, this should be fetched from the server.
  // Calculate start and end index based on the current page
  const start = (page - 1) * this.productsPerPage;
  const end = start + this.productsPerPage;
 
  // Here, you would return the actual products based on the start and end indexes
  return []; // Return actual products array based on start and end
}
foodType(event: any, type: string): void {
  const isChecked = event.target.checked;
 
  if (isChecked) {
    // If checked, add the type to the list
    if (!this.foodTypeList.includes(type)) {
      this.foodTypeList.push(type);
    }
  } else {
    // If unchecked, remove the type from the list
    this.foodTypeList = this.foodTypeList.filter(foodType => foodType !== type);
  }
 
  // Call the API with the updated food type list
  this.ProductListCall(this.foodTypeList, false);
}
 
  toggleAvailability(){
    this.isAvailability = !this.isAvailability;
  }
  onOutOfStockChange(event: any): void {
    this.outOfStockIncluded = event.target.checked; // Update state based on checkbox
    // Optionally, trigger the API call immediately after checkbox change
    this.ProductListCall([], false);
  }
 
 
 
  togglePriceContent() {
    this.isPriceVisible = !this.isPriceVisible;
  }
 
  // Method to handle slider changes
  onSliderChange(event: any): void {
    this.sliderValue = Number(event.target.value);
 
    // Adjust the min and max prices based on the slider value
    const midpoint = (this.maxValue - this.minValue) / 2;
 
    if (this.sliderValue <= midpoint) {
      // Increase min price until the midpoint
      this.minPrice = this.sliderValue;
      this.maxPrice = midpoint + (this.sliderValue * (this.maxValue - midpoint) / midpoint);
    } else {
      // Increase max price after the midpoint
      this.minPrice = midpoint; // Set minPrice to midpoint
      this.maxPrice = this.sliderValue;
    }
   
    // Call the API whenever the price changes
    this.ProductListCall([], false);
  }
  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
   
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity
    };
 
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const product = this.productList.find(item => item.id === productId);
          if (product) {
            product.cartitemsquantity = quantity;
 
          }
 
          this.addedToCart.set(productId, true);
          this.cartService.updateCartCount(data.newCartCount);
 
        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }
 
  getQuantity(productId: number): number {
 
    return this.quantities.get(productId) || 1;
 
  }
  increaseQuantity(productId: number, productVariationId: string) {
    const product = this.productList.find(item => item.id === productId);
    if (product) {
      product.cartitemsquantity += 1;
 
      this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
    }
  }
 
  decreaseQuantity(productId: number, productVariationId: string, cartId: any) {
    const product = this.productList.find(item => item.id === productId);
 
    if (product) {
      if (product.cartitemsquantity > 1) {
        // Decrease quantity and update the cart
        product.cartitemsquantity -= 1;
 
        this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
      } else if (product.cartitemsquantity === 1) {
        // If the quantity is 1, set it to 0 and call delete function
 
        product.cartitemsquantity = 0;
        this.deleteCartItem(cartId);  // Call delete function
      }
    }
  }
 
  deleteCartItem(cartId: any): void {
    const requestBody = {
      updatedBy: this.userId,
      cartIds: [cartId],
    };
 
    this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          if (this.userId !== null && this.userId !== undefined) {
            this.cartService.updateCartCount(this.userId);
          } else {
            console.error('User ID is null or undefined. Cannot update cart count.');
          }
        }
      },
      (error: any) => {
        console.error('An error occurred:', error);
      }
    );
  }
 
  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
    event.stopPropagation(); // Prevent triggering parent events
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const newStatus = !product.wishlistIcon;
 
    product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
    if (this.userId) {
      const requestBody = {
        status: newStatus ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId
      };
 
      this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
        .subscribe(
          (data: any) => {
            // Handle the success response here if needed
          },
          (error) => {
            console.error("API Error:", error);
          }
        );
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigate(['/login']);
    }
  }
 
  wish_list(): void {
    // if (!this.userId) {
    //   this.router.navigate(['/login']);
    //   return;
    // }
 
    const requestBody = {
      userId: this.userId,
    };
 
    this.http.post(environment.apiUrl + `/wishlistitems/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            const wish_list_product = data.wishlistitems_output;
 
            // Loop through productList and check if product is in the wishlist
            this.productList.forEach((product: any) => {
              const isInWishlist = wish_list_product.some((item: any) => item.productid === product.id);
              product.wishlistIcon = isInWishlist; // Set wishlist icon to true if in wishlist
 
            });
          }
        },
        (error) => {
          console.error("Wishlist API Error:", error);
        }
      );
  }
 
}
 
 
 