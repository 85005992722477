<div mat-dialog-content>
  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>
 
  <div class="row mt-3 ">
    <!-- Thumbnail Images -->
    <div class="col-md-1 px-0">
      <!-- <div class="d-block">
        <div class="img" *ngFor="let image of productImages.src; let i = index" (click)="selectImage(i)">
          <img [src]="image" alt="Product Image" class="border my-0"
            [ngClass]="{'active-thumbnail': selectedIndex === i}" />
        </div>
      </div> -->
      <div id="myCarousel" class="carousel slide position-relative" data-ride="carousel">
        <div class="carousel-inner" role="listbox">
          <!-- Loop through product images and display only 3 at a time -->
          <div class="img item m-0" *ngFor="let image of productImages.src.slice(startIndex, endIndex); let i = index" (click)="selectImage(startIndex + i)">
            <img [src]="image" alt="Product Image" class=" border mx-0 mb-2"
            [ngClass]="{'border': selectedIndex !== (startIndex + i), 'active-thumbnail': selectedIndex === (startIndex + i)}" />
          </div>
        </div>
        <!-- Carousel controls -->
        <div *ngIf="startIndex > 0" class="carousel-control-prev position-absolute" >
          <img
          [src]="prevImageSrc"
          alt="Previous"
          class="m-0  carousel-img-scroll"
          (click)="prevImage()"
          (mouseover)="hoverImage('prev', true)"
          (mouseout)="hoverImage('prev', false)"
          width="35px" height="35px"
        >
        </div>
        <div *ngIf="endIndex < productImages.src.length"  class="carousel-control-next position-absolute" >
          <img
          [src]="nextImageSrc"
          alt="Next"
          class="m-0  carousel-img-scroll"
          (click)="nextImage()"
          (mouseover)="hoverImage('next', true)"
          (mouseout)="hoverImage('next', false)"
          width="35px" height="35px"
        >
        </div>
      </div>
    </div>
 
    <!-- Main Product Image -->
    <div class="col-md-5 p-0 border" style="border-radius: 10px;">
      <img [src]="mainImage" alt="Product Image" class="main-product-image" />
    </div>
 
    <!-- Product Details -->
    <div class="col-md-5 productDetails">
      <h4>{{ productDetails.title }}</h4>
 
      <div class="promotions mt-3 mb-3">
        <span class="promo_off">50% offer</span>
        <span class="combo_off mx-1">Combo offer available</span>
        <!-- <span class="badge" [ngClass]="{ 'bg-success': productDetails.type === 'discount', 'bg-info': productDetails.type === 'offer' }">
        {{ productDetails.label }}
      </span> -->
      </div>
 
      <div class="description-container">
        <p class="description" [innerHTML]="sanitizedDescription"></p>
      </div>
      <!-- <a class="show-more mt-3" (click)="toggleShowMore()">Show more</a> -->
 
      <div class="pricing mt-3">
        <span class="new-price fs-3">{{ priceToShow | currency: 'GBP' }}</span>
        <span class="original-price ms-2 text-decoration-line-through">
          <del>{{ mrpPrice | currency: 'GBP' }}</del>
        </span>
        <span class="ms-2 tax">(inclusive of all taxes)</span>
      </div>
 
 
      <div class="size-selection mt-3">
        <div class="sizes p-1">
          <button *ngFor="let variation of productDetails.variations" (click)="selectSize(variation)"
            [ngClass]="{'selected-btn': variation === selectedVariation}" class="btn size-btn me-2">
            {{ variation.variationvalue }} {{ variation.variationshortname }}
          </button>
 
        </div>
      </div>
 
      <button class="btn add-card btn-lg mt-3" (click)="addToCart()"><img src="../../assets/images/bag-add.svg" alt=""> Add to cart</button>
 
 
    </div>
 
    <!-- Share and Like Icons -->
    <div class="col-md-1 p-0">
      <div class="d-flex justify-content-between gap-3">
        <img src="../../assets/icon/share.svg" alt="share" class="icon">
        <img src="../../assets/icon/like_icon.svg" alt="like" class="icon" style="margin-right: 10px;" />
      </div>
    </div>
  </div>
  <div class="mt-5">
    <img src="../../assets/images/animation-arrow.svg" alt="" class="d-block m-auto img-animation" (click)="navigateToProductPage()" style="cursor: pointer;">
  </div>
   
</div>
 