<div class="my-5">
  <div>
      <div class="header d-flex justify-content-between align-items-center">
          <h5 class="fs-md-5 ">Subscribe Product</h5>
          <h6 class="hover-img" (click)="subscribeList_page()">View all <img src="../../assets/images/arrow.svg" alt="arrow" class="ms-2 arrow-img"></h6>
      </div>
      <div class="section mt-4">
        <div id="popular-spots-lists">
          <div class="parent" #elemt (mousedown)="startDragging($event, false, elemt,'mouse',child, '')"
            (mouseup)="stopDragging($event, false,'mouse')" (mouseleave)="stopDragging($event, false,'mouse')"
            (mousemove)="moveEvent($event, elemt, 'mouse', child)" (scroll)="onScrollOrClick()" >
            <div class="child d-flex  position-relative" #child>
                <div class="card me-md-2 me-1 " *ngFor="let product of  productList,let i= index">
                  <div class="d-flex mobile-position">
                    <!-- <div class="offer">{{ product.offer }}</div> -->
                    <div class="offer">Save Upto 20%</div>
                    <div class="like-border me-5 d-none d-sm-block">
                      <!-- If product is in the wishlist (heart icon) -->
                      <span *ngIf="isLoggedIn &&product.wishlistIcon; else noWishListIcon" (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                        <img src="../../assets/images/heart-icon.svg" class="like" alt="heart-icon">
                      </span>
                     
                      <!-- If product is not in the wishlist (border heart icon) -->
                      <ng-template #noWishListIcon>
                        <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                          <img src="../../assets/images/heart-border-web.png" class="like" alt="like">
                        </span>
                      </ng-template>
                     
                      <img src="../../assets/images/view-border.svg" class="view" alt="view"
                           (click)="openProductDialog(product, product.selectedVariation)">
                    </div>
                         <!-- for mobile -->
                <div class="mobile-like mt-2 d-sm-none d-block">
                  <span *ngIf="isLoggedIn &&product.wishlistIcon; else noWishListIcon"
                  (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                  <img src="../../assets/images/heart-icon.svg" class="heartlike" alt="like">
                </span>
 
                <!-- If product is not in the wishlist (border heart icon) -->
                <ng-template #noWishListIcon>
                  <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                    <img src="../../assets/images/heartborder.svg" class="heartlike" alt="like">
                  </span>
                </ng-template>
              </div>

                    </div>
                <div class="image-container"  (click)="productList_page(product.id,product.selectedVariation )">
                    <img [src]="product.src?.[0]" class="card-img d-block mx-auto" alt="product">
                </div>

                <div class="card-body px-sm-2">
                    <h6 class="card-title mb-md-2 mb-0 text-truncate">{{ product.title }}</h6>
                    <div class="size-selection d-flex align-items-center">
                      <div class="sizes d-flex align-items-center">
                        <button *ngFor="let variation of product.variations"   (click)="selectSize(product.id, variation)"
                          class="btn size-btn me-1 d-flex align-items-center" [ngClass]="{'selected-btn': variation.priceToShow === product.price}">                                  {{ variation.variationvalue }} {{ variation.variationshortname }}
                        </button>
                      </div>
                    </div>
                    <div class="text-center d-md-flex justify-content-md-between align-items-center mt-1">
                      <div class="mt-2" >
                        <!-- Display updated price and MRP price -->
                        <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                      </div>
                      <div class="add-btn text-center" (click)="SubcribeClick()">
                        <a class="btn mt-1"><img src="../../assets/images/cart.svg" alt="cart" class="me-2" >Subscribe</a>
                      </div>
                      <!-- <div class="d-flex justify-content-between align-items-center add-border cart" *ngIf="product.cartquantity > 0">
                        <button class="btn p-0" (click)="decreaseQuantity(product.id, product.selectedVariation, product.cartid)"><img src="../../assets/images/decrease_img.svg"
                                alt="Decrease" style="width: 20px; height: 20px; margin-left: 20px;"></button>
                        <span class="px-2" style="font-size: 14px; ">{{  product.cartquantity }}</span>
                        <button class="btn p-0" (click)="increaseQuantity(product.id,product.selectedVariation)"><img src="../../assets/images/increase_img.svg"
                                alt="Increase" style="width: 20px; height: 20px; margin-right: 20px;"></button>
                    </div> -->
                    </div>
                  </div>
            </div>
            </div>
          </div>
        </div>
        <div class="d-lg-block d-none">
        <div  class="see-more "(click)="onScrollOrClick()" *ngIf="downArrow">
          <img src="../../assets/images/carousel.svg" class="d-block mx-auto seemoreIcon" alt="downArrow"
            />
        </div>
        <div  class="see-more "(click)="onRightScrollOrClick()" *ngIf="!downArrow">
          <img src="../../assets/images/carousel.svg" class="d-block mx-auto seemoreIcon" alt="downArrow"  id="right-subscribe"
            />
        </div>
        <div  class="see-less " (click)="onLeftScrollOrClick()" *ngIf="upArrow">
          <img src="../../assets/images/arrow-left.svg" class="d-block mx-auto seemoreIcon" alt="upArrow" id="left-subscribe"
             />
        </div>
        </div>
      </div>
   
   
