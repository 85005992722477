import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private dataToPass = new BehaviorSubject<any>(null);
  constructor() { }

  setData(data: any): void {
    this.dataToPass.next(data); 
  }

  getData(): Observable<any> {
    return this.dataToPass.asObservable();
  }

  clearData(): void {
    this.dataToPass.next(null);
  }
}

