<div class="mt-4">
    <!-- tittle -->
    <div class="d-flex justify-content-start gap-4 tittle list-unstyled mb-0 heading_cart" >
        <a href="javascript:void(0)">
            <img src="../../assets/images/cart-active.svg" class="" alt="">
            <img src="../../assets/images/cart-inactive.svg" class="d-none " alt="">
            My Cart
        </a>
        <a href="javascript:void(0)" class="d-none d-lg-none">
            <img src="../../assets/images/subscription-active.svg" class="" alt="">
            <img src="../../assets/images/subscription-inactive.svg" class=" d-none" alt="">
            My Subscription
        </a>
    </div>
    <hr class=" hr-line">
 
    <!-- count -->
    <div class="d-flex justify-content-center align-items-center card-container" *ngIf="cartPage">
        <div class="step-container">
            <small class="border-rounded fs-6" [ngClass]="{'active': currentStep >= 1}">1</small>
            <label class="d-lg-block d-none">Your Cart</label>
            <label class="d-lg-none d-block text-truncate">Your Cart</label>
        </div>
        <hr class="hr-color">
        <div class="step-container">
            <small class="border-rounded fs-6" [ngClass]="{'active': currentStep >= 2}">2</small>
            <label class="d-lg-block d-none">Address</label>
            <label class="d-lg-none d-block text-truncate">Address</label>
        </div>
        <hr class="hr-color">
        <div class="step-container">
            <small class="border-rounded fs-6" [ngClass]="{'active': currentStep >= 3}">3</small>
            <label class="d-lg-block d-none">Delivery Schedule</label>
            <label class="d-lg-none d-block text-truncate">Delivery Schedule</label>
        </div>
        <hr class="hr-color">
        <div class="step-container">
            <small class="border-rounded fs-6" [ngClass]="{'active': currentStep >= 4}">4</small>
            <label class="d-lg-block d-none">Payment Process</label>
            <label class="d-lg-none d-block text-truncate">Payment Process</label>
        </div>
    </div>
   
 
    <!-- table -->
 
 
    <div class="container">
        <!-- cart-page -->
        <div class="row mt-5" *ngIf="cartPage">
            <!-- product page 1st page -->
            <div class="col-md-8 col-12 d-lg-block d-none" *ngIf="productPage">
                <div class="d-flex justify-content-between align-items-center cart">
                    <div class="">
                        <h5>Cart</h5>
                    </div>
                    <div class="d-flex cart align-items-center">
                        <p class="m-0">Toatal product: {{count}}</p>
 
                        <button class="btn clear_btn" (click)="clearAllItems()">Clear all</button>
                    </div>
                </div>
                <table class="table table-responsive">
                    <thead>
                        <tr>
                            <th scope="col" class="col-product">Product</th>
                            <th scope="col">Variant</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Subtotal</th>
                            <th scope="col">Allow Substitutions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of cartItems">
                            <th scope="row" class="col-product">
                                <div class="d-flex align-items-center cart">
                                    <div class="card p-1 me-2">
                                        <img [src]="item.src?.[0]" alt="img" class=" image-src">
                                    </div>
                                   
                                    <p class="mb-0" style="font-size: 14px;">{{ item.title }}</p>
                                </div>
                            </th>
                            <td>
                                <div>
                                    <select class="variations"  [(ngModel)]="item.productvariationid" (change)="updatePrice($event, item)" [value]="item.productvariationid">
                                        <option value="50ml" *ngFor="let variation of item.variations" [value]="variation.id">{{ variation.variationvalue }} {{ variation.variationshortname }}</option>
                                       
                                    </select>
                                </div>
                            </td>
                            <td style="font-size: 14px;">  {{ (item.selectedPrice || item.variations?.[0]?.sellingprice || 0) | currency:'GBP' }}</td>
                            <td>
                                <div class="d-flex align-items-center add-border cart">
                                    <button class="btn p-0" (click)="decreaseQuantity(item)" ><img src="../../assets/images/decrease_img.svg"
                                            alt="Decrease" style="width: 20px; height: 20px;"></button>
                                    <span class="px-2" style="font-size: 14px;">{{ item.quantity }}</span>
                                    <button class="btn p-0" (click)="increaseQuantity(item)"><img src="../../assets/images/increase_img.svg"
                                            alt="Increase" style="width: 20px; height: 20px;"></button>
                                </div>
                            </td>
 
                            <td style="font-size: 14px;"> {{ (item.selectedPrice || item.variations?.[0]?.sellingprice || 0) * item.quantity | currency:'GBP' }}</td>
                            <td>
                                <div class="d-flex justify-content-around align-items-center cart">
                                    <input type="checkbox" name="substitute" id="substitute" [checked]="item.allowsubstitute" (change)="onSubstituteChange($event, item)">
                                    <button class="btn p-0 ms-2" (click)="deleteCartItem(item.id)"><img src="../../assets/images/remove_btn.svg"
                                            alt="Remove" style="width: 20px; height: 20px;"></button>
                                </div>
                            </td>
                        </tr>
                     
                    </tbody>
                </table>
 
 
 
                <div class="d-flex justify-content-between align-items-center mt-4 cart">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="allowSubstitutes" id="allowSubstitutes"  (change)="onMasterCheckboxChange($event)">
                        <label class="form-check-label" for="allowSubstitutes">Allow substitutes for unavailable
                            products in this order*</label>
                    </div>
                    <button class="btn btn-next px-md-4" (click)="productPage_next()">Next <img src="../../assets/images/next_rightarrow.svg" alt=""></button>
                </div>
            </div>
            <!-- for mobile -->
            <div class="col-md-8 col-12  d-lg-none d-block"  *ngIf="productPage">
                <div class="d-flex justify-content-between align-items-center cart">
                    <div class="">
                        <h5>&nbsp; Cart</h5>
                    </div>
                    <div class="d-flex cart align-items-center">
                        <p class="m-0">Toatal product:2</p>
 
                        <button class="btn clear_btn">Clear all</button>
                    </div>
                </div>
                <hr>
                <div class=" m-2">
                    <div class="d-flex justify-content-between align-items-center cart">
                        <div class="d-flex" style="width: 60%;">
                            <img src="../../assets/images/card-img.svg" alt="img" class="me-2">
                            <p class="mb-0" style="font-size: 14px;">Johnson's & Johnson's Baby Shampoo</p>
                        </div>
                        <select class="variations">
                            <option value="50ml">50 ml</option>
                            <option value="100ml">100 ml</option>
                            <option value="150ml">150 ml</option>
                        </select>
                    </div>
 
                    <div class="d-flex justify-content-between align-items-center m-1">
                        <div class="mt-1">
                            <p class="px-5">$2.99</p>
                        </div>
                        <div class="d-flex justify-content-around align-items-center gap-4">
                            <div>
                            <h6>$5.98</h6>
                        </div>
                            <div class="add-border d-flex align-items-center justify-content-around">
                         <button class="btn p-0">
                                <img src="../../assets/images/decrease_img.svg" alt="Decrease"
                                    style="width: 18px; height: 18px;"></button>
                            <span class="px-2" style="font-size: 15px;">2</span>
                            <button class="btn p-0"><img src="../../assets/images/increase_img.svg" alt="Increase"
                                    style="width: 18px; height: 18px;"></button>
                                </div></div>
                    </div>
                </div>
                <hr>
                <div class="row m-2">
                    <div class="col">
                        <input type="checkbox" class="mx-1">
                        <label for="">Allow Substitutions</label>
                    </div>
                    <div class="col text-center">
                        <button class="btn text-center p-1" style="margin-top: -5px;"><img src="../../assets/images/cancel.svg" alt=""></button>
                        <label for="" class="text-center">Cancel</label>
                    </div>
                </div>
                <hr>
                <div class="form-check m-1">
                    <input class="form-check-input" type="checkbox" name="allowSubstitutes" id="allowSubstitutes">
                    <label class="form-check-label" for="allowSubstitutes">Allow substitutes for unavailable
                        products in this order*</label>
                </div>
                <!-- <div class="m-2 next_btn_container footer">
                    <button class="btn btn-next" (click)="productPage_next()">Next<img src="../../assets/images/next_rightarrow.svg" alt=""></button>
                </div> -->
                <div class="sticky-footer">
                    <div class="total-info">
                        <label class="name">Total</label>
                        <span class="amount ms-4">$11.98</span>
                        <img src="../../assets/alert-icon.svg" alt="alert-icon" width="20px" height="20px" class="mt-md-1 m-3">
                    </div>
                    <button class="btn btn-next" (click)="productPage_next()">Next
                        <img src="../../assets/images/next_rightarrow.svg" alt="">
                    </button>
                </div>
            </div>
 
            <!-- deliver address 2nd page -->
            <div class="col-md-8 col-12 " *ngIf="deliverAddress">
                <div class="d-flex justify-content-between align-items-center">
                    <h5><img src="../../assets/images/left_arrow.svg" alt=""> Delivery address</h5>
                    <button class="btn add-btn"><img src="../../assets/images/plus.svg" alt=""> Add address</button>
                </div>
                <ul class="mt-2 p-0">
                    <li class="list-unstyled border-bottom border-top p-2">
                        <div class="d-flex">
                            <input type="radio">
                            <label for="" class="delivery_details">Santhosh | 9876543210 | santhoshgmail.com
                            </label><small
                                class="work p-1">Work</small>
                        </div>
                        <p class="delivery_address">151, Sagotharar St, Chetti Kulam, Nagercoil, Tamil Nadu 629001</p>
                    </li>
                </ul>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mt-2 mb-5 details" (click)="detectLocation()">
                        <img src="../../assets/icon/detect-address.svg" alt="" class="pe-2"> Detect my current location
                      </div>
                    <div class="mt-2 mb-5 d-lg-block d-none btn_container">
                        <button class="btn next_del_btn" (click)="deliverAddress_next()">Next <img src="../../assets/images/next_rightarrow.svg"
                                alt=""></button>
                    </div>
                    <!-- <div class=" d-lg-none d-block btn_container">
                        <button class="btn next_del_btn " (click)="deliverAddress_next()">Next <img src="../../assets/images/next_rightarrow.svg"
                                alt=""></button>
                    </div> -->
                    <div class="sticky-footer  d-lg-none d-block d-flex">
                        <div class="total-info">
                            <label class="name">Total</label>
                            <span class="amount ms-4">$11.98</span>
                            <img src="../../assets/alert-icon.svg" alt="alert-icon" width="20px" height="20px" class="mt-md-1 m-3">
                        </div>
                        <button class="btn next_del_btn "  (click)="deliverAddress_next()">Next
                            <img src="../../assets/images/next_rightarrow.svg" alt="">
                        </button>
                    </div>
                </div>
            </div>
            <!-- delivery schedule 3rd page-->
            <div class="col-md-8 col-12 d-lg-block d-none" *ngIf="deliverySchedule">
                <div>
                    <h5><img src="../../assets/images/left_arrow.svg" alt="">&nbsp; Delivery Schedule</h5>
                </div>
                <hr>
                <div class="container">
                    <div class="d-flex gap-3 m-4">
                        <div class="card"  style="margin-left: 16px;">
                            <div class="card-body days">
                                <h6 class="card-title">Thu</h6>
                                <p class="card-text">Aug 01</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body days">
                                <h6 class="card-title">Fri</h6>
                                <p class="card-text">Aug 02</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body days">
                                <h6 class="card-title">Sat</h6>
                                <p class="card-text">Aug 03</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body days">
                                <h6 class="card-title">Sun</h6>
                                <p class="card-text">Aug 04</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body days">
                                <h6 class="card-title">Mon</h6>
                                <p class="card-text">Aug 05</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body days">
                                <h6 class="card-title">Tue</h6>
                                <p class="card-text">Aug 06</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body days">
                                <h6 class="card-title">Wed</h6>
                                <p class="card-text">Aug 07</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex gap-3 m-4">
                        <div  style="margin-left: 16px;">
                            <input type="radio">
                            <label for="">2.00 pm to 5.00 pm</label>
                        </div>
                        <div>
                            <input type="radio">
                            <label for="">5.00 pm to 9.00 pm</label>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="delivery_container">
                    <div>
                        <label for="">Add delivery instructions</label>
                    </div>
                    <div>
                        <input type="text" class="p-4 w-100">
                    </div>
                </div>
                <div class="mt-2 mb-5">
                    <button class="btn next_btn" (click)="deliverySchedule_next()">Next <img src="../../assets/images/next_rightarrow.svg"
                            alt=""></button>
                </div>
            </div>
 
            <!-- for mobile -->
            <div class="col-md-8 col-12 d-lg-none d-block" *ngIf="deliverySchedule">
                <div>
                    <h5><img src="../../assets/images/left_arrow.svg" alt="" class="d-lg-block d-none">&nbsp; Delivery Schedule</h5>
                </div>
                <hr>
                <div class="container">
                    <div class="row">
                        <div class="col-3">
                            <div class="card">
                                <div class="card-body days">
                                    <h6 class="card-title text-center">Thu</h6>
                                    <p class="card-text mobile_card">Aug 01</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card">
                                <div class="card-body days">
                                    <h6 class="card-title text-center">Fri</h6>
                                    <p class="card-text mobile_card">Aug 02</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card">
                                <div class="card-body days">
                                    <h6 class="card-title text-center">Sat</h6>
                                    <p class="card-text mobile_card">Aug 03</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card">
                                <div class="card-body days">
                                    <h6 class="card-title text-center">Sun</h6>
                                    <p class="card-text mobile_card">Aug 04</p>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 col-3">
                            <div class="card">
                                <div class="card-body days">
                                    <h6 class="card-title text-center">Mon</h6>
                                    <p class="card-text mobile_card">Aug 05</p>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 col-3">
                            <div class="card">
                                <div class="card-body days">
                                    <h6 class="card-title text-center">Tue</h6>
                                    <p class="card-text mobile_card">Aug 06</p>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 col-3">
                            <div class="card">
                                <div class="card-body days">
                                    <h6 class="card-title text-center">Wed</h6>
                                    <p class="card-text mobile_card">Aug 07</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex gap-3 mt-3">
                        <div>
                            <input type="radio">
                            <label for="" class="time">2.00 pm to 5.00 pm</label>
                        </div>
                        <div>
                            <input type="radio">
                            <label for="" class="time">5.00 pm to 9.00 pm</label>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="delivery_container">
                    <div>
                        <label for="">Add delivery instructions</label>
                    </div>
                    <div>
                        <input type="text" class="p-4 w-100">
                    </div>
                </div>
                <!-- <div class=" next_btn_container footer">
                    <button class="btn next_btn" (click)="deliverySchedule_next()">Next <img src="../../assets/images/next_rightarrow.svg"
                            alt=""></button> -->
                            <div class="sticky-footer">
                                <div class="total-info">
                                    <label class="name">Total</label>
                                    <span class="amount ms-4">$11.98</span>
                                    <img src="../../assets/alert-icon.svg" alt="alert-icon" width="20px" height="20px" class="mt-md-1 m-3">
                                </div>
                                <button class="btn btn-next" (click)="deliverySchedule_next()">Next
                                    <img src="../../assets/images/next_rightarrow.svg" alt="">
                                </button>
                </div>
            </div>
           <!-- payment process 4th page-->
           <div class="col-md-8 col-12" *ngIf="paymentProcess && paymentActive">
            <div>
                <h5><img src="../../assets/images/left_arrow.svg" alt=""> Payment process</h5>
            </div>
            <hr>
       
            <!-- Wallet Option -->
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <input type="radio" name="paymentMethod" id="wallet" [(ngModel)]="selectedPaymentMethod" value="wallet">
                    <label for="wallet">Wallet</label>
                </div>
                <div *ngIf="selectedPaymentMethod === 'wallet'">
                    <div>
                        <span>Available Balance</span>
                        <span>$2.99</span>
                    </div>
                </div>
            </div>
       
            <hr>
       
            <!-- Card Payment Option -->
            <div class="card_payment_container">
                <div>
                    <input type="radio" name="paymentMethod" id="cardPayment" [(ngModel)]="selectedPaymentMethod" value="card">
                    <label for="cardPayment">Card Payment</label>
                </div>
                <div class="card_payment_content m-4" *ngIf="selectedPaymentMethod === 'card'">
                    <!-- Card Payment Content -->
                    <div class="card_details">
                        <label>Saved Card details</label>
                        <div class="card mt-2">
                            <div class="card-body d-flex align-items-center justify-content-between">
                                <input type="radio" name="savedCard" class="me-3">
                                <div class="d-block">
                                    <span>Santhosh</span><br>
                                    <span>HDFC Bank</span>
                                </div>
                                <div>XXXXXX0987 9087</div>
                                <div>Debit card</div>
                                <div>
                                    <img src="../../assets/images/visa_bank.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
       
                    <div class="newcard_details mt-4">
                        <label>Pay with new card</label>
                        <div class="card mt-2">
                            <div class="card-body">
                                <div>
                                    <label for="cardHolderName" class="form-label">Card Holder name</label>
                                    <input type="text" id="cardHolderName" class="form-control content" placeholder="Card holder name">
                                </div>
                                <div class="mt-2">
                                    <label for="bankName" class="form-label">Bank name</label>
                                    <select id="bankName" class="form-select content">
                                        <option value=""></option>
                                    </select>
                                </div>
                                <div class="input-with-icon mt-2">
                                    <label for="cardDetails" class="form-label">Card details</label>
                                    <div class="input-container">
                                        <input type="text" id="cardDetails" class="form-control content" placeholder="1234 1234 1234 9876">
                                        <img src="../../assets/images/visa_bank.svg" alt="" id="cardDetailsImg">
                                    </div>
                                </div>
       
                                <div class="mt-2">
                                    <label class="form-label">Card type</label>
                                    <div class="form-check-group card_detail">
                                        <div class="form-check card_type">
                                            <input type="radio" class="form-check-input content" name="cardType" id="debitCard">
                                            <label for="debitCard" class="form-check-label">Debit Card</label>
                                        </div>
                                        <div class="form-check card_type">
                                            <input type="radio" class="form-check-input content" name="cardType" id="creditCard">
                                            <label for="creditCard" class="form-check-label">Credit Card</label>
                                        </div>
                                    </div>
                                </div>
       
                                <div class="row mt-2 mb-2">
                                    <div class="col">
                                        <label for="expiryDate" class="form-label">Expiry date</label>
                                        <input type="date" id="expiryDate" class="form-control content">
                                    </div>
                                    <div class="col cvc-input-container">
                                        <label for="cvc" class="form-label">CVC</label>
                                        <input type="text" id="cvc" class="form-control content">
                                        <img src="../../assets/images/cvc_number_img.svg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-check mt-4">
                            <input type="checkbox" id="saveCardDetails" class="form-check-input">
                            <label for="saveCardDetails" class="form-check-label">Save your card details for later payment</label>
                        </div>
                    </div>
                </div>
            </div>
       
            <hr>
       
            <!-- Pay with Link Option -->
            <div>
                <input type="radio" name="paymentMethod" id="linkPayment" [(ngModel)]="selectedPaymentMethod" value="link">
                <label for="linkPayment">Pay with link</label>
            </div>
       
            <hr>
       
            <!-- Cash on Delivery Option -->
            <div class="mb-2">
                <input type="radio" name="paymentMethod" id="cashPayment" [(ngModel)]="selectedPaymentMethod" value="cash">
                <label for="cashPayment">Cash on delivery</label>
            </div>
       
        </div>
 
 
            <!--  Cart total side card page-->
            <div class="col-md-4 col-12">
                <div class="card card-border mb-1">
 
                    <div class="card-body" >
                        <h5 class="card-title">Cart total</h5>
                        <div class="card-text">
                            <div class="d-flex justify-content-between align-items-center cart">
                                <label for="">No of products</label>
                                <span>{{count}}</span>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-between align-items-center cart">
 
                                <label for="">MRP Total</label>
                                <span>  {{ getMrpPrice() | currency:'GBP' }}</span>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-between align-items-center cart">
 
                                <label for="">Subtotal</label>
                                <span>{{ getSubTotalPrice() | currency:'GBP' }}</span>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-between align-items-center cart">
 
                                <label for="">Saved</label>
                                <span>{{ getPriceDifference() | currency:'GBP' }}</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-center cart mt-1">
 
                                <label for="">Total</label>
                                <span>{{ getSubTotalPrice() | currency:'GBP' }}</span>
                            </div>
                        </div>
                        <div class="align-items-center mt-2" disable>
                            <button class="btn order-btn" (click)="placeOrder()"   [disabled]="!isplaceOrderEnabled" >Place order</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 
        <!-- Order received -->
        <div class="mt-5 d-lg-block d-none" *ngIf="orderPlaced">
            <h5 class="text-center">Thank you. Your order has been received.</h5>
            <div class="row mt-5 mb-5 order_border">
                <div class="col-2 col-md-2 border-end">
                    <div>
                        <label for="">Order id</label>
                        <span class="d-block order_details">1234567890</span>
                    </div>
                </div>
                <div class="col-3 col-md-3 border-end">
                    <div>
                        <label for="">Order items</label>
                        <span class="d-block order_details">Johnson's & Johnson's Baby Shampoo <a href="">show
                                more</a></span>
                    </div>
                </div>
                <div class="col-2 col-md-2 border-end">
                    <div>
                        <label for="">Order Date</label>
                        <span class="d-block order_details">Friday, 02.08.2024</span>
                    </div>
                </div>
                <div class="col-2 col-md-2 border-end">
                    <div>
                        <label for="">Delivery Date</label>
                        <span class="d-block order_details">Friday, 02.08.2024</span>
                    </div>
                </div>
                <div class="col-3 col-md-3">
                    <div>
                        <label for="">Delivery Address</label>
                        <div class="d-block order_details">
                            <span>Santhosh | 9876543210 | santhoshgmail.com</span>
                            <span>151, Sagotharar St, Chetti Kulam, Nagercoil, Tamil Nadu 629001</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
                <a href="javascript:void(0)" class="details"><img src="../../assets/images/left_arrow.svg" alt="">&nbsp; Order details</a>
                <a href="/home" class="details">Back to home &nbsp;<img src="../../assets/images/right_arrow.svg" alt=""></a>
            </div>
        </div>
        <!-- for mobile -->
        <div class="mt-5 d-lg-none d-block" *ngIf="orderPlaced">
            <h6 class="text-center">Thank you. Your order has been received.</h6>
            <div class="row mt-5 mb-5 order_border">
                <div class="col-12 mb-3">
                    <div>
                        <label for="">Order id</label>
                        <span class="d-block order_details">1234567890</span>
                    </div>
                </div>
                <hr>
                <div class="col-12 mb-3">
                    <div>
                        <label for="">Order items</label>
                        <span class="d-block order_details">Johnson's & Johnson's Baby Shampoo <br> <a href="javascript:void(0)">show
                                more</a></span>
                    </div>
                </div>
                <hr>
                <div class="col-12 mb-3">
                    <div>
                        <label for="">Order Date</label>
                        <span class="d-block order_details">Friday, 02.08.2024</span>
                    </div>
                </div>
                <hr>
                <div class="col-12 mb-3">
                    <div>
                        <label for="">Delivery Date</label>
                        <span class="d-block order_details">Friday, 02.08.2024</span>
                    </div>
                </div>
                <hr>
                <div class="col-12 mb-3">
                    <div>
                        <label for="">Delivery Address</label>
                        <div class="d-block order_details">
                            <span>Santhosh | 9876543210 | santhoshgmail.com</span>
                            <span>151, Sagotharar St, Chetti Kulam, Nagercoil, Tamil Nadu 629001</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
                <a href="javascript:void(0)" class="details"><img src="../../assets/images/left_arrow.svg" alt="">&nbsp; Order details</a>
                <a href="javascript:void(0)" class="details">Back to home &nbsp;<img src="../../assets/images/right_arrow.svg" alt=""></a>
            </div>
        </div>
        <!-- Empty card -->
        <div class="empty-cart-container m-4 p-4 " *ngIf="orderEmpty">
            <div class="empty-cart-content">
                <img src="../../assets/images/empty_cart.svg" alt="" class="img-fluid d-block mx-auto">
                <h5 class="text-center mt-3">Your Cart is Empty!</h5>
                <div class="shopping">
                    <a href="javascript:void(0)">Continue Shopping</a>
                </div>
            </div>
        </div>
    </div>
    <div class="m-5 d-lg-block d-none">
        <app-play-store></app-play-store>
    </div>
    <div class="m-3 d-lg-none d-block">
        <app-play-store></app-play-store>
    </div>
     
</div>
 
 
 
 
  <!-- subscription delivery schedule -->
  <!-- <div class="col-md-8 col-12 d-lg-block d-none">
    <div>
        <h5><img src="../../assets/images/left_arrow.svg" alt="">&nbsp; Delivery Schedule</h5>
    </div>
    <hr>
    <div class="container mb-2">
        <div>
            <p>Select schedule</p>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <div class="card schedule">
                <div class="card-title">
                    <input type="radio" name="" id="">
                    <label for="">Daily</label>
                </div>
            </div>
            <div class="card schedule">
                <div class="card-title">
                    <input type="radio" name="" id="">
                    <label for="">Alternate</label>
                </div>
            </div>
            <div class="card schedule">
                <div class="card-title">
                    <input type="radio" name="" id="">
                    <label for="">Every 3 days</label>
                </div>
            </div>
            <div class="card schedule">
                <div class="card-title">
                    <input type="radio" name="" id="">
                    <label for="">Weekly</label>
                </div>
            </div>
            <div class="card schedule">
                <div class="card-title">
                    <input type="radio" name="" id="">
                    <label for="">Monthly</label>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <p>Subscription starts on</p>
            <div>
                <input type="date" name="" id="">
            </div>
        </div>
        <div class="mt-3 mb-2">
            <p>Subscription Ends on</p>
            <div>
                <input type="date" name="" id="">
            </div>
        </div>
        <hr>
        <div class="mt-2">
            <div>
                <p>Add delivery instructions</p>
            </div>
            <div>
                <input type="text" class="instructions">
            </div>
        </div>
        <div class="mt-2 mb-5">
            <button class="btn next_btn">Next <img src="../../assets/images/next_rightarrow.svg"
                    alt=""></button>
        </div>
    </div>
</div> -->
 
<!-- for mobile -->
<!-- <div class="col-md-8 col-12 d-lg-none d-block">
    <div>
        <h5><img src="../../assets/images/left_arrow.svg" alt="">&nbsp; Delivery Schedule</h5>
    </div>
    <hr>
    <div class="container mb-2">
        <div>
            <p>Select schedule</p>
        </div>
        <div class="row m-3">
            <div class="col-6 mb-2">
                <div class="card schedule">
                    <div class="card-title">
                        <input type="radio" name="" id="">
                        <label for="">Daily</label>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2">
                <div class="card schedule">
                    <div class="card-title">
                        <input type="radio" name="" id="">
                        <label for="">Alternate</label>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2">
                <div class="card schedule">
                    <div class="card-title">
                        <input type="radio" name="" id="">
                        <label for="">Every 3 days</label>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2">
                <div class="card schedule">
                    <div class="card-title">
                        <input type="radio" name="" id="">
                        <label for="">Weekly</label>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2">
                <div class="card schedule">
                    <div class="card-title">
                        <input type="radio" name="" id="">
                        <label for="">Monthly</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <p>Subscription starts on</p>
            <div>
                <input type="date" name="" id="">
            </div>
        </div>
        <div class="mt-3 mb-2">
            <p>Subscription Ends on</p>
            <div>
                <input type="date" name="" id="">
            </div>
        </div>
        <hr>
        <div class="mt-2">
            <div>
                <p>Add delivery instructions</p>
            </div>
            <div>
                <input type="text" class="instructions">
            </div>
        </div>
        <div class="mt-2">
            <button class="btn next_btn">Next <img src="../../assets/images/next_rightarrow.svg"
                    alt=""></button>
        </div>
    </div>
</div> -->
<!-- subscription delivery schedule end -->
 