import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from '../../environments/environment.development';
import {jwtDecode} from 'jwt-decode';


export interface loginResponse {
  locations: any;
  token: string,
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private dialog: MatDialog,private http: HttpClient) {
    // banner-image close
    this.dialog.afterOpened.subscribe(() => {
      document.body.classList.add('no-scroll');
      
    });
    this.dialog.afterAllClosed.subscribe(() => {
      document.body.classList.remove('no-scroll');
    });
    // login
    this.accessToken.next(localStorage.getItem('accessToken') || null);
    
    this.userName.next(localStorage.getItem('userName') || null)
    if (localStorage.getItem('accessToken')) {
       this.userInfo.next(this.decodeToken(this.accessToken.getValue()))
       
      }
    this.UserType.next(localStorage.getItem('UserType') || null)
  }
// login
searchKey:string =''
public accessToken: BehaviorSubject<any> = new BehaviorSubject(null);
public userName: BehaviorSubject<any>= new BehaviorSubject(null);
userInfo: BehaviorSubject<any> = new BehaviorSubject(null);
UserType: BehaviorSubject<any> = new BehaviorSubject(null);
viewHeader:boolean=true;
UserAction: BehaviorSubject<any> = new BehaviorSubject(null);
 

isSubmitting = false;

// decodeToken(token: string) { 
//   if(token&&token!=='undefined')
//   return jwtDecode(token);
// }
decodeToken(token: string): any {
  return jwtDecode(token);
}
	
login(credentials: { emailOrphone: string, password: string,rememberme:boolean}) {
  let data:any = credentials;
      if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
        data.appToken = localStorage.getItem('appToken');
      }
  return this.http.post<loginResponse>(environment.apiUrl + '/users/login', data, { responseType: 'json' }).pipe(map((data: any) => {
    localStorage.setItem('accessToken', data.token);
    console.log(data.token,"data.token")
    let restoken:any = this.decodeToken(data.token);
    console.log(restoken,"restoken")

    this.UserAction.next(data.action);
    console.log(this.UserAction,"this.UserAction")

    this.userInfo.next(this.decodeToken(data.token));
    console.log(this.userInfo,"this.userInfo")


    localStorage.setItem('userName',restoken?.firstname);
    console.log(restoken?.firstname,"restoken?.firstname")

    localStorage.setItem('UserType',"user");

    this.UserType.next('user');
    console.log(this.UserType,"this.userInfo")

    this.userName.next(restoken?.firstname)
    console.log(this.userName,"this.userName")

  }));
}

validateGoogleUserSignIn(token:any){
  let data:any = token;
  data.userType = "user";
      // if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
      //   data.appToken = localStorage.getItem('appToken');
      // }
      console.log(data,"data")
  return this.http.post<loginResponse>(environment.apiUrl + '/users/google-login', data, { responseType: 'json' }).pipe(map((data: any) => {
    localStorage.setItem('accessToken', data.token);
    localStorage.setItem('userName',data?.users?.firstname);
    localStorage.setItem('UserType',"user");
    this.UserType.next('user');
    this.userName.next(data?.users?.firstname)
    this.userInfo.next(this.decodeToken(data.token));
  }));
}


logout() {

localStorage.clear();
this.userName.next(null);

}

// getDataById(id: any) {
//   return this.http.get(environment.apiUrl + `/users/get?id=${id}`);
// }


// update(credentials: { id :number,firstName: string, lastName: string, email: string }) { 
//   return this.http.post<loginResponse>(environment.apiUrl + '/users/update', credentials, { responseType: 'json' }).pipe(map((data: any) => {

//     localStorage.setItem('userName',data?.users?.firstname);
//     this.userName.next(data?.users?.firstname);
// })); }

// validateGoogleUserSignIn(token:any){
//   let data:any = token;
//       if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
//         data.appToken = localStorage.getItem('appToken');
//       }
//   return this.http.post<loginResponse>(environment.apiUrl + '/users/googleSignIn', data, { responseType: 'json' }).pipe(map((data: any) => {
//     localStorage.setItem('accessToken', data.token);
//     localStorage.setItem('userName',data?.users?.firstname);
//     localStorage.setItem('UserType',"user");
//     this.UserType.next('user');
//     this.userName.next(data?.users?.firstname)
//     this.userInfo.next(this.decodeToken(data.token));
//   }));
// }

// validateWebAppleLogin(data:any){
//   let datas:any = data;
//   if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
//     data.appToken = localStorage.getItem('appToken');
//   }
//   return this.http.post<loginResponse>(environment.apiUrl + '/users/webAppleSignIn', datas, { responseType: 'json' }).pipe(map((data: any) => {
//     localStorage.setItem('accessToken', data.token);
//     localStorage.setItem('userName',data?.users?.firstname);
//     localStorage.setItem('UserType',"user");
//     this.UserType.next('user');
//     this.userName.next(data?.users?.firstname)
//     this.userInfo.next(this.decodeToken(data.token));
//   }));
// }



}


