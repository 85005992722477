import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { ProductService } from '../services/product.service';

interface Card {
  category: string;
  title: string;
  id: string;
  level: string;
  parentid: string;
  parentname: string;
  src: any;
}
interface Category {
  id: string;
  name: string;
}

@Component({
  selector: 'app-explore-by-categories',
  templateUrl: './explore-by-categories.component.html',
  styleUrls: ['./explore-by-categories.component.css']
})
export class ExploreByCategoriesComponent implements OnInit {
  categories: Category[] = [];
  activeCategory: string = 'All';
  activeCategoryId: string = 'all'; // Default ID for "All"
  cards: Card[] = [];
  filteredCards: Card[] = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.categoriesListLevel_0();
    this.filterCards({ id: 'all', name: 'All' }); // Ensure "All" is the default active category
  }

  filterCards(category: Category): void {
    this.activeCategory = category.name;
    this.activeCategoryId = category.id;

    if (category.name === 'All') {
      this.filteredCards = this.cards; // Show all cards
      this.categoriesListLevel_1(''); // Fetch level 1 categories with empty parentId
    } else {
      this.filteredCards = this.cards.filter(card => card.category === category.name); // Filter cards by category
      this.categoriesListLevel_1(category.id); // Fetch level 1 categories with the selected category ID
    }
  }

  category_card(subcategory_id: any, subcategory_title: any, subcategory_level: any, parentid: any) {
    const dataToPass = {
      subcategory_Id: subcategory_id,
      subcategory_Title: subcategory_title,
      Level: subcategory_level,
      parentId: parentid
    };
    this.productService.setData(dataToPass);
    this.router.navigate(['/product-list-pages']);
  }

  categoriesListLevel_0(): void {
    const requestBody = {
      status: 1,
      parentId: '',
      level: '0',
    };

    this.http.post<{ status: string; categories_output: Category[] }>(`${environment.apiUrl}/categories/list`, requestBody).subscribe({
      next: (data) => {
        if (data.status === 'SUCCESS') {
          const dynamicCategories = data.categories_output;
          this.categories = [
            { id: 'all', name: 'All' }, // Add "All" option statically
            ...dynamicCategories // Append dynamic categories
          ];
          this.filteredCards = this.cards; // Show all cards initially
        } else {
          console.error('Error fetching level 0 categories:', data);
        }
      },
      error: (error) => {
        console.error('API error for level 0 categories:', error);
      }
    });
  }

  categoriesListLevel_1(parentId: string): void {
    const requestBody = {
      status: 1,
      parentId: parentId,
      level: '1',
    };

    this.http.post<{ status: string; categories_output: any[] }>(`${environment.apiUrl}/categories/list`, requestBody).subscribe({
      next: (data) => {
        if (data.status === 'SUCCESS') {
          data.categories_output.forEach((x: any) => {
            x.src = [];
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=categories&dataForId=${x.id}`).subscribe((datas: any) => {
              if (datas.status === "SUCCESS") {
                if (datas.uploads.length > 0) {
                  this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      x.src.push(res.uploads.base64data);
                    }
                  });
                }
              }
            });
          });
          this.cards = data.categories_output.map(category => ({
            category: category.parentname.split(' > ')[0], // Assuming parentname has a format like "Grocery > Baby Products"
            title: category.name,
            id: category.id,
            level: category.level,
            parentid: category.parentid,
            parentname: category.parentname,
            src: category.src
          }));
          this.filteredCards = this.activeCategory === 'All' ? this.cards : this.cards.filter(card => card.category === this.activeCategory);
        } else {
          console.error('Error fetching level 1 categories:', data);
        }
      },
      error: (error) => {
        console.error('API error for level 1 categories:', error);
      }
    });
  }
}
