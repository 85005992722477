<section class="container-fluid">
    <div class="row">
        <div class="col-md-4">
            <div class="d-flex align-items-center h-100 justify-content-center">
                <div class="px-md-5">
                    <h1 class="fw-bold">ERROR 404</h1>
                    <h4 class="text-muted">OOPS!! The page you are looking for is not found</h4>
                    <button class="btn btn-primary rounded-pill px-4" (click)="goBack()">Go Back</button>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <img src="assets/404-page.svg" class="img" alt="404 image">
        </div>
    </div>
</section>