<div class="fixed-margin-top">
  <div class="m-4 ">
   
    <div class="row">
   
      <div class="col-lg-3 product-list d-none d-lg-block">
   
        <div class="btn-container d-flex">
          <div class="row p-4" *ngFor="let main_category of mainCategories">
            <button type="button" class="btn col-md-6 col-sm-6 btn-category"
              (click)="Category_Section(main_category.id,main_category.categorykey)"
              [ngClass]="{'active-category': main_category.id === activeCategoryId}">
              {{ main_category.name }}
            </button>
          </div>
        </div>
   
        <!-- Grocery Section -->
        <div class="category_list">
          <div class="category-color">
        <div  class="m-2">
          <h5 (click)="Category_Section(mainCategories[0].id,mainCategories[0].categorykey)" class="cursor-point mb-0">Category</h5>
          <div *ngIf="subCategories.length > 0">
          <hr *ngIf="showHr">
          <div *ngFor="let category of subCategories; let i = index" class="">
            <div class="category-heading p-2 ps-lg-3 d-flex justify-content-between align-items-center"
              (click)="toggleDropdownContent($event, i, category.id)"
              [ngClass]="{'active-category': category.id === activeSubCategoryId}">
              <h6 class="font-weight-bold mb-0">{{ category.name }} </h6>
              <img [src]="'assets/icon/' + (isDropdownContentVisible[i] ? 'list-arrow-side.svg' : 'list-arrow-down.svg')"
              class="ms-4 pointer" alt="arrow">
         
            </div>
            <div *ngIf="isDropdownContentVisible[i]">
              <ul class="list-unstyled test">
                  <li *ngFor="let item of subCategories_list; let j = index">
                    <div class="category-heading ps-lg-3 d-flex align-items-center"
                      [ngClass]="{ 'bg-warning': item.isOpen, 'bg-outline-warning': !item.isOpen }"
                      (click)="onCategoryClick(item)">
                      <input class="form-check-input m-0" type="checkbox" [id]="'checkbox-' + item.id + '-' + item.id" [(ngModel)]="item.isChecked"
                      (click)="$event.stopPropagation()">
                      <h6 class="font-weight-bold sub-item ps-lg-3 mb-0" style="font-weight: 200;">{{ item.name }}</h6>
                    </div>
                  </li>
              </ul>
            </div>
          </div>
          </div>
        </div>
      </div>
      <hr>
          <div class="category-color">
            <h5 class="mb-0">Brand</h5>
            <hr>
          </div>
          <div  class="category-color">
            <h5 class="mb-0">Price</h5>
            <hr>
          </div>
          <div  class="category-color" >
            <h5 class="mb-0">Availability</h5>
            <hr>
          </div>
          <div class="category-color">
            <h5 class="mb-0">Offers</h5>
            <hr>
         
      </div>
    </div>
      </div>
   
      <!-- card -->
   
      <div class="col-lg-9 col product-list-card mt-4">
        <div class="d-flex justify-content-between result">
          <div class="d-flex pt-2"> <small>Result Found :</small> <small>30</small></div>
          <button class="btn btn-border d-none d-lg-block" (click)="toggleSortMenu()"  [ngClass]="{'menu-open': isSortMenuOpen}"
          >
            Sort by <img src="../../assets/icon/down-arrow.svg" alt="">
          </button>
          <ul *ngIf="isSortMenuOpen" class="sort-menu p-1">
            <li><a (click)="sortBy('lowToHigh')">Price: Low to High</a></li>
            <li><a (click)="sortBy('highToLow')">Price: High to Low</a></li>
            <li><a (click)="sortBy('mostPopular')">Most Popular</a></li>
            <li><a (click)="sortBy('discountHighToLow')">Discount: High to Low</a></li>
          </ul>
   
          <div class="d-flex  d-block d-lg-none">
            <button class="btn btn-border-mobile me-md-5 me-1 "><img src="../../assets/icon/sort-by.svg" alt=""> Sort
              by</button>
            <button class="btn btn-border-mobile me-md-5" (click)="toggleFilterSection()"><img
                src="../../assets/icon/filter.svg" alt=""> Filter</button>
   
          </div>
        </div>
        <hr class="hr-color">
   
        <div class="row">
          <div *ngIf="activeButton === 1">
            <div class="row">
              <div *ngFor="let product of productList" class="col-md-4 d-lg-block d-none mb-4">
                <div class="card me-md-2 me-1" style="cursor: pointer;">
                  <div class="d-flex">
                    <!-- <div class="offer mx-1 px-1">{{ product.offer }}</div> -->
                    <div class="offer mx-1 px-2">50% off</div>
   
                    <div class="hover-zoom" (click)="grocery_click(product.id,product.selectedVariation)"
                    >
                      <img [src]="product.src?.[0]" class="card-img" alt="product">
                    </div>
                    <div class="like-border me-5 d-none d-sm-block">
                      <!-- If product is in the wishlist (heart icon) -->
                      <span *ngIf="product.wishlistIcon; else noWishListIcon" (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                        <img src="../../assets/images/heart-icon.svg" class="like" alt="heart-icon">
                      </span>
                     
                      <!-- If product is not in the wishlist (border heart icon) -->
                      <ng-template #noWishListIcon>
                        <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                          <img src="../../assets/images/heart-border-web.png" class="like" alt="like">
                        </span>
                      </ng-template>
                     
                      <img src="../../assets/images/view-border.svg" class="view" alt="view"
                           (click)="openProductDialog(product, product.selectedVariation)">
                    </div>
                    <!-- for mobile -->
                    <div class="mobile-like mt-2 d-sm-none d-block">
                      <img src="../../assets/images/heartborder.svg" class="heartlike" alt="like">
                    </div>
                  </div>
                  <div class="card-body px-sm-2">
                    <h6 class="card-title mb-md-2 mb-3 mt-2 text-truncate">{{ product.title }}</h6>
                    <div class="card-text d-flex mb-2 mt-3">
                      <!-- <p class="mx-1" >10 pcs</p> -->
                      <button *ngFor="let variation of product.variations" (click)="selectSize(product.id, variation)"
                        class="btn size-btn me-1 pt-1" [ngClass]="{'selected-btn': variation.priceToShow === product.price}">
                        {{ variation.variationvalue }} {{ variation.variationshortname }}
                      </button>
   
   
                      <!-- <p class="mx-1" *ngFor="let size of product.sizes">{{ size }}</p> -->
                    </div>
                    <div class="text-center d-md-flex justify-content-md-between mt-1 mt-md-0 mx-2 mobile-off">
                      <div class="mt-md-0 ">
                        <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                        <span class="amount2 m-0"><del>{{ product.originalPrice | currency:'GBP' }}</del></span>
                      </div>
                      <div class="add-btn text-center mt-1" (click)="Add_to_cart(product.id,product.selectedVariation,1)" *ngIf="product.cartquantity === null || product.cartquantity === 0">
                        <a class="btn mt-1"><img src="../../assets/images/cart.svg" alt="cart" class="me-2">Add to Cart</a>
                      </div>
                      <div class="d-flex justify-content-between align-items-center add-border cart" *ngIf="product.cartquantity > 0">
                        <button class="btn p-0" (click)="decreaseQuantity(product.id, product.selectedVariation, product.cartid)"><img src="../../assets/images/decrease_img.svg"
                                alt="Decrease" style="width: 20px; height: 20px; margin-left: 20px;"></button>
                        <span class="px-2" style="font-size: 14px; ">{{  product.cartquantity }}</span>
                        <button class="btn p-0" (click)="increaseQuantity(product.id,product.selectedVariation)"><img src="../../assets/images/increase_img.svg"
                                alt="Increase" style="width: 20px; height: 20px; margin-right: 20px;"></button>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- for mobile -->
              <div *ngFor="let product of productList" class="col-12 d-lg-none d-block fixed-margin">
                <div class=" me-md-2" style="cursor: pointer;" *ngIf="showProductList">
                  <div class="row">
                    <!-- <div class="offer mx-1 px-1">{{ product.offer }}</div> -->
                    <!-- <div class="offer mx-1 px-1">50% off</div> -->
   
                    <!-- <div class="hover-zoom" > -->
                    <div class="col-4 col-sm-6">
                      <img [src]="product.src?.[0]" class="card-img" alt="product" (click)="grocery_click(product.id,product.selectedVariation)">
                    </div>
                    <!-- <div class="like-border me-md-3 me-0 d-none d-sm-block">
                              <img src="../../assets/images/like.svg" class="like" alt="like">
                              <img src="../../assets/images/view.svg" class="view" alt="view" (click)="openProductDialog(product)">
                            </div> -->
                    <div class="col-8 col-sm-6">
                      <div class="d-flex justify-content-between align-items-center mt-4">
                        <h5 class="card-title mb-0">{{ product.title }}</h5>
                        <span *ngIf="product.wishlistIcon; else noWishListIcon" (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                                                   <img src="../../assets/images/Wishlist-like.svg" class="heart-like" alt="heart-icon">
 
                        </span>
                       
                        <!-- If product is not in the wishlist (border heart icon) -->
                        <ng-template #noWishListIcon>
                          <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                            <img src="../../assets/images/like.svg" class="like" alt="like">
                          </span>
                        </ng-template>
                      </div>
                      <div class="offer mx-1 px-1">50% off</div>
   
                      <div class="card-text d-flex mt-2 mb-2">
                        <button *ngFor="let variation of product.variations" (click)="selectSize(product.id, variation)"
                          class="btn size-btn me-2 pt-1" [ngClass]="{'selected-btn': variation.priceToShow === product.price}">
                          {{ variation.variationvalue }} {{ variation.variationshortname }}
                        </button>
                      </div>
                      <div class="card-body d-flex justify-content-between align-items-center mt-3">
   
                        <div class="text-center d-md-flex justify-content-md-between mt-1 mt-md-0 mx-2">
                          <div class="mt-md-0 d-flex mobile-off">
                            <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                            <span class="amount2"><del>{{ product.originalPrice | currency:'GBP' }}</del></span>
                          </div>
   
                        </div>
   
                        <!-- <div class="add-btn text-center m-0" (click)="Add_to_cart(product.id,product.selectedVariation,1)" *ngIf="product.cartquantity === null || product.cartquantity === 0">
                          <a class="btn px-0"><img src="../../assets/images/cart.svg" alt="cart"> Add to Cart</a>
                        </div> -->
                       
                        <div class="add-btn text-center mt-1" (click)="Add_to_cart(product.id,product.selectedVariation,1)" *ngIf="product.cartquantity === null || product.cartquantity === 0">
                          <a class="btn mt-1"><img src="../../assets/images/cart.svg" alt="cart" >Add to Cart</a>
                        </div>
                        <div class="d-flex justify-content-between align-items-center add-border cart" *ngIf="product.cartquantity > 0">
                          <button class="btn p-0" (click)="decreaseQuantity(product.id, product.selectedVariation, product.cartid)"><img src="../../assets/images/decrease_img.svg"
                                  alt="Decrease" style="width: 20px; height: 20px; margin-left: 20px;"></button>
                          <span class="px-2" style="font-size: 14px; ">{{  product.cartquantity }}</span>
                          <button class="btn p-0" (click)="increaseQuantity(product.id,product.selectedVariation)"><img src="../../assets/images/increase_img.svg"
                                  alt="Increase" style="width: 20px; height: 20px; margin-right: 20px;"></button>
                      </div>
                      </div>
                    </div>
   
                    <!-- </div> -->
   
                  </div>
                  <!-- <div class="card-body px-sm-2"> -->
   
                  <!-- <div class="text-center d-md-flex justify-content-md-between mt-1 mt-md-0 mx-2">
                              <div class="mt-md-0 ">
                                <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                                <span class="amount2 m-0"><del>{{ product.originalPrice | currency:'GBP' }}</del></span>
                              </div>
                             
                            </div> -->
                  <!-- </div> -->
                </div>
                <hr>
              </div>
            </div>
          </div>
   
        </div>
        <div class="row d-none">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item disabled">
                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
              </li>
              <li class="page-item active"><a class="page-link" href="#">1</a></li>
              <li class="page-item " aria-current="page">
                <a class="page-link" href="#">2</a>
              </li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <app-play-store></app-play-store>
   
  </div>
   
  <!-- for mobile filter click -->
  <div class="filter-container d-lg-none d-block" [ngClass]="{'show-filter': showFilterSection}">
    <div class="header">
      <div class="p-3 filter_header d-flex justify-content-between align-items-center">
        <img src="../../assets/images/left_arrow.svg" alt="" (click)="toggleFilterSection()" style="cursor: pointer;">
   
        <h5 class="mb-0">Filter</h5>
        <span style="cursor: pointer;">Reset all</span>
      </div>
    </div>
   
    <div class="section">
      <div class="btn-container row p-3 mb-3">
        <div class=" col-6" *ngFor="let main_category of mainCategories">
          <button type="button" class="btn px-3" (click)="Category_Section(main_category.id,main_category.categorykey)"
            style="width: 100%" [ngClass]="{'active-category': main_category.id === activeCategoryId}">
            {{ main_category.name }}
          </button>
        </div>
      </div>
   
      <div *ngIf="activeButton === 1" class="mt-3">
        <div class="p-2">
          <div *ngFor="let category of subCategories; let i = index" class="category-color">
            <div class="category-heading p-2 ps-lg-3 d-flex justify-content-between align-items-center"
              (click)="toggleDropdownContent($event, i, category.id)"
              [ngClass]="{'active-category': category.id === activeSubCategoryId}">
              <h5 class="font-weight-bold mb-0">{{ category.name }} </h5>
              <img src="../../assets/icon/{{ isDropdownContentVisible[i] ? 'list-arrow-side.svg' : 'list-arrow-down.svg' }}"
                class="ms-4 pointer" alt="arrow">
            </div>
            <div *ngIf="isDropdownContentVisible[i]">
              <ul class="list-unstyled test">
                <li *ngFor="let item of subCategories_list; let j = index">
                  <div class="category-heading ps-lg-3 d-flex align-items-center"
                    [ngClass]="{ 'bg-warning': item.isOpen, 'bg-outline-warning': !item.isOpen }"
                    (click)="ProductListCall(item.id)">
                    <input class="form-check-input form-check-box" type="checkbox"
                      [id]="'checkbox-' + item.id + '-' + item.id">
                    <h5 class="font-weight-bold mob-sub-item ps-lg-3 mb-0" style="font-weight: 200;">{{ item.name }}</h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button class="btn w-100 apply_btn">Apply</button>
      </div>
    </div>
  </div>
   
  </div>
   