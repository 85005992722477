<div class="profile-container d-flex">
    <div class="profile-sidebar d-lg-block d-none">
        <div class="profile-header">
            <img [src]="profileImageUrl" alt="Profile Picture" class="profile-img">
            <div class="profile-info">
                <span class="greeting">Hello</span>
                <h6 class="username">{{user_details.firstname}}</h6>
            </div>
        </div>
        <div class="profile-section">
            <div class="menu-section">
                <h5 class="menu-header">Personal Information</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('profile')"
                        [class.active]="activeSection === 'profile'">
                        <img src="../../assets/icon/profile-icon.svg" alt="" class="icon-profile">
                        Profile
                    </li>
                    <li class="menu-item" (click)="onSectionChange('wishlist')"
                        [class.active]="activeSection === 'wishlist'">
                        <img src="../../assets/icon/wish-icon.svg" alt="" class="icon-profile">
                        My Wishlist
                        <span class="badge-num text-center">{{count}}</span>
 
                    </li>
                    <li class="menu-item" (click)="onSectionChange('orders')"
                        [class.active]="activeSection === 'orders'">
                        <img src="../../assets/icon/my-order.svg" alt="" class="icon-profile">
                        My Orders
                    </li>
                    <li class="menu-item" (click)="onSectionChange('subscriptions')"
                        [class.active]="activeSection === 'subscriptions'">
                        <img src="../../assets/icon/subscribt-product-icon.svg" alt="" class="icon-profile">
                        Subscribe Products
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Payment</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('wallets')"
                        [class.active]="activeSection === 'wallets'">
                        <img src="../../assets/icon/mywallet-icon.svg" alt="" class="icon-profile">
                        My Wallet
                    </li>
                    <li class="menu-item" (click)="onSectionChange('cards')" [class.active]="activeSection === 'cards'">
                        <img src="../../assets/icon/saved-icon.svg" alt="" class="icon-profile">
                        Saved Card Details
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Location</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('address')"
                        [class.active]="activeSection === 'address'">
                        <img src="../../assets/icon/addrress-icon.svg" alt="" class="icon-profile">
                        Manage Address
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Notifications</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('notifications')"
                        [class.active]="activeSection === 'notifications'">
                        <img src="../../assets/icon/notify-icon.svg" alt="" class="icon-profile">
                        Notification
                        <span class="badge-num text-center">2</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="signout">
            <a href="javascript:void(0)" class="signout-link" (click)="toggleSignoutPopup()">
                <img src="../../assets/icon/signout-icon.svg" alt="" class="icon-profile">
                Sign out
            </a>
        </div>
    </div>
    <div class="profile-content" *ngIf="activeSection === 'profile'" [formGroup]="myGroup">
        <div>
 
             
            <div class="profile-picture">
                <img [src]="profileImageUrl" alt="Profile Picture" class="profile-man">
                <input type="file" accept="image/*" (change)="handleLicenceChange($event)"
                #fileInput class="d-none">
                <div (click)="triggerUpload()">
                <img src="../../assets/icon/profile-edit-icon.svg" alt="Edit Profile" class="edit-icon">
            </div>
               
              </div>
 
        </div>
        <div class="user_name d-lg-block d-none">
            <div class="d-flex justify-content-between align-items-center">
                <label for="">First name / Last name</label>
                <a href="javascript:void(0)" class="edit-click" *ngIf="!isNameEditing"
                    (click)="toggleNameEdit()">Edit</a>
                <a href="javascript:void(0)" class="cancel-click" *ngIf="isNameEditing"
                    (click)="cancelNameEdit()">Cancel</a>
 
            </div>
            <div class="row d-lg-flex d-none">
                <div class="col-lg-5">
                    <input type="text" class="form-control name" placeholder="Enter the first name"
                        [disabled]="!isNameEditing" formControlName="firstname">
                </div>
                <div class="col-lg-5">
                    <input type="text" class="form-control name" placeholder="Enter the last name"
                        [disabled]="!isNameEditing" formControlName="lastname">
                </div>
                <div class="col-lg-2">
                    <input class="btn btn-primary save-name" type="button" value="Save" *ngIf="isNameEditing"
                        (click)="saveNameChanges()">
                </div>
            </div>
 
 
        </div>
        <div class="d-lg-none d-block">
            <div class="d-flex justify-content-between align-items-center">
                <label for="">First name / Last name</label>
                <a href="javascript:void(0)" class="mobile-edit" *ngIf="!isNameEditing"
                    (click)="toggleNameEdit()">Edit</a>
                <a href="javascript:void(0)" class="mobile-cancel" *ngIf="isNameEditing"
                    (click)="cancelNameEdit()">Cancel</a>
            </div>
            <div class="d-flex flex-column gap-3 d-lg-none">
                <input type="text" class=" form-control name" placeholder="Enter the first name"
                    formControlName="firstname" [disabled]="!isNameEditing">
                <input type="text" class=" form-control name" placeholder="Enter the last name"
                    formControlName="lastname" [disabled]="!isNameEditing">
                <input class="btn btn-primary save-name" type="button" value="Save" *ngIf="isNameEditing"
                    (click)="saveNameChanges()">
            </div>
 
        </div>
        <div class="user_gender">
            <div class="d-flex justify-content-between align-items-center">
                <label for="">Gender</label>
                <a href="javascript:void(0)" class="edit-gender" *ngIf="!isGenderEditing"
                    (click)="toggleGenderEdit()">Edit</a>
                <a href="javascript:void(0)" class="cancel-gender" *ngIf="isGenderEditing"
                    (click)="cancelGenderEdit()">Cancel</a>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <input type="radio" name="gender" class="me-2" id="male" [value]="'Male'" formControlName="gender"
                        [disabled]="!isGenderEditing">
                    <label for="male">Male</label>
                </div>
                <div class="col">
                    <input type="radio" name="gender" class="me-2" id="female" [value]="'Female'"
                        formControlName="gender" [disabled]="!isGenderEditing">
                    <label for="female">Female</label>
                </div>
                <div class="col" style="margin-top: -15px; padding-right: 0;">
                    <input class="btn btn-primary save-gender" type="button" value="Save" *ngIf="isGenderEditing"
                        (click)="saveGenderChanges()">
                </div>
            </div>
        </div>
        <div class="user_mobile">
            <div class="d-flex justify-content-between align-items-center">
                <label for="">Mobile number</label>
                <a href="javascript:void(0)" class="edit-mobile" *ngIf="!isPhoneEditing"
                    (click)="togglePhoneEdit()">Edit</a>
                <!-- <label for="" *ngIf="isPhoneEditing" style="margin-left: 135px;">Enter new mobile number</label> -->
                <!-- <a href="javascript:void(0)" class="cancel-click" *ngIf="isPhoneEditing" -->
                    <!-- (click)="cancelPhoneEdit()">Cancel</a> -->
            </div>
            <div class="row mt-2">
                <div class="col-lg-5">
                    <!-- <ngx-intl-tel-input [cssClass]="'d-block w-100 phone'" [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[searchCountryField.Iso2, searchCountryField.Name]"
                        [selectFirstCountry]="false" [separateDialCode]="true" [maxLength]="15" [phoneValidation]="true"
                        formControlName="mobileInput" [disabled]="!isPhoneEditing"> 
                    </ngx-intl-tel-input> -->
                    <ngx-intl-tel-input class="d-block w-100 phone"  
                    [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]" [enableAutoCountrySelect]="false"
                    [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" 
                    name="phone" formControlName="mobileInput" ></ngx-intl-tel-input> 
 
                </div>
                <!-- <div class="col-lg-5">
                    <input type="text" class="form-control number" placeholder="New Mobile number"
                        *ngIf="isPhoneEditing">
                </div>
                <div class="col-lg-2">
                    <input class="btn btn-primary save-number" type="button" value="Save" *ngIf="isPhoneEditing"
                        (click)="saveChanges()">
                </div> -->
            </div>
            <div class="phone-otp-overlay" *ngIf="showPhoneOtpVerifyPopup">
                <div class="phone-otp-verify-container">
                    <button class="close-btn" (click)="toggleVerifyPhoneOtpPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="phone-otp-verify-content">
                        <div class="phone-otp">
                            <div class="popup-message">OTP is sent to your old number</div>
                            <div>
                                <input type="text" class="phone-otp-input"
                                    placeholder="Enter your old mobile number OTP" [(ngModel)]="otpInput"
                                    (input)="checkOtp($event)" (focus)="highlightBorder()" (blur)="resetBorder()">
                            </div>
                            <!-- <div class="popup-message">OTP is sent to your new number</div>
                            <input type="text" class="phone-otp-input" placeholder="Enter your new mobile number OTP"
                                [(ngModel)]="otpInput" (input)="checkOtp()" (focus)="highlightBorder()"
                                (blur)="resetBorder()"> -->
                        </div>
                        <div class="popup-actions">
                            <button class="btn verify-btn" [disabled]="isVerifyDisabled"
                                (click)="verifyPhoneOtp()">Verify</button>
                            <a class="resend-otp-btn">Resend OTP</a>
                        </div>
                    </div>
 
                </div>
            </div>
        </div>
        <div class="user_email">
            <div class="d-flex justify-content-between align-items-center">
                <label for="email">Email id</label>
                <a *ngIf="!isEmailEditable" class="edit-mobile" href="javascript:void(0)"
                    (click)="editEmail()">Verify</a>
                <a *ngIf="isEmailEditable" class="edit-mobile" href="javascript:void(0)"
                    (click)="cancelEmailEdit()">Cancel</a>
            </div>
            <div class="row">
                <div class="mt-2 col-lg-5">
                    <input type="email" class="form-control" placeholder="example@email.com"
                        [disabled]="!isEmailEditable" formControlName="email">
                </div>
                <div class="mt-2 col-lg-2">
                    <button class="btn btn-primary verify-email" *ngIf="isEmailEditable"
                        (click)="showOtpPopup()">Verify</button>
                </div>
            </div>
            <div class="otp-overlay" *ngIf="showOtpVerifyPopup">
                <div class="otp-verify-container">
                    <button class="close-btn" (click)="toggleVerifyOtpPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="otp-verify-content">
                        <div class="otp">
                            <div class="popup-message">OTP is sent to your email</div>
                            <div>
                                <input type="text" class="otp-input" placeholder="Enter your Email OTP"
                                    [(ngModel)]="otpInput"   (input)="checkOtp($event)" (focus)="highlightBorder()"
                                    (blur)="resetBorder()">
                            </div>
                        </div>
                        <div class="popup-actions">
                            <button class="btn verify-btn" [disabled]="isVerifyDisabled"
                                (click)="verifyOtp()">Verify</button>
                            <a class="resend-otp-btn">Resend OTP</a>
                        </div>
                    </div>
 
                </div>
            </div>
        </div>
 
        <div class="user_password mt-4">
            <div class="d-flex justify-content-between align-items-center">
                <label for="password">Password</label>
                <a *ngIf="!isPasswordEditable" class="edit-mobile" href="javascript:void(0)"
                    (click)="editPassword()">Change Password</a>
                <a *ngIf="isPasswordEditable" class="cancel-click" href="javascript:void(0)"
                    (click)="cancelPasswordEdit()">Cancel</a>
            </div>
            <div class="row">
                <div class="mt-2 col-lg-5">
                    <input type="password" class="form-control" [disabled]="!isPasswordEditable" placeholder="Password"
                        formControlName="currentPassword">
                </div>
                <div class="mt-2 col-lg-5" *ngIf="isPasswordEditable">
                    <input type="password" class="form-control" placeholder="Enter new Password"
                        formControlName="newPassword">
                    <small>Password contains upper & lowercase, symbols, numbers, and 8 characters</small>
                </div>
                <div class="mt-2 col-lg-2" *ngIf="isPasswordEditable">
                    <button class="btn btn-primary save-password" (click)="savePassword()">Save</button>
                </div>
            </div>
            <div class="password-overlay" *ngIf="showPasswordVerifyPopup">
                <div class="password-verify-container">
                    <button class="close-btn" (click)="toggleVerifyPasswordPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="password-verify-content">
                        <div class="passwords">
                            <div class="popup-message">Enter your old password</div>
                            <div>
                                <input type="text" class="password-input" placeholder="Enter old password"
                                    [(ngModel)]="passwordInput" (input)="checkPassword()" (focus)="highlightBorder()"
                                    (blur)="resetBorder()">
                            </div>
                            <div class="forget-pswd">
                                <a class="forget-password-btn">Forget Password</a>
                            </div>
                        </div>
                        <div class="popup-actions verify-pswd">
                            <button class="btn verify-btn" [disabled]="isVerifyPasswordDisabled"
                                (click)="verifyPassword()">Verify</button>
                        </div>
                    </div>
                </div>
            </div>
 
        </div>
 
    </div>
    <div class="wishlist-content" *ngIf="activeSection === 'wishlist'">
        <div>
            <h6>My Wishlist({{count}})</h6>
        </div>
        <hr>
        <div class="row wishlist-item align-items-center" *ngFor="let wishlistitems of wishlistitems_output ">
            <div class="col-3 col-md-1">
                <!-- <img src="../../assets/images/johnsonoil.svg" alt="" class="wishlist-img"> -->
                <img [src]="wishlistitems.src?.[0]" alt="img" class=" image-src">
            </div>
            <div class="col-6 col-md-6">
                <h6 class="product-name">{{wishlistitems.title}}</h6>
            </div>
            <div class="col-3 col-md-2 text-end">
                <span class="product-price">£ {{getPriceToShow(wishlistitems)}}</span>
            </div>
            <div class="col-6 col-md-2 add-cart mt-2 mt-md-0 text-center">
                <button (click)="Add_to_cart(wishlistitems.productid,wishlistitems.productvariationid,1)">Add to
                    cart</button>
            </div>
            <div class="col-6 col-md-1 text-center mt-2 mt-md-0 close-btns">
                <button class="btn"><img src="../../assets/images/close.svg" alt=""
                        (click)="removeWishList(wishlistitems.productid,wishlistitems.productvariationid)"></button>
            </div>
            <hr class="mt-1">
        </div>
        <!-- <hr>
        <div class="row wishlist-item align-items-center">
            <div class="col-3 col-md-1">
                <img src="../../assets/images/johnsonoil.svg" alt="" class="wishlist-img">
            </div>
            <div class="col-6 col-md-7">
                <h6 class="product-name">Johnson's & Johnson's Baby Shampoo</h6>
            </div>
            <div class="col-3 col-md-1 text-end">
                <span class="product-price">$2.99</span>
            </div>
            <div class="col-6 col-md-2 add-cart mt-2 mt-md-0 text-center">
                <button>Add to cart</button>
            </div>
            <div class="col-6 col-md-1 text-center mt-2 mt-md-0 close-btn">
                <button><img src="../../assets/icon/close.svg" alt=""></button>
            </div>
        </div> -->
    </div>
    <div class="order-content" *ngIf="activeSection === 'orders'">
        <div class="d-flex">
            <img src="../../assets/images/left_arrow.svg" alt="" class="me-2" *ngIf="showOrderDetails"
                (click)="toggleOrderDetails()" style="width: 15px;">
            <h6>My Orders</h6>
        </div>
        <hr>
        <div *ngIf="!showOrderDetails">
            <div class="order-item row align-items-center mb-4" (click)="toggleOrderDetails()">
                <div class="col-3 col-md-1">
                    <img src="../../assets/images/johnsonoil.svg" alt="" class="order-img">
                </div>
                <div class="col-7 col-md-5">
                    <h6 class="product-name">Johnson's & Johnson's Baby Shampoo</h6>
                    <span class="variations">50 ml</span>
                </div>
                <div class="col-2 col-md-2 text-start text-md-center mt-2 mt-md-0">
                    <span class="product-name">£2.99</span>
                </div>
                <div class=" col-12 col-md-4 text-center mt-2 mt-md-0">
                    <h6 class="product-name text-center">Delivery on Friday, 02.08.2024</h6>
                    <span class="m-auto d-block text-center delivery-card">You item is wait for delivery</span>
                </div>
            </div>
            <hr>
            <div class="order-item row align-items-center mb-4" (click)="toggleOrderDetails()">
                <div class="col-3 col-md-1">
                    <img src="../../assets/images/food.svg" alt="" class="order-img">
                </div>
                <div class="col-7 col-md-5">
                    <h6 class="product-name">Fried Chicken</h6>
                    <span class="variations">10 pcs</span>
                </div>
                <div class="col-2 col-md-2 text-start text-md-center mt-2 mt-md-0">
                    <span class="product-name">£2.99</span>
                </div>
                <div class=" col-12 col-md-4 text-center mt-2 mt-md-0">
                    <h6 class="product-name text-center">Delivery on Friday, 02.08.2024</h6>
                    <span class="m-auto d-block text-center delivery-card">You item is wait for delivery</span>
                </div>
            </div>
        </div>
        <div *ngIf="showOrderDetails" class="order-details">
            <div class="orders-content">
                <div class="row w-100">
                    <!-- Product Details Section -->
                    <div class="col-8">
                        <h6 class="mb-4">Product Details</h6>
                        <div class="row">
                            <div class="col-2">
                                <img src="../../assets/images/johnsonoil.svg" alt="Product Image" class="product-detail-img">
                            </div>
                            <div class="col-10">
                                <div class="order-info">
                                    <span class="order-id">Order id: 1234567890</span>
                                    <h6 class="product-title">Johnson's & Johnson's Baby Shampoo</h6>
                                    <p class="product-size">50 ml</p>
                                    <span class="quantity-info">No of Quantity: 2</span>
                                    <p class="product-price">£5.98</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Order Status Section -->
                    <div class="col-4">
                        <h6 class="mb-4">Order Status</h6>
                        <div class="order-status">
                            <p><span class="status-dot green"></span> Order on Friday, 02.08.2024</p>
                            <p><span class="status-dot yellow"></span> Delivery on Friday, 02.08.2024
                                <small class="status-note text-center">You ordered item is waiting for delivery</small>
                            </p>
                            <button class="cancel-btn">Cancel Product</button>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <!-- Delivery Address Section -->
            <div class="address-info">
                <h6>Delivery Address</h6>
                <div class="order_details">
                    <span>Santhosh | 9876543210 | santhoshgmail.com</span>
                    <span>151, Sagotharar St, Chetti Kulam, Nagercoil, Tamil Nadu 629001</span>
                </div>
            </div>
        </div>
       
    </div>
    <div class="subsciptions-content" *ngIf="activeSection === 'subscriptions'">
        <div>
            <h6>My Subscriptions</h6>
        </div>
        <hr>
        <div class="row align-items-center subscription-item">
            <div class="col-3 col-lg-1">
                <img src="../../assets/images/johnsonoil.svg" alt="" class="subsciption-img">
            </div>
            <div class="col-7 col-lg-5">
                <h6 class="product-name">Anchor Unsalted Butter</h6>
                <span class="variations">200 G</span>
            </div>
            <div class="col-2 col-lg-1 text-lg-center text-start">
                <span class="product-price">£2.99</span>
            </div>
            <div class="col-12 col-lg-5">
                <div class="d-flex align-items-center justify-content-lg-center justify-content-between gap-2">
                    <button class="btn btn-success active-btn">Active</button>
                    <h6 class="product-name text-center">Friday, 02.08.2024</h6>
                </div>
                <span class="m-auto d-block text-center delivery">Subsciption Starts</span>
            </div>
        </div>
        <hr>
        <div class="row align-items-center subscription-item">
            <div class="col-3 col-lg-1">
                <img src="../../assets/images/johnsonoil.svg" alt="" class="subsciption-img">
            </div>
            <div class="col-7 col-lg-5">
                <h6 class="product-name">Anchor Unsalted Butter</h6>
                <span class="variations">200 G</span>
            </div>
            <div class="col-2 col-lg-1 text-lg-center text-start">
                <span class="product-price">£2.99</span>
            </div>
            <div class="col-12 col-lg-5">
                <div class="d-flex align-items-center justify-content-lg-center justify-content-between gap-2">
                    <button class="btn btn-danger pause-btn">Pause</button>
                    <h6 class="product-name">Friday, 02.08.2024</h6>
                </div>
                <span class="m-auto d-block text-center delivery">Subsciption Starts</span>
            </div>
        </div>
    </div>
 
    <div class="wallet-content" *ngIf="activeSection === 'wallets'">
        <div>
            <h6>My Wallet</h6>
        </div>
        <hr>
        <div class="row">
            <div class="col-lg-6 col-md-12 mb-3">
                <div class="d-flex wallet-card-container">
                <div class="card-container">
                </div>
                <div class="wallet-card p-3">
                    <div class="wallet-card-header">
                        <h6>Available Balance</h6>
                    </div>
                    <div class="wallet-card-body">
                        <div class="balance-section">
                            <div class="balance-details">
                                <span class="currency-symbol">£</span>
                                <span class="wallet-amount">2.99</span>
                            </div>
                            <div class="wallet-icon">
                                <img src="../../assets/images/wallet-card-img.svg" alt="Wallet Icon">
                            </div>
                        </div>
                        <hr class="wallet-line">
                        <a href="" class="add-balance-link">
                            <img src="../../assets/images/wallet-add-btn.svg" alt="Add Icon" class="add-icon"> Add wallet balance
                        </a>
                    </div>
                </div>
            </div>
            </div>
            <div class="col-lg-6 col-md-12 mb-3">
                <div class="transactions-card p-3">
                    <div class="transactions-card-header d-flex justify-content-between align-items-center">
                        <h6>Your Transactions</h6>
                        <select name="" id="" class="timeline-select">
                            <option value="">Time line</option>
                        </select>
                    </div>
                    <hr>
                    <ul class="transactions-list">
                        <li class="transaction-item d-flex justify-content-between align-items-center">
                            <div class="transaction-details">
                                <h6>Transaction successful</h6>
                                <span class="payment-method">Card payment</span>
                                <span class="transaction-date">Monday, 29.07.2024</span>
                            </div>
                            <div class="transaction-amount d-flex align-items-center">
                                <span class="amount-text text-success me-2">£ 5.98</span>
                                <img src="../../assets/icon/right-arrow.svg" alt="Arrow" class="right-arrow-icon">
                            </div>
                        </li>
                        <hr>
                        <li class="transaction-item d-flex justify-content-between align-items-center">
                            <div class="transaction-details">
                                <h6>Transaction unsuccessful</h6>
                                <span class="payment-method">Wallet payment</span>
                                <span class="transaction-date">Monday, 29.07.2024</span>
                            </div>
                            <div class="transaction-amount d-flex align-items-center">
                                <span class="amount-text text-danger me-2">£ 5.98</span>
                                <img src="../../assets/icon/right-arrow.svg" alt="Arrow" class="right-arrow-icon">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
 
    <div class="card-content" *ngIf="activeSection === 'cards'">
        <div class="d-flex align-items-center justify-content-between" *ngIf="!showCardPaymentContent">
            <h6>Card details</h6>
            <button class="btn btn-outline-secondary add-address-btn" (click)="toggleCardPaymentContent()">+ Add Card
                details</button>
        </div>
        <div class="d-flex align-items-center justify-content-between" *ngIf="showCardPaymentContent">
            <h6><img src="../../assets/images/left_arrow.svg" alt="backIcon">Add new card details</h6>
        </div>
        <hr>
        <div *ngIf="!showCardPaymentContent">
            <div class="row d-lg-flex d-none">
                <div class="col-2">
                    <div>
                        <h6 class="card-detail-content">HDFC</h6>
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <h6 class="card-detail-content">XXXX XXXX XXXX 9876</h6>
                    </div>
                </div>
                <div class="col-2">
                    <div>
                        <h6 class="card-detail-content">Debit Card</h6>
                    </div>
                </div>
                <div class="col-2">
                    <div>
                        <img src="../../assets/images/visa_bank.svg" alt="">
                    </div>
                </div>
                <div class="col-1">
                    <div>
                        <a href="" class="edit-link">Edit</a>
                    </div>
                </div>
                <div class="col-1">
                    <div>
                        <a href="" class="delete-link">Delete</a>
                    </div>
                </div>
            </div>
            <div class=" d-lg-none d-block">
 
                <div class="row">
                    <div class="col-4">
                        <div>
                            <h6 class="card-detail-content">HDFC</h6>
                        </div>
                    </div>
                    <div class="col-4">
                        <div>
                            <h6 class="card-detail-content">XXXX XXXX XXXX 9876</h6>
                        </div>
                    </div>
                    <div class="col-4">
                        <div>
                            <h6 class="card-detail-content">Debit Card</h6>
                        </div>
                    </div>
 
                </div>
                <div class="row">
                    <div class="col-4">
                        <div>
                            <img src="../../assets/images/visa_bank.svg" alt="">
                        </div>
                    </div>
                    <div class="col-4">
                        <div>
                            <a href="" class="edit-link">Edit</a>
                        </div>
                    </div>
                    <div class="col-4">
                        <div>
                            <a href="" class="delete-link">Delete</a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row d-lg-flex d-none">
                <div class="col-2">
                    <div>
                        <h6 class="card-detail-content">HDFC</h6>
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <h6 class="card-detail-content">XXXX XXXX XXXX 9876</h6>
                    </div>
                </div>
                <div class="col-2">
                    <div>
                        <h6 class="card-detail-content">Debit Card</h6>
                    </div>
                </div>
                <div class="col-2">
                    <div>
                        <img src="../../assets/images/visa_bank.svg" alt="">
                    </div>
                </div>
                <div class="col-1">
                    <div>
                        <a href="" class="edit-link">Edit</a>
                    </div>
                </div>
                <div class="col-1">
                    <div>
                        <a href="" class="delete-link">Delete</a>
                    </div>
                </div>
            </div>
 
 
        </div>
        <!-- add card btn click show content -->
        <div class="row align-items-center" *ngIf="showCardPaymentContent">
            <div class="card_payment_content">
                <div class="newcard_details">
                    <div class="mt-2">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <label for="cardHolderName" class="form-label">Card Holder name</label>
                                    <input type="text" id="cardHolderName" class="form-control content"
                                        placeholder="Card holder name">
                                </div>
                                <div class="col">
                                    <label for="cardHolderEmail" class="form-label">Email id</label>
                                    <input type="email" id="cardHolderEmail" class="form-control content"
                                        placeholder="Email id">
                                </div>
                            </div>
                            <div class="mt-2">
                                <label for="bankName" class="form-label">Bank name</label>
                                <select id="bankName" class="form-select content">
                                    <option value=""></option>
                                </select>
                            </div>
                            <div class="input-with-icon mt-2">
                                <label for="cardDetails" class="form-label">Card details</label>
                                <div class="input-container">
                                    <input type="text" id="cardDetails" class="form-control content"
                                        placeholder="1234 1234 1234 9876">
                                    <img src="../../assets/images/visa_bank.svg" alt="" id="cardDetailsImg">
                                </div>
                            </div>
 
                            <div class="mt-2">
                                <label class="form-label">Card type</label>
                                <div class="form-check-group card_detail">
                                    <div class="form-check card_type">
                                        <input type="radio" class="form-check-input content" name="cardType"
                                            id="debitCard">
                                        <label for="debitCard" class="form-check-label">Debit Card</label>
                                    </div>
                                    <div class="form-check card_type">
                                        <input type="radio" class="form-check-input content" name="cardType"
                                            id="creditCard">
                                        <label for="creditCard" class="form-check-label">Credit Card</label>
                                    </div>
                                </div>
                            </div>
 
                            <div class="row mt-2 mb-2">
                                <div class="col">
                                    <label for="expiryDate" class="form-label">Expiry date</label>
                                    <input type="date" id="expiryDate" class="form-control content">
                                </div>
                                <div class="col cvc-input-container">
                                    <label for="cvc" class="form-label">CVC</label>
                                    <input type="text" id="cvc" class="form-control content">
                                    <img src="../../assets/images/cvc_number_img.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-check mt-4">
                        <button class="btn save_btn">Save card</button>
                    </div>
                </div>
            </div>
 
        </div>
    </div>
    <div class="address-content" *ngIf="activeSection === 'address'">
        <div class="d-flex align-items-center justify-content-between"
            *ngIf="!showCardAddressContent && !showNewAddress">
            <h6>Address</h6>
            <button class="btn btn-outline-secondary add-address-btn" (click)="toggleCardAddressContent()">+ Add
                Address</button>
        </div>
        <div class="d-flex align-items-center justify-content-between" *ngIf="showCardAddressContent || showNewAddress">
            <h6><img src="../../assets/images/left_arrow.svg" alt="backIcon"> Add New Address</h6>
        </div>
        <hr>
        <div class=" d-lg-none d-block" *ngIf="!showCardAddressContent && !showNewAddress">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <label for="" class="delivery_details">Santhosh | 9876543210 | santhoshgmail.com</label>
                        <small class="badge badge-secondary work-badge">Work</small>
                    </div>
                    <p class="delivery_address">151, Sagotharar St, Chetti Kulam, Nagercoil, Tamil Nadu 629001</p>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-6 text-center">
                    <a href="" class="edit-link">Edit</a>
                </div>
                <div class="col-6 text-center">
                    <a href="" class="delete-link">Delete</a>
                </div>
            </div>
        </div>
        <div class="row align-items-center d-lg-flex d-none" *ngIf="!showCardAddressContent && !showNewAddress">
            <div class="col-10">
                <div class="d-flex align-items-center">
                    <label for="" class="delivery_details">Santhosh | 9876543210 | santhoshgmail.com</label>
                    <small class="badge badge-secondary work-badge">Work</small>
                </div>
                <p class="delivery_address">151, Sagotharar St, Chetti Kulam, Nagercoil, Tamil Nadu 629001</p>
            </div>
            <div class="col-1 text-center">
                <a href="" class="edit-link">Edit</a>
            </div>
            <div class="col-1 text-center">
                <a href="" class="delete-link">Delete</a>
            </div>
        </div>
        <div class="map-container" *ngIf="showCardAddressContent">
            <div>
                <img src="../../assets/icon/map.svg" alt="mapIcon" class="map-img">
            </div>
            <div>
                <label for="deliveryArea" class="form-label">Delivery area</label>
                <div class="input-with-link">
                    <input type="text" class="form-control" id="deliveryArea" placeholder="">
                    <a href="#">Search Location</a>
                </div>
            </div>
            <div class="btn-container mt-3">
                <button class="btn next-btn" (click)="showNewAddressContainer()">Next <img
                        src="../../assets/images/next_rightarrow.svg" class="next-btn-img" alt="nextIcon"></button>
            </div>
        </div>
 
        <!-- Add new address content -->
        <div class="new-address-container" *ngIf="showNewAddress">
            <div class="new-address-content">
                <!-- Form fields for new address -->
                <div class="row">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">First name</label>
                        <input type="text" class="form-control" placeholder="Enter your First name">
                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Last name</label>
                        <input type="text" class="form-control" placeholder="Enter your Last name">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Mobile number</label>
                        <input type="text" class="form-control" placeholder="Enter your Mobile number">
                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Email id</label>
                        <input type="text" class="form-control" placeholder="Enter your Email id">
                    </div>
                </div>
                <div>
                    <label for="deliveryArea" class="form-label">Delivery area</label>
                    <div class="input-with-link  search-input-location">
                        <input type="text" class="form-control" id="deliveryArea" placeholder="">
                        <a href="#">Search Location</a>
                    </div>
                </div>
                <div>
                    <label for="" class="form-label">Address</label>
                    <input type="text" class="form-control">
                </div>
                <div class="row">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Floor/Door no (optional)</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Landmark</label>
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Postal code</label>
                        <input type="text" class="form-control" placeholder="Enter your Postal code">
                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Address type</label>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-4">
                                <div class="card">
                                    <div class="card-body home-card-body">Home</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-4">
                                <div class="card">
                                    <div class="card-body home-card-body">Office</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-4">
                                <div class="card">
                                    <div class="card-body home-card-body">Others</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <button class="btn next-save-btn">Save Address</button>
                </div>
            </div>
        </div>
    </div>
    <div class="notification-content" *ngIf="activeSection === 'notifications'">
        <h6>Notifications</h6>
        <hr>
        <div class="notification-item d-flex align-items-center">
            <div>
                <img src="../../assets/images/johnsonoil.svg" alt="Order Image" class="order-img">
            </div>
            <div class="d-block flex-grow-1 mx-3">
                <p class="mb-1 notification-text">Your order has been received successfully. We will reach out to you
                    soon</p>
                <p class="mb-0 notification-date">02.08.2024</p>
            </div>
            <div>
                <a href="" class="view-details-link">View details</a>
            </div>
        </div>
        <hr>
        <div class="notification-item d-flex align-items-center">
            <div>
                <img src="../../assets/images/johnsonoil.svg" alt="Order Image" class="order-img">
            </div>
            <div class="d-block flex-grow-1 mx-3">
                <p class="mb-1 notification-text">Your order has been received successfully. We will reach out to you
                    soon</p>
                <p class="mb-0 notification-date">02.08.2024</p>
            </div>
            <div>
                <a href="" class="view-details-link">View details</a>
            </div>
        </div>
        <hr>
        <div class="notification-item d-flex align-items-center">
            <div>
                <img src="../../assets/images/johnsonoil.svg" alt="Order Image" class="order-img">
            </div>
            <div class="d-block flex-grow-1 mx-3">
                <p class="mb-1 notification-text">Your order has been received successfully. We will reach out to you
                    soon</p>
                <p class="mb-0 notification-date">02.08.2024</p>
            </div>
            <div>
                <a href="" class="view-details-link">View details</a>
            </div>
        </div>
        <hr>
    </div>
    <div class="signout-overlay" *ngIf="showSignoutPopup">
        <div class="signout-content">
            <div class="sign">
                <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
                <div class="popup-message">Do you want to logout your account</div>
            </div>
            <hr class="hr-line">
            <div class="popup-actions">
                <button class="btn signout-btn" (click)="toggleSignoutPopup()">No</button>
                <button class="btn signout-btn">Yes</button>
            </div>
        </div>
    </div>
</div>
<app-play-store></app-play-store>
 