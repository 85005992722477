import { Injectable, OnInit } from '@angular/core'; 
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, } from '@angular/common/http'; 
import { catchError, from, mergeMap, Observable, of, switchMap, throwError } from 'rxjs'; 
 import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth.service';
 
 
 @Injectable() 
    export class AuthInterceptor implements HttpInterceptor { accessToken: string | null = null; 
   
        constructor(private authService: AuthService, private snackBar: MatSnackBar) {
            this.authService.accessToken.subscribe({ 
            next: (token: string | null) => { 
             if (typeof token === "string") this.accessToken = token; 
        } }) }
        
        intercept(req: HttpRequest<any>, next: HttpHandler):
         Observable<HttpEvent<any>> {
             const userToken = this.accessToken; 
             const modifiedReq = req.clone({
                 headers: req.headers.set('Authorization', `Bearer ${userToken}`), 
                }); 
                return next.handle(modifiedReq); 
            } 
        
        }