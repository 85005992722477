<div class="container-fluid" style="height: 100%; width: 100%;">
    <div class="row justify-content-center align-items-center" style="height: 100%;">

        <!-- Login Form Section -->
        <div class="col-lg-6 col-md-12" id="loginSection">
            <div class="text-center mb-4 mt-4">
                <img src="/assets/gpstore-logo.svg" class="w-30" alt="logo">
            </div>
            <div class="container">
                <!-- Login -->
                <div class="m-3" *ngIf="currentSection === 'login'">
                    <h5 class="text-start mb-4">Login</h5>
                    <form class="m-auto" (submit)="onLogin()" [formGroup]="loginForm">
                        <!-- mobile -->
                        <!-- <div class="mb-3 row">
                            <label for="inputMobile" class="form-label mobileLabel">Mobile number</label>
                            <div class="d-flex">
                                <ngx-intl-tel-input class="d-block w-100 phone" formControlName="mobileInput"
                                    [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                    [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedKingdom"
                                    [maxLength]="15" [phoneValidation]="true">
                                </ngx-intl-tel-input>
                            </div>
                        </div> -->
                        <!-- <div class="text-secondary text-center">Or</div> -->
                        <!-- email -->
                        <div class="form-group mb-3">
                            <label for="inputEmail" class="form-label emailLabel">Mobile number /Email id </label>
                            <input type="text" class="form-control" id="inputEmail" formControlName="emailInput"
                                [ngClass]="{ 'is-invalid': isSubmitting && loginForm.errors?.['emailOrPhoneRequired'] }"
                                placeholder="Enter your registered Mobile number/Email id" aria-describedby="emailHelp">
                        </div>
                        <!-- Custom validator error message -->
                        <div *ngIf="isSubmitting && loginForm.errors?.['emailOrPhoneRequired']"
                            class="invalid-feedback">
                            Email or Phone Number is required
                        </div>
                        <!-- password -->
                        <div class="form-group mb-2 position-relative">
                            <label for="inputPassword" class="form-label passwordLabel">Password *</label>
                            <input type="{{ passwordVisible ? 'text' : 'password' }}" class="form-control"
                                id="inputPassword" formControlName="password" placeholder="Enter your Password"
                                [ngClass]="{ 'is-invalid': isSubmitting && f['password'].errors }" required>
                            <mat-icon class="pwd-eye" (click)="togglePasswordVisibility()">
                                {{ passwordVisible ? 'visibility' : 'visibility_off' }}
                            </mat-icon>
                        </div>
                        <!-- <div *ngIf="isSubmitting && f['password'].errors" class="invalid-feedback">
        <div *ngIf="f['password'].errors['required']">Password is required</div>
    </div> -->
                        <!-- remember me -->
                        <div class="form-group row mb-4">
                            <div class="col-6">
                                <input type="checkbox" class="form-check-input" id="checkBox"
                                    formControlName="rememberme">
                                <label class="form-check-label remember" for="checkBox">Remember me</label>
                            </div>
                            <!-- forgot password -->
                            <div class="col-6 text-end">
                                <a class="forgetPassword cursor" (click)="showForgotPassword()">forgot password?</a>
                            </div>
                        </div>
                        <!-- login button -->
                        <div class="form-group mb-2">
                            <button type="submit" class="btn loginBtn w-100">Login</button>
                        </div>
                        <div class="form-group text-center mb-2">
                            <span style="color: #B3B3B3;">or</span>
                        </div>
                        <div class="form-group text-center mb-3 p-1">
                            <!-- <button type="button" class="email btn">
                                <span class="gmail-btn text-center"><img src="../../../assets/icon/g-signin.svg"
                                        alt="Gmail Logo" class="gmail-logo"></span>
                                <span class="text-center">Sign in</span>
                            </button> -->
                            <div  class="g_id_signin mt-3 mx-auto" style="width: fit-content;" id="g_id_onload" data-type="standard" data-text="signingoogle" data-shape="" data-client_id="138028886270-3a8pv7s0qf14fdcskl5b8cpn767ijk50.apps.googleusercontent.com" data-callback="handleCredentialResponse"></div>
                        </div>
                        <div class="form-group text-center mb-3">
                            <span class="createAcc">Don't have an account yet? <a href="/register"
                                    class="createNew">create new
                                    account</a></span>
                        </div>
                        <div class="form-group text-center mb-3">
                            <span class="guest-border"><a href="/home" class="Guest">Continue as a Guest <img
                                        src="../../../assets/icon/arrow-left.svg" alt="arrow"></a></span>
                        </div>
                    </form>

                </div>
                <!-- Forgot Password -->
                <div class="m-4" *ngIf="currentSection === 'forgetPassword'">
                    <h5 class="text-start mb-4">Forgot Password</h5>
                    <form (submit)="onForgotPassword()" [formGroup]="forgotPasswordForm">
                        <div class="mb-3 row">
                            <label for="inputMobile" class="form-label mobileLabel">Mobile number</label>
                            <div class="d-flex">
                                <ngx-intl-tel-input class="d-block w-100 phone" formControlName="mobileInput"
                                    [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                    [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedKingdom"
                                    [maxLength]="15" [phoneValidation]="true">
                                </ngx-intl-tel-input>

                            </div>

                        </div>
                        <div class="text-secondary text-center">Or</div>
                        <div class="form-group mb-4">
                            <label for="forgetPasswordInput" class="form-label forgetPasswordLabel">Email id *</label>
                            <input type="text" class="form-control" id="forgetPasswordInput"
                                formControlName="emailInput" placeholder="Enter your registered Email id" required>
                            <div *ngIf="isSubmitting && forgotPasswordForm.controls['emailInput'].errors"
                                class="invalid-feedback">
                                <div *ngIf="forgotPasswordForm.controls['emailInput'].errors['required']">Email is
                                    required</div>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <button type="submit" class="btn VerifyBtn w-50 d-block m-auto">Verify</button>
                        </div>
                        <div class="form-group text-center mb-3">
                            <span class="guest-border"><a href="/home" class="Guest">Continue as a Guest <img
                                        src="../../../assets/icon/arrow-left.svg" alt="arrow"></a></span>
                        </div>
                    </form>
                </div>
                <!-- OTP Verification -->
                <div class="m-4" *ngIf="currentSection === 'otpVerification'">
                    <h5 class="text-start mb-3">OTP Verification</h5>
                    <form (submit)="onOtpVerification()" [formGroup]="otpVerificationForm">
                        <div class="form-group mb-5">
                            <label for="inputOtp" class="form-label otpLabel">OTP</label>
                            <input type="text" class="form-control" id="inputOtp" formControlName="otp"
                                placeholder="Enter OTP" required>
                            <div *ngIf="isSubmitting && otpVerificationForm.controls['otp'].errors"
                                class="invalid-feedback">
                                <div *ngIf="otpVerificationForm.controls['otp'].errors['required']">OTP is required
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <button type="submit" class="btn changePasswordBtn w-50 d-block m-auto">Verify OTP</button>
                            <a href="#" class="text-center">Resend OTP</a>
                        </div>
                        <div class="form-group text-center mb-3">
                            <span class="guest-border"><a href="/home" class="Guest">Continue as a Guest <img
                                        src="../../../assets/icon/arrow-left.svg" alt="arrow"></a></span>
                        </div>
                    </form>
                </div>
                <!-- Change Password -->
                <div class="m-4" *ngIf="currentSection === 'changePassword'">
                    <h5 class="text-start mb-3">Change password</h5>
                    <form (submit)="onChangePassword()" [formGroup]="changePasswordForm">
                        <div class="form-group mb-3">
                            <label for="inputNewPassword" class="form-label newPasswordLabel">New password *</label>
                            <input type="password" class="form-control" id="inputNewPassword"
                                formControlName="newPassword" placeholder="Enter new password" required>
                            <div *ngIf="isSubmitting && changePasswordForm.controls['newPassword'].errors"
                                class="invalid-feedback">
                                <div *ngIf="changePasswordForm.controls['newPassword'].errors['required']">New password
                                    is required</div>
                                <div *ngIf="changePasswordForm.controls['newPassword'].errors['minlength']">Password
                                    must be at least 8 characters long</div>
                            </div>
                            <mat-icon class="pwd-eye" (click)="togglePasswordVisibility()">
                                {{ passwordVisible ? 'visibility' : 'visibility_off' }}
                            </mat-icon>
                            <span class="validation">Password contains upper & lowercase letters, symbols, numbers, and
                                at least 8 characters</span>
                        </div>
                        <div class="form-group mb-3">
                            <label for="inputConfirmPassword" class="form-label confirmPasswordLabel">Confirm password
                                *</label>
                            <input type="password" class="form-control" id="inputConfirmPassword"
                                formControlName="confirmPassword" placeholder="Enter your password" required>
                            <div *ngIf="isSubmitting && changePasswordForm.controls['confirmPassword'].errors"
                                class="invalid-feedback">
                                <div *ngIf="changePasswordForm.controls['confirmPassword'].errors['required']">Confirm
                                    password is required</div>
                            </div>
                            <mat-icon class="pwd-eye" (click)="togglePasswordVisibility()">
                                {{ passwordVisible ? 'visibility' : 'visibility_off' }}
                            </mat-icon>
                        </div>
                        <div class="form-group mb-2">
                            <button type="submit" class="btn passwordLoginBtn w-100">Change Password</button>
                        </div>
                        <div class="form-group text-center mb-3">
                            <span class="guest-border"><a href="/home" class="Guest">Continue as a Guest <img
                                        src="../../../assets/icon/arrow-left.svg" alt="arrow"></a></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Image Carousel Section -->
        <div class="col-lg-6 col-md-12 carouselImage d-none d-lg-block">
            <div class="container">
                <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="img-wrapper">
                                <img src="../../../assets/carousel-image/login-carousel.svg" class="d-block w-100"
                                    alt="...">
                            </div>
                            <div class="carousel-caption d-none d-md-block text-start">
                                <h1>GP STORE</h1>
                                <h2>“Shop smart, eat well with GP Store your online grocery and food Platform”</h2>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="img-wrapper">
                                <img src="../../../assets/carousel-image/carousel-login.svg" class="d-block w-100"
                                    alt="...">
                            </div>
                            <div class="carousel-caption d-none d-md-block text-start">
                                <h1>GP STORE</h1>
                                <h2>“Shop smart, eat well with GP Store your online grocery and food Platform”</h2>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="0" class="active"
                            aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>