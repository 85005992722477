import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { RecentlyViewedProductsComponent } from './recently-viewed-products/recently-viewed-products.component';
import { ExploreByCategoriesComponent } from './explore-by-categories/explore-by-categories.component';
import { SubscribeProductComponent } from './subscribe-product/subscribe-product.component';
import { PlayStoreComponent } from './play-store/play-store.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GpstoreBannerComponent } from './gpstore-banner/gpstore-banner.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { CarouselModule } from 'primeng/carousel';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms'; 
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ProductListPagesComponent } from './product-list-pages/product-list-pages.component';
import { ProductPagesComponent } from './product-pages/product-pages.component';
import { ProductPopupDialogComponent } from './product-popup-dialog/product-popup-dialog.component';
import { AddCartComponent } from './add-cart/add-cart.component';
import { BestSellingComponent } from './best-selling/best-selling.component';
import { SubscribeListProductComponent } from './subscribe-list-product/subscribe-list-product.component';
import { AuthInterceptor } from './auth/inter/auth.interceptor';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
import { CancellationPolicyPageComponent } from './cancellation-policy-page/cancellation-policy-page.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider'
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    RecentlyViewedProductsComponent,
    ExploreByCategoriesComponent,
    SubscribeProductComponent,
    PlayStoreComponent,
    GpstoreBannerComponent,
    LoginComponent,
    RegisterComponent,
    ProductListPagesComponent,
    ProductPagesComponent,
    ProductPopupDialogComponent,
    AddCartComponent,
    BestSellingComponent,
    SubscribeListProductComponent,
    ProfilePageComponent,
    ErrorPageComponent,
    ContactUsPageComponent,
    AboutUsPageComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyPageComponent,
    CancellationPolicyPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    NgImageSliderModule,
    CarouselModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatIconModule,
    FormsModule,
    NgxIntlTelInputModule,
    SocialLoginModule,
    NgxSliderModule
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {

        autoLogin: false,

        providers: [{

          id: GoogleLoginProvider.PROVIDER_ID,

          provider: new GoogleLoginProvider(environment.Google_Client_Id)

        }]

      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
