<div class="mx-xl-4 px-xl-3 px-2"  
>
    <!-- carousel -->
    <div class="d-lg-flex gap-lg-3 px-2">
        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="img-wrapper">
                        <img src="../../assets/carousel-image/carousel-1.svg" class="d-block" alt="...">
                    </div>
                    <div class="carousel-caption  text-start">
                        <h1>GP STORE</h1>
                        <h2>There’s You can Buy </h2>
                        <h2>all Your Grocery Products</h2>
                        <button class="btn" (click)="groceryOpen()">Shop now</button>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="img-wrapper">
                        <img src="../../assets/carousel-image/carousel-2.svg" class="d-block" alt="...">
                    </div>
                    <div class="carousel-caption  text-start">
                        <h1>GP STORE</h1>
                        <h2>There’s You can Buy </h2>
                        <h2>all Your Grocery Products</h2>
                        <button class="btn" (click)="groceryOpen()">Shop now</button>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="img-wrapper">
                        <img src="../../assets/carousel-image/carousel-3.svg" class="d-block" alt="...">
                    </div>
                    <div class="carousel-caption  text-start">
                        <h1>GP STORE</h1>
                        <h2>There’s You can Buy </h2>
                        <h2>all Your Grocery Products</h2>
                        <button class="btn" (click)="groceryOpen()">Shop now</button>
                    </div>
                </div>
            </div>
            <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> -->
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
            </div>
 
        </div>
        <div class="hot-food d-block">
            <img src="../../assets/carousel-image/carousel-4.svg" alt="" class="d-none d-lg-block">
            <img src="../../assets/carousel-image/hot-food-mobile.svg" alt="" class="d-lg-none d-block">
 
            <div class="hot-food-captions  text-start px-3">
                <h2>Hot & Healthy </h2>
                <h2>Food</h2>
                <button class="btn food-btn" (click)="foodOpen()">Shop now</button>
            </div>
        </div>
    </div>
    <!-- cards -->
    <div class="gap-lg-4 mt-4">
        <p-carousel [value]="offers" [numVisible]="3" [numScroll]="1" [responsiveOptions]="responsiveOptions"
            [showIndicators]="false" class="custom-carousel">
            <ng-template let-offer pTemplate="item">
 
                <div class="card mx-2 cursor" [ngStyle]="{'background-color': offer.backgroundColor}">
                    <div class="cards-food justify-content-between pe-lg-1">
                        <div class=" cards-image ">
                            <img [src]="offer.image" class="img-fluid rounded-start" alt="...">
                        </div>
                        <div class="scroll-card">
                            <div class="card-body pe-3">
                                <div class="card-text ">
                                    <span [ngStyle]="{'color': offer.highlightColor}" class="cards-upto pe-lg-2">Up
                                        to</span>
                                    <span [ngStyle]="{'color': offer.textColor}"
                                        class="cards-offer ms-1">{{offer.text1}}</span>
                                </div>
                                <div class="card-text">
                                    <span [ngStyle]="{'color': offer.highlightColor}" class="cards-upto ">On</span>
                                </div>
                                <div>
                                    <span [ngStyle]="{'color': offer.textColor}"
                                        class="cards-offer">{{offer.text2}}</span>
                                </div>
                                <div class="card-text">
                                    <small [ngStyle]="{'color': offer.highlightColor}"
                                        class="cards-date">{{offer.offerDetails}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
 
        </p-carousel>
    </div>
 
 
    <div class=" arrow" [ngClass]
    ="{'visible': showArrow}"
    (click)
    ="scrollToTop()">
        <img src="../../assets/images/scroll-top-arrow.svg" alt="">
    </div>
     
 
    <app-explore-by-categories></app-explore-by-categories>
    <app-recently-viewed-products></app-recently-viewed-products>
 
    <!-- <app-best-selling-products></app-best-selling-products> -->
    <!-- <app-best-selling-foods></app-best-selling-foods> -->
 
    <!--  -->
    <div *ngFor="let data of  mainCategories; let j= index">
        <app-best-selling [data]="data"></app-best-selling>
    </div>
 
    <!--  -->
    <div class="border  "></div>
    <app-subscribe-product></app-subscribe-product>
    <app-play-store></app-play-store>
</div>
 