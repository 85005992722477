import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { ProductPopupDialogComponent } from '../product-popup-dialog/product-popup-dialog.component';
import { ProductService } from '../services/product.service';


@Component({
  selector: 'app-product-list-pages',
  templateUrl: './product-list-pages.component.html',
  styleUrls: ['./product-list-pages.component.css']
})
export class ProductListPagesComponent {
  showProductList: boolean = true;
  showHr: boolean = false;
  showFilterSection: boolean = false;
  isGroceryMenuOpen: boolean = false;
  isFrozenFoodOpen: boolean = false;
  activeButton: number = 1;
  isSortMenuOpen: boolean = false;
  homeData: any;
  categoryKey: any
  selectedVariation: any;
  userId: string | null = null;
  add_cart: boolean = true;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();

  constructor(public auth: AuthService, public router: Router, private route: ActivatedRoute, private http: HttpClient, private productService: ProductService, private dialog: MatDialog, private elementRef: ElementRef,private cartService: CartService) {
    this.auth.userInfo.subscribe((data: any) => {
          this.userId = data?.id;
        });
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      const searchQuery = params['q'];
      this.categoryKey = params['category'];
      if (searchQuery) {
        this.ProductListCall(searchQuery, true);

      }
    });

    this.categoriesListLevel_0()
    this.productService.getData().subscribe(data => {
      this.homeData = data;
      if (this.homeData) {
        this.categoriesListLevel_0();

      }
    });

  }
  toggleFilterSection() {
    this.showProductList = !this.showProductList;
    this.showFilterSection = !this.showFilterSection;
  }
  onCategoryClick(item: any): void {
    // Toggle checkbox status when div is clicked
    item.isChecked = !item.isChecked;
  
    // Gather all selected categories
    const selectedCategories = this.subCategories_list
      .filter((category: any) => category.isChecked)
      .map((category: any) => category.id);
  
    // Call ProductListCall with selected categories
    this.ProductListCall(selectedCategories);
  }

  toggleSortMenu(): void {
    this.isSortMenuOpen = !this.isSortMenuOpen;
  }

  sortBy(criteria: string): void {

    this.isSortMenuOpen = false;
  }
  categoryList: any[] = [];
  subCategories: any[] = [];
  subCategories_list: any[] = [];
  mainCategories: any[] = [];
  //
  frozenFoodItems: string[] = [];
  foodCategories: string[] = [];
  productList: any[] = [];
  activeCategoryId: string = '';
  activeSubCategoryId: string = '';

  openProductDialog(product: any,selectedVariationId: string): void {
   
    const variationId = this.selectedVariation|| selectedVariationId;
   
    this.dialog.open(ProductPopupDialogComponent, {
      data: {
        id: product.id,
        datafrom: '',
        variationId: variationId
      },
      width: '90%',
      height: '90%'
    });
    this.selectedVariation=''
  }
 
  grocery_click(productId: string, selectedVariation: string): void {
   
    const bestSelling = "bestSelling";
    const variationId = this.selectedVariation||selectedVariation;
   
    this.router.navigate(['/product-pages', productId], {
      state: { dataToPass: { datafrom: bestSelling , variationId: variationId } }
    });
 
  }
 


  //api call
  isDropdownContentVisible: boolean[] = [];
  Category_Section(id: any, categorykey: any) {
    this.activeCategoryId = id;
    this.homeData = '';
    this.categoriesListLevel_1(id)
    this.categoryKeyCall(categorykey)
    this.showHr = !this.showHr;
  }

  Category_menu(id: any, index: number) {
    this.categoriesListLevel_2(id)
    this.activeSubCategoryId = id;
    // this.ProductListCall( id);
  }

  toggleDropdownContent(event: MouseEvent, index: number, categoryId: any): void {

    event.stopPropagation();

    this.isDropdownContentVisible[index] = !this.isDropdownContentVisible[index];
    if (this.isDropdownContentVisible[index]) {
      for (let i = 0; i < this.isDropdownContentVisible.length; i++) {
        if (i !== index) {
          this.isDropdownContentVisible[i] = false;
        }
      }
    }
    if (this.isDropdownContentVisible[index]) {
      this.Category_menu(categoryId, index);


    }

  }


  categoriesListLevel_0(): void {
    const requestBody = {
      "status": 1,
      parentId: '',
      level: '0',
    };

    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const element = this.elementRef.nativeElement.querySelector('.fixed-margin-top');
          if (element) {
            element
              .scrollIntoView({
                top:
                  0
                , behavior:
                  'smooth'
                , block:
                  'start'
                , inline:
                  'nearest'
              });
          }
          if (this.categoryKey) {
            this.homeData = null
            let selectedCategory: any = data.categories_output.find((x: any) => x.categorykey == this.categoryKey);

            if (selectedCategory != -1) {
              this.activeCategoryId = selectedCategory.id;
              this.categoriesListLevel_1(this.activeCategoryId)
              this.categoryKeyCall(this.categoryKey)
            }
          }

          this.mainCategories = data.categories_output;
          // this.ProductListCall()

          if (this.homeData) {
            const parentId = this.homeData.parentId;
            this.categoriesListLevel_1(parentId)

          }
        }
      },
    );
  }

  categoriesListLevel_1(parentId: string): void {
    const requestBody = {
      status: 1,
      parentId: parentId,
      level: '1',
    };

    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const element = this.elementRef.nativeElement.querySelector('.fixed-margin-top');
          if (element) {
            window.scroll(0, 0);
          }
          this.subCategories = data.categories_output;
          if (this.subCategories.length > 0) {
            this.showHr = true;
          }
          this.isDropdownContentVisible = new Array(this.subCategories.length).fill(false);


          if (this.homeData) {
            const subcategory_Id = this.homeData.subcategory_Id;
            this.activeCategoryId = this.homeData.parentId
            this.activeSubCategoryId = subcategory_Id

            let subCategoryindex = this.subCategories.findIndex((x: any) => x.id == subcategory_Id);

            if (subCategoryindex != -1) {
              this.isDropdownContentVisible[subCategoryindex] = !this.isDropdownContentVisible[subCategoryindex];
              if (this.isDropdownContentVisible[subCategoryindex]) {
                for (let i = 0; i < this.isDropdownContentVisible.length; i++) {
                  if (i !== subCategoryindex) {
                    this.isDropdownContentVisible[i] = false;
                  }
                }
              }
              if (this.isDropdownContentVisible[subCategoryindex]) {
                this.categoriesListLevel_2(subcategory_Id)

              }

            }
          }
        }
      },
    )
  }

  categoriesListLevel_2(parentId: string): void {
    this.subCategories_list = [];
    const requestBody = {
      status: 1,
      parentId: parentId,
      level: '2',
    };

    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.subCategories_list = data.categories_output;
          this.ProductListCall(data.categories_output[0].id);
        }
      },
    )
  }

  categoryKeyCall(categoryKey: string): void {
    const requestBody = {
      "status": 1,
      "categoryKey": categoryKey
    };

    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.categoryList = data.categories_output;
          const categoryListIds: string[] = [];
          this.categoryList.forEach((category: any) => {
            const categoryList_id = category.id;
            categoryListIds.push(categoryList_id);

          });
          this.ProductListCall(categoryListIds);
        }
      },
    )
  }


  selectSize(id: any, variation: any): void {
    let productIndex = this.productList.findIndex((x: any) => x.id == id);

    if (productIndex != -1) {
      this.productList[productIndex].price = variation.priceToShow;
      this.productList[productIndex].originalPrice = variation.originalPrice;
      this.selectedVariation = variation.productvariationid;
    }

  }
  ProductListCall(query: string | string[], isSearchQuery: boolean = false): void {
    const requestBody: any = {
      status: 1,
      isSale: true
    };

    if (isSearchQuery) {
      // Expecting a search query as a string
      requestBody.q = query as string; // Ensuring that it's treated as a string
    } else {
      // Expecting category IDs as an array of strings
      requestBody.categoryIds = query as string[]; // Ensuring that it's treated as an array of strings
    }

    if (this.userId) {
      requestBody.userId = this.userId;
    }

    this.http.post(environment.apiUrl + `/products/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.productList = data.products_output;

          this.productList.forEach((product: any) => {
            if (product.productvariations && product.productvariations.length > 0) {
              product.variations = [];
              product.productvariations.forEach((variation: any) => {
                if (product.productvariations[0]) {
                  // Set the first variation as default
                  product.originalPrice = product.productvariations[0].mrpprice;
                  product.variationvalue = product.productvariations[0].variationvalue;
                  product.price = product.productvariations[0].sellingprice;
                  product.originalPrice = product.productvariations[0].mrpprice;
                  product.variationvalue = product.productvariations[0].variationvalue;
                  product.variationshortname = product.productvariations[0].variationshortname;
                  product.selectedVariation = product.productvariations[0].productvariationid
                  product.cartquantity = product.productvariations[0].cartquantity
                  product.cartid = product.productvariations[0].cartid
                  
                  product.variations.push({
                    originalPrice: variation.mrpprice,
                    priceToShow: variation.sellingprice,
                    variationvalue: variation.variationvalue,
                    variationshortname: variation.variationshortname,
                    productvariationid: variation.productvariationid
                  });

                }
              });
            }
          });

          this.productList.forEach((x: any) => {
            x.src = [];
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${x.id}`).subscribe((datas: any) => {
              if (datas.status === "SUCCESS") {
                if (datas.uploads.length > 0) {
                  this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      x.src.push(res.uploads.base64data);
                    }
                  });
                }
              }
            });
          });

          this.wish_list()
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
    // this.http.post(environment.apiUrl + `/products/list`, requestBody, { responseType: 'json' }).subscribe(
    //   (data: any) => {
    //     if (data.status === 'SUCCESS') {
    //       this.productList = data.products_output;


    //       this.productList.forEach((product: any) => {
    //         if (product.productvariations && product.productvariations.length > 0) {
    //           product.variations = [];
    //           product.productvariations.forEach((variation: any) => {
    //             if (product.productvariations[0]) {
    //               // Set the first variation as default
    //               product.originalPrice = product.productvariations[0].mrpprice;
    //               product.variationvalue = product.productvariations[0].variationvalue;
    //               product.price = product.productvariations[0].sellingprice;
    //               product.originalPrice = product.productvariations[0].mrpprice;
    //               product.variationvalue = product.productvariations[0].variationvalue;
    //               product.variationshortname = product.productvariations[0].variationshortname;
    //               product.selectedVariation = product.productvariations[0].productvariationid;
    //               product.variations.push({
    //                 originalPrice: variation.mrpprice,
    //                 priceToShow: variation.sellingprice,
    //                 variationvalue: variation.variationvalue,
    //                 variationshortname: variation.variationshortname,
    //                 productvariationid: variation.productvariationid
    //               });

    //             }
    //           });
    //         }
    //       });
    //       data.products_output.forEach((x: any) => {

    //         x.src = [];
    //         this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${x.id}`).subscribe((datas: any) => {

    //           if (datas.status === "SUCCESS") {
    //             if (datas.uploads.length > 0) {

    //               this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`).subscribe((res: any) => {
    //                 if (res.status === "SUCCESS") {
    //                   x.src.push(res.uploads.base64data);
    //                 }
    //               });
    //             }
    //           }
    //         })
    //       })
    //     }
    //   },
    // );
  }
  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity
    };

    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const product = this.productList.find(item => item.id === productId);
          if (product) {
            product.cartquantity = quantity; 
          }
  
          this.addedToCart.set(productId, true);
          this.cartService.updateCartCount(data.newCartCount);

        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }

  getQuantity(productId: number): number {

    return this.quantities.get(productId) || 1;

  }
  increaseQuantity(productId: number, productVariationId: string) {
    const product = this.productList.find(item => item.id === productId);
    if (product) {
      product.cartquantity += 1;
      this.Add_to_cart(productId, productVariationId, product.cartquantity);
    }
  }
  
  decreaseQuantity(productId: number, productVariationId: string, cartId: any) {
    const product = this.productList.find(item => item.id === productId);
    if (product) {
      if (product.cartquantity > 1) {
        // Decrease quantity and update the cart
        product.cartquantity -= 1;
        this.Add_to_cart(productId, productVariationId, product.cartquantity);
      } else if (product.cartquantity === 1) {
        // If the quantity is 1, set it to 0 and call delete function
        product.cartquantity = 0;
        this.deleteCartItem(cartId);  // Call delete function
      }
    }
  }

  deleteCartItem(cartId: any): void {
    const requestBody = {
      updatedBy: this.userId,
      cartIds: [cartId],
    };
  
    this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          if (this.userId !== null && this.userId !== undefined) {
            this.cartService.updateCartCount(this.userId);
          } else {
            console.error('User ID is null or undefined. Cannot update cart count.');
          }
        }
      },
      (error: any) => {
        console.error('An error occurred:', error);
      }
    );
  }

  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
    event.stopPropagation(); // Prevent triggering parent events
  
    const newStatus = !product.wishlistIcon;

    product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
    if (this.userId) {
      const requestBody = {
        status: newStatus ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId
      };
  
      this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
        .subscribe(
          (data: any) => {
            // Handle the success response here if needed
          },
          (error) => {
            console.error("API Error:", error);
          }
        );
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigate(['/login']);
    }
  }
  
  wish_list(): void {
    // if (!this.userId) {
    //   this.router.navigate(['/login']);
    //   return;
    // }
  
    const requestBody = {
      userId: this.userId,
    };
  
    this.http.post(environment.apiUrl + `/wishlistitems/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            const wish_list_product = data.wishlistitems_output;
  
            // Loop through productList and check if product is in the wishlist
            this.productList.forEach((product: any) => {
              const isInWishlist = wish_list_product.some((item: any) => item.productid === product.id);
              product.wishlistIcon = isInWishlist; // Set wishlist icon to true if in wishlist
              
            });
          }
        },
        (error) => {
          console.error("Wishlist API Error:", error);
        }
      );
  }
  
}
