<!-- card-filter.component.html -->
<div class="mt-4" >
  <h5 class="mb-0 ">Explore by Categories</h5>

  <div class="d-flex justify-content-end Categories pt-1">
    <div
    *ngFor="let category of categories"
    [id]="category.id"
    [class.active]="category.id === activeCategoryId"
    (click)="filterCards(category)"
  >
    {{ category.name }}
  </div>
    
  </div>

  <div class="border"></div>
  <!-- [ngClass]="{'margin-start': (i + 1) % 5 === 0}"  -->
      <!-- <br *ngIf="(i + 1) % 4 === 0"> -->

  <div class="card-scroll-container">
  <div class="row" >
    <div *ngFor="let card of filteredCards; let i = index" 
    class="card-container col col-lg-3 ">
      <div class="card " >
        <div class="card-body d-flex justify-content-between align-items-center object-fit-contain" (click)="category_card(card.id,card.title,card.level,card.parentid)">
          <div class="wrapper" *ngIf="card.src.length > 0">
            <img [src]="card.src[0]" alt="image" class="image">
          </div>
          <h6 class="card-title text-truncate">{{ card.title }}</h6>
          <img src="../../assets/explore-by-categories works/arrow.svg" class="arrow" alt="arrow">
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="border mt-0 mt-lg-4"></div>
</div>
