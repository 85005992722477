import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
 
declare var $: any;
@Component({
  selector: 'app-product-pages',
  templateUrl: './product-pages.component.html',
  styleUrls: ['./product-pages.component.css']
})
export class ProductPagesComponent implements OnInit {
  isSale: boolean | undefined;
  isSubscribe: boolean | undefined;
  mrpPrice: number | undefined;
  priceToShow!: number;
  variationDisplay: string | undefined;
  selectedVariation: any;
  productDetails: any = {};
  productVariations: any[] = [];
  variations: any[] = [];
  dataFromPreviousComponent: string | undefined;
  mainImage: string = '';
  selectedIndex: number = 0;
  selectedSize = '50 ML';
  isExpanded = false;
  isDeliverySchedule: boolean = false;
  productImages: { src: string[] } = { src: [] };
  sanitizedDescription: SafeHtml = '';
  activeTab = 'description';
  product_list_Id: any;
  additionaldetails: any;
  showMore: boolean = false;
  FoodDeliverySlot: boolean = false;
  responsiveOptions: any | undefined;
  numVisible: number = 1;
  variationid:any;
  userId: string | null = null;
  add_cart: boolean = true;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
  startIndex: number = 0;
  endIndex: number = 5;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  // numVisible: number = 3;
 
    // Image sources for controls
    prevImageSrc: string = '../../assets/icon/carousel-down.svg';
    nextImageSrc: string = '../../assets/icon/carousel-down.svg';
   
  offers = [
    {
      title1: "Johnson's & Johnson's Baby Shampoo",
      title2: "Johnson's & Johnson's Baby Soap",
      image1: "../../assets/images/johnsonoil.svg",
      image2: "../../assets/images/johnson.svg",
      comboOffer: "Combo offer",
      extraOffer: "Save extra 20%",
      price: "£6.98",
      individualPrices: "(£4.99 + £1.99)"
    },
    {
      title1: "Johnson's & Johnson's Baby Shampoo",
      title2: "Johnson's & Johnson's Baby Soap",
      image1: "../../assets/images/johnsonoil.svg",
      image2: "../../assets/images/johnson.svg",
      comboOffer: "Combo offer",
      extraOffer: "Save extra 20%",
      price: "£6.98",
      individualPrices: "(£4.99 + £1.99)"
    },
    {
      title1: "Johnson's & Johnson's Baby Shampoo",
      title2: "Johnson's & Johnson's Baby Soap",
      image1: "../../assets/images/johnsonoil.svg",
      image2: "../../assets/images/johnson.svg",
      comboOffer: "Combo offer",
      extraOffer: "Save extra 20%",
      price: "£6.98",
      individualPrices: "(£4.99 + £1.99)"
    }
  ];
  @ViewChild('tabSection', { static: false }) tabSection!: ElementRef;
 
  constructor(
    public router: Router, 
    private route: ActivatedRoute, 
    private http: HttpClient, 
    private sanitizer: DomSanitizer, 
    public auth: AuthService,
    private cartService: CartService,
    private elementRef: ElementRef
  ) {
    const navigation = this.router.getCurrentNavigation();
    const dataToPass = navigation?.extras?.state?.['dataToPass'];
  console.log(dataToPass,"dataToPass")
    // Check if dataToPass exists and access its properties safely
    if (dataToPass) {
      this.dataFromPreviousComponent = dataToPass.datafrom;
      this.variationid = dataToPass.variationId;
  console.log(this.dataFromPreviousComponent,"dataFromPreviousComponent");
  
      if (this.dataFromPreviousComponent === 'subscribeProduct') {
        this.isDeliverySchedule = true;
      } else {
        this.isDeliverySchedule = false;
      }
    }
  
    // Subscribing to auth userInfo
    this.auth.userInfo.subscribe((data: any) => {
      this.userId = data?.id;
    });
  }
  
  ngOnInit(): void {
    this.product_list_Id = this.route.snapshot.paramMap.get('id')!;
    this.ProductListCall(this.product_list_Id);
    this.responsiveOptions = [
      {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
      }
    ];
 
    this.updateVisibleItems();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItems();
  }
 
 
 
  updateVisibleItems() {
 
    const width = window.innerWidth;
    if (width > 1199) {
      this.numVisible = 1;
    }
  }
 
  // @ViewChild('myCarousel', { static: false }) myCarousel!: ElementRef;
 
  ngAfterViewInit() {
    // Initialize the carousel using jQuery
    // $(this.myCarousel.nativeElement).carousel({
    //   interval: 3000, // Adjust the interval as needed
    //   wrap: true
    // });
  }
 
 
  prevImage(): void {
   
    if (this.startIndex > 0) {
      this.startIndex--;
      this.endIndex--;
    }
    // if (this.startIndex > 0) {
    //   this.startIndex -= this.numVisible;
    // }
  }
 
  nextImage() {
    if (this.endIndex < this.productImages.src.length) {
      this.startIndex++;
      this.endIndex++;
   
    }
    // if (this.startIndex + this.numVisible < this.productImages.src.length) {
    //   this.startIndex += this.numVisible;
    // }
  }
 
  get visibleImages() {
    return this.productImages.src.slice(this.startIndex, this.startIndex + this.numVisible);
  }
 
  hoverImage(control: string, isHover: boolean) {
    if (control === 'prev') {
      this.prevImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    } else if (control === 'next') {
      this.nextImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    }
  }
 
  getKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }
 
  selectImage(index: number): void {
    this.mainImage = this.productImages.src[index];
    this.selectedIndex = index;
  }
 
  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    } else {
      // this.startX = e.touches[0].pageX - el.offsetLeft;
      // this.startY = e.touches[0].pageY - el.offsetTop;
    }
 
 
 
    this.scrollLeft = el.scrollLeft;
    // setTimeout(() => { this.isDragging = true }, 300);
  }
 
  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    // setTimeout(() => { this.isDragging = false });
    this.mouseDown = false;
  }
 
  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    // e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    } else {
      // x = e.touches[0].pageX - el.offsetLeft;
      // y = e.touches[0].pageY - el.offsetTop;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }
 
 
  onScroll(e: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, child: any) {
    if ((child.clientWidth - el.scrollLeft) <= el.clientWidth + 1) {
      // this.loadmore();
 
      // if (this.showMoreReview && !this.reviewLoading) {
      //   this.reviewLoading = true;
      //   this.loadmore();
      // }
    }
  }
 
  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }
 
  toggleShowMore() {
    this.showMore = !this.showMore;
 
    const tabSectionPosition = this.tabSection.nativeElement.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: tabSectionPosition - 200, // Adjust this value (-100) to control how far up you want to scroll
      behavior: 'smooth'
    });
 
  }
 
 
  addToCart(): void {
    // alert(Added ${this.selectedSize} of ${this.productDetails.name} to the cart!);
  }
 
 
  selectSize(variation: any): void {
 
 
    this.selectedVariation = variation;
    
    this.productDetails.selectedVariation = variation.productvariationid;
    
    this.priceToShow = this.getPriceToShow(variation);
  this.mrpPrice = variation.mrpprice;
 
    }
 
  getPriceToShow(variation: any): number {
    if (variation.issubscribe && variation.issale) {
      if (variation.dataFromPreviousComponent === 'bestSelling') {
        this.priceToShow = variation.sellingprice; // Set priceToShow when dataFromPreviousComponent is 'bestSelling'
      } else if (variation.dataFromPreviousComponent === 'subscribeProduct') {
        this.priceToShow = variation.priceupto7days; // Set priceToShow when dataFromPreviousComponent is 'subscribe'
      } else {
        this.priceToShow = variation.priceupto7days; // Default to priceupto7days if both issubscribe and issale are true
      }
    } else if (variation.issubscribe) {
      this.priceToShow = variation.priceupto7days; // Set priceToShow if only issubscribe is true
    } else if (variation.issale ) {
      this.priceToShow = variation.sellingprice; // Set priceToShow if only issale is true
    } else {
      this.priceToShow = variation.mrpprice; // Default to mrpPrice if neither issubscribe nor issale is true
    }
    return this.priceToShow; // Return the updated priceToShow
  }
 
 
 
 
 
  ProductListCall(subcategoryId?: string): void {
    const id = subcategoryId || '';
    const userIdParam = this.userId ? `&userId=${this.userId}` : '';
    this.http.get(`${environment.apiUrl}/products/get?id=${id}${userIdParam}`).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
         console.log(data,"data");
         
          const element = this.elementRef.nativeElement.querySelector('.scroll_top');
          if (element) {
            window.scroll(0, 0);
          }
          this.productDetails = data.products;
          this.productDetails.variations = [];  
           const matchingVariation = this.productDetails.productvariations.find(
            (variation:any) => variation.productvariationid === this.variationid
          );
         
          if (matchingVariation) {
            this.productDetails.cartquantity = matchingVariation.cartquantity;
            this.productDetails.cartid = matchingVariation.cartid;
            this.productDetails.selectedVariation = matchingVariation.productvariationid;
            
          }
          if (this.productDetails.productvariations && this.productDetails.productvariations.length > 0) {
 
 
            this.productDetails.productvariations.forEach((x: any) => {
 
              if (this.dataFromPreviousComponent === 'subscribeProduct' && x.issubscribe === true) {
                // Filter for subscribeProduct
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice, sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale:x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent
 
                });
              } else if (this.dataFromPreviousComponent === 'bestSelling' && x.issale === true) {
                // Filter for bestSelling
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice,
                  sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale:x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent
 
                });
              }else if(this.dataFromPreviousComponent === '' && x.issale === true){
              this.productDetails.variations.push({
                variationvalue: x.variationvalue,
                variationshortname: x.variationshortname,
                productvariationid: x.productvariationid,
                variationid: x.variationid,
                mrpprice: x.mrpprice,
                sellingprice: x.sellingprice,
                priceupto7days: x.priceupto7days,
                issale:x.issale,
                issubscribe: x.issubscribe,
                dataFromPreviousComponent: this.dataFromPreviousComponent
 
              });
            }
            })
           
            if (this.variationid) {
              const mathProductvariationId =  this.productDetails.variations.find(
                (variation: any) => variation.productvariationid === this.variationid
              );
              this.selectedVariation = mathProductvariationId;

 
            }else if ( this.productDetails.variations.length > 0) {
              this.selectedVariation =  this.productDetails.variations[0]; // Set the first variation as default

 
            }
             else {
              this.selectedVariation = this.productDetails.productvariations[0];

            }
            // Set the default variation (first one)
            // this.selectedVariation = this.productDetails.productvariations[0];
 
            // Calculate the default price to show based on the first variation
            this.getPriceToShow(this.selectedVariation);
            this.mrpPrice = this.selectedVariation.mrpprice;
          }
 
          // Other code for description and image handling
          if (this.productDetails.description) {
            this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(this.productDetails.description);
          } else {
            this.sanitizedDescription = '';
          }
 
          let x = data.products.additionaldetails;
 
          try {
            if (x && x.trim() !== '') {
              this.additionaldetails = JSON.parse(x);
            } else {
              this.additionaldetails = {}; // or any default value
            }
          } catch (e) {
            console.error("Error parsing additionaldetails:", e);
            this.additionaldetails = {}; // Handle the error by assigning a default value
          }
 
          this.http.get(`${environment.apiUrl}/uploads/list-by-datafor?dataFor=products&dataForId=${this.productDetails.id}`).subscribe((datas: any) => {
            if (datas.status === "SUCCESS") {
              this.productImages.src = [];
 
              datas.uploads.forEach((upload: any, index: number) => {
                this.http.get(`${environment.apiUrl}/uploads/get?id=${upload.id}`).subscribe((res: any) => {
                  if (res.status === "SUCCESS") {
                    this.productImages.src.push(res.uploads.base64data);
                    if (index === 0) { // Set the first image as the default main image
                      this.mainImage = this.productImages.src[0];
                      this.selectedIndex = 0;
                    }
                  }
                });
              });
            }
          });
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
 
  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    if (!this.userId) {
      // Redirect to login page if user is not logged in
      this.router.navigate(['/login']);
      return;
    }
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity
    };
 
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
 
          this.productDetails.cartquantity = quantity;
         
 
          // const product = this.productDetails.productvariations.find(
          //   (variation: any) => variation.productvariationid === data.productvariationid
          // );
          // if (product) {
          //   product.cartquantity = quantity;
           
          // }
 
          this.cartService.updateCartCount(data.newCartCount);  // Ensure cart count is updated correctly
        }
      },
      (error: any) => {
        console.error("Error in add-to-cart API call:", error);
      }
    );
  }
 
 increaseQuantity(productId: number, productVariationId: string) {
  const product = this.productDetails.productvariations.find(
    (variation: any) => variation.productvariationid === productVariationId
  );
  if (product) {
    product.cartquantity += 1;
 
    // this.Add_to_cart(productId, productVariationId, product.cartquantity);
    this.productDetails.cartquantity = product.cartquantity; // Update the main product cart quantity
  }
}
 
decreaseQuantity(productId: number, productVariationId: string, cartId: any) {
  const product = this.productDetails.productvariations.find(
    (variation: any) => variation.productvariationid === productVariationId
  );
  if (product) {
    if (product.cartquantity > 1) {
      product.cartquantity -= 1;
      this.Add_to_cart(productId, productVariationId, product.cartquantity);
    } else if (product.cartquantity === 1) {
      product.cartquantity = 0;
      this.productDetails.cartquantity = 0;  // Also set the main product cart quantity to 0
      this.deleteCartItem(cartId);  // Call delete function when quantity reaches zero
    }
  }
}
 
deleteCartItem(cartId: any): void {
  const requestBody = {
    updatedBy: this.userId,
    cartIds: [cartId],
  };
 
  this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
    (data: any) => {
      if (data.status === 'SUCCESS') {
        // Update cart count after deletion
        this.cartService.updateCartCount(data.newCartCount);
 
        // Find the product variation associated with the deleted cart item
        const product = this.productDetails.productvariations.find(
          (variation: any) => variation.cartid === cartId
        );
 
        if (product) {
          // Reset the cart quantity to 0 for the deleted product variation
          product.cartquantity = 0;
        }
 
        // Reset main product cartquantity if it was the last item in the cart
        this.productDetails.cartquantity = 0;
 
      }
    },
    (error: any) => {
      console.error('An error occurred while deleting the cart item:', error);
    }
  );
}
 
 
}
 
 
 