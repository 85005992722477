import { Component } from '@angular/core';

@Component({
  selector: 'app-play-store',
  templateUrl: './play-store.component.html',
  styleUrls: ['./play-store.component.css']
})
export class PlayStoreComponent {

}
