import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, catchError, forkJoin, map, of, Subscription, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { ProductService } from '../services/product.service';
 
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  searchQuery: string = '';
  productList: any[] = [];
  activeSubcategoryId: string | null = null;
  subCategories: any[] = [];
  mainCategories: any[] = [];
  groceryOpen: boolean = false;
  foodOpen: boolean = false;
  SubscribeOpen: boolean = false;
  cartItems: any[] = [];
  add_bagOpen = false;
  mapDropdownOpen = false;
  profiledownOpen = false;
  grocerydownOpen: number | null = null;
  fooddownOpen = false;
  profileDropdownOpen = false;
  lastScrollTop = 0;
  upperHeader: HTMLElement | null = null;
  headerNav: HTMLElement | null = null;
  name: string | null = null;
  userId: string = '';
  count: number = 0;
  homeData: any;
  activeCategoryId: any;
  user_details: any = {};
  profileImageUrl: string = '';
  list_categories: Subscription | undefined;
  listCategoriesSubscription: Subscription | undefined;
  pagesOutput: any[] = [];
 
  activeCategoryIndex: number | null = null;
  private variationsCache = new Map<string, any[]>();
  private variationsCacheSubject = new BehaviorSubject<Map<string, any[]>>(this.variationsCache);
 
  constructor(public auth: AuthService, public router: Router, private http: HttpClient, private elementRef: ElementRef, private productService: ProductService, private cartService: CartService) {
    this.pageType()
 
    this.auth.userInfo.subscribe(data => {
 
      if (data) {  // Check if userInfo is not null
        this.userId = data.id;
        this.cartService.updateCartCount(this.userId);
        this.cartList()
        this.getUserDetails()
 
      }
    })
    this.cartService.cartCount$.subscribe(count => {
      this.count = count; // This will get updated when cartCount changes
    });
  }
 
  ngOnInit(): void {
    this.upperHeader = document.getElementById('upper-header');
    this.headerNav = document.getElementById('header-nav');
    this.categoriesListLevel_0();
    this.list_categories = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!this.router.url.includes('product-list-pages')) {
          this.activeCategoryId = null; // Reset the active category ID
        }
      }
    });
 
  }
  pageType(): void {
    const requestBody = {
      sort:"ASC",
      sortColumn:"pages.title"
    };
 
    this.http.post(environment.apiUrl + `/pages/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
 
            this.pagesOutput = data.pages_output;
          }
        },
        (error) => {
          console.error("Wishlist API Error:", error);
        }
      );
  }
  click_pageType(page: any): void{
 
    // Handle the response data here
    if (page.pagetype === 'about-us') {
      this.router.navigate(['/about-us'], { state: { pageData: page.pagetype } });
    } else if (page.pagetype === 'cancellation-policy') {
      this.router.navigate(['/cancellation-policy'], { state: { pageData: page.pagetype  } });
    } else if (page.pagetype === 'privacy-policy') {
      this.router.navigate(['/privacy-policy'], { state: { pageData: page.pagetype  } });
    } else if (page.pagetype === 'terms-conditions') {
      this.router.navigate(['/terms-conditions'], { state: { pageData: page.pagetype  } });
    } else {
      console.error('Unknown pagetype:', page.pagetype);
    }
 
}
  ngOnDestroy(): void {
    // this.list_categories.unsubscribe();
    if (this.list_categories) {
      this.list_categories.unsubscribe();
    }
    if (this.listCategoriesSubscription) {
      this.listCategoriesSubscription.unsubscribe();
    }
    // Reset the state when the component is destroyed
    this.homeData = null;
    this.activeCategoryId = null;
  }
  ngAfterViewInit(): void {
 
    this.listCategoriesSubscription = this.productService.getData().subscribe(data => {
      this.homeData = data;
      if (this.homeData) {
        this.categoriesListLevel_1(data.parentId);
 
      }
 
    });
    this.homeData = ''
 
  }
  navigateToProfile(section: string, event: MouseEvent) {
    event.stopPropagation();
    const offcanvasElement = this.elementRef.nativeElement.querySelector('#offcanvasRight');
    const bsOffcanvas = (window as any).bootstrap.Offcanvas.getInstance(offcanvasElement);
    if (bsOffcanvas) {
      bsOffcanvas.hide();
    }
    this.mapDropdownOpen = false;
    this.profileDropdownOpen = false;
    this.router.navigate(['/profile'], { queryParams: { section } });
  }
 
 
 
 
 
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > this.lastScrollTop) {
      // Scroll down
      if (this.upperHeader) {
        this.upperHeader.style.top = '-50px'; // Hide upper header
 
      }
      if (this.headerNav) {
        this.headerNav.classList.add('fixed-top'); // Fix main header
 
      }
    } else {
      // Scroll up
      if (this.upperHeader) {
        this.upperHeader.style.top = '0'; // Show upper header
 
      }
      if (scrollTop === 0 && this.headerNav) {
        this.headerNav.classList.remove('fixed-top'); // Remove fixed position
 
      }
    }
 
    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  }
 
  isSubscribeActive: boolean = false;
 
 
 
  toggleDropdown(index: number): void {
 
    this.grocerydownOpen = this.grocerydownOpen === index ? null : index;
    this.categoriesListLevel_1(this.mainCategories[index].id);
  }
  add_bag(isOpen: boolean) {
    this.add_bagOpen = isOpen;
    this.mapDropdownOpen = false;
    this.profileDropdownOpen = false;
 
    if (this.userId) {
      this.cartList()
    }
  }
 
  products = [
    {
      price: 2.99,
      total: 5.98,
    }
  ];
  closeDropdown(type: string): void {
    if (type === 'map') {
      this.mapDropdownOpen = false;
    } else if (type === 'profile') {
      this.profileDropdownOpen = false;
    } else if (type === 'grocery') {
      this.grocerydownOpen = null;
    }
  }
  mapDropdown(event: Event): void {
    event.stopPropagation();
    this.mapDropdownOpen = !this.mapDropdownOpen;
    this.profileDropdownOpen = false;
  }
  profiledown(event: Event): void {
    event.stopPropagation();
    this.profiledownOpen = !this.profiledownOpen;
    this.profileDropdownOpen=!this.profileDropdownOpen
    this.mapDropdownOpen = false;  // Close other dropdowns
  }
  grocerydown(event: Event, index: number): void {
    event.stopPropagation();
    this.grocerydownOpen = this.grocerydownOpen === index ? null : index;
  }
  fooddown(event: Event): void {
    event.stopPropagation();
    this.fooddownOpen = !this.fooddownOpen;
    // this.profileDropdownOpen = false;
  }
  detectLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
      }, (error) => {
        console.error(error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }
 
  toggleProfileDropdown(event: Event): void {
    event.stopPropagation();
    this.profileDropdownOpen = !this.profileDropdownOpen;
    this.mapDropdownOpen = false;
 
  }
 
  saveAddress(): void {
    const address = (document.querySelector('input[placeholder="Add address"]') as HTMLInputElement).value;
  }
 
  // private closeDropdownOnClickOutside(event: Event): void {
  //   const target = event.target as HTMLElement;
  //   if (!target.closest('.nav-item.dropdown')) {
  //     this.mapDropdownOpen = false;
  //   }
  // }
  // mobile
  isMobileMenuOpen = false;
  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    const menu = document.getElementById('mobile-menu');
    if (menu) {
      menu.style.display = this.isMobileMenuOpen ? 'block' : 'none';
    }
  }
 
  //  login
  loginpage() {
    this.router.navigate(['/login']);
  }
 
  // logout
  signout() {
 
    this.auth.logout();
    this.name = null;
    localStorage.clear();
    this.userId = "";
    this.cartService.resetCartCount();
    this.clearCookies();
    // this.router.navigate(['/home']).then(() => {
    // });
  }
 
  clearCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  }
  subscribeList_page(): void {
    this.SubscribeOpen = true;
    const offcanvasElement = this.elementRef.nativeElement.querySelector('#offcanvasRight');
    const bsOffcanvas = (window as any).bootstrap.Offcanvas.getInstance(offcanvasElement);
    if (bsOffcanvas) {
      bsOffcanvas.hide();
    }
    this.grocerydownOpen = null
    this.router.navigate(['/subscribe-list-pages']);
  }
  categorykey_click(categorykey: string): void {
 
    this.router.navigate(['/product-list-pages'], { queryParams: { category: categorykey } });
  }
 
  navigateToProductList(subcategory_id: any, subcategory_title: any, subcategory_level: any, parentid: any): void {
    const dataToPass = {
      subcategory_Id: subcategory_id,
      subcategory_Title: subcategory_title,
      Level: subcategory_level,
      parentId: parentid
    };
    this.productService.setData(dataToPass);
    this.router.navigate(['/product-list-pages']);
  }
 
  navigateToProductListAndCloseOffcanvas(id: string, name: string, level: number, parentId: string): void {
    this.activeSubcategoryId = id;
    // Close the off-canvas menu
    const offcanvasElement = this.elementRef.nativeElement.querySelector('#offcanvasRight');
    const bsOffcanvas = (window as any).bootstrap.Offcanvas.getInstance(offcanvasElement);
    if (bsOffcanvas) {
      bsOffcanvas.hide();
    }
 
    // Navigate to the product list
    this.navigateToProductList(id, name, level, parentId);
  }
  groceryCategoryId: string | null = null;
 
  categoriesListLevel_0(): void {
    const requestBody = {
      status: 1,
      parentId: '',
      level: '0',
    };
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.mainCategories = data.categories_output;
 
          //variations
          this.mainCategories.forEach((categories: any) => {
            if (categories.categorykey && categories.categorykey.length > 0) {
              categories.categorykey = categories.categorykey;
            }
          })
 
          // if (this.homeData) {
          //   const parentId = this.homeData.parentId;
 
          //   this.categoriesListLevel_1(parentId)
          // }
          const groceryCategory = this.mainCategories.find(category => category.parentname.toLowerCase() === 'grocery');
          if (groceryCategory) {
            this.groceryCategoryId = groceryCategory.id;
          }
        }
      },
    );
  }
 
  categoriesListLevel_1(parentId: string): void {
    const requestBody = {
      status: 1,
      parentId: parentId,
      level: '1',
    };
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.subCategories = data.categories_output;
          if (this.homeData) {
 
            this.activeCategoryId = this.homeData.parentId;
 
          }
        }
      },
    )
    this.activeCategoryId = ''
  }
 
 
  // add-cart
  add_cart() {
    this.router.navigate(['/add-cart'])
    this.add_bagOpen = false
  }
  // add-to-cart-list api
  cartList() {
    const requestBody = { userId: this.userId };
    if (this.userId !== null) {
      this.http.post(environment.apiUrl + `/cart/list`, requestBody, { responseType: 'json' }).pipe(
        switchMap((data: any) => {
          if (data.status === 'SUCCESS') {
            this.cartItems = data.cart_output;
            this.count = data.count;
 
            const imageRequests = this.cartItems.map((item: any) => {
              return this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=users&dataForId=${item.productid}`).pipe(
                switchMap((datas: any) => {
                  if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                    return this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`);
                  } else {
                    return of({ status: 'ERROR' });
                  }
                }),
                map((res: any) => {
                  if (res.status === "SUCCESS") {
                    item.src = [res.uploads.base64data];
                  } else {
                    item.src = [];
                  }
                  return item;
                })
              );
            });
 
            // Use forkJoin to wait for all image requests to complete
            return forkJoin(imageRequests).pipe(
              switchMap((itemsWithImages: any[]) => {
                // Get variations for all items
                const variationRequests = itemsWithImages.map((item: any) => {
                  return this.variationApiCall(item.productid, item.productvariationid).pipe(
                    map((filteredVariations: any) => {
                      item.variations = filteredVariations;
 
                      const selectedVariation = item.variations.find((v: any) => v.id === item.productvariationid);
                      if (selectedVariation) {
                        item.selectedVariationId = selectedVariation.id;
                        item.selectedPrice = selectedVariation.sellingprice;
                        item.mrpPrice = selectedVariation.mrpprice;
                      }
 
                      return item;
                    })
                  );
                });
 
                return forkJoin(variationRequests);
              })
            );
          } else {
            return of([]);
          }
        }),
        catchError(error => {
          console.error("Error in cartList API call:", error);
          return of([]);
        })
      ).subscribe(
        (updatedItems: any[]) => {
          this.cartItems = updatedItems;
          this.getSubTotalPrice();
        }
      );
    }
  }
 
 
  variationApiCall(productid: any, productvariationid: any) {
    if (this.variationsCache.has(productid)) {
      return of(this.variationsCache.get(productid));
    }
 
    const requestBody = {
      productId: productid,
      productvariationId: productvariationid
    };
 
    return this.http.post(environment.apiUrl + `/products/list-productvariations`, requestBody, { responseType: 'json' }).pipe(
      map((data: any) => {
        if (data.status === 'SUCCESS') {
 
          const filteredVariations = data.productvariations_output.filter((variation: any) => variation.issale === true);
          this.variationsCache.set(productid, filteredVariations);
          this.variationsCacheSubject.next(this.variationsCache);
          return filteredVariations;
        } else {
          return [];
        }
      }),
      catchError(error => {
        console.error("Error in variationApiCall API call:", error);
        return of([]);
      })
    );
  }
  toggleCategory(open: boolean, index: number): void {
    this.activeCategoryIndex = open ? index : null;
 
    const categoryId = this.mainCategories[index].id;
 
    if (this.isGroceryCategory(categoryId)) {
      this.groceryOpen = open;
    } else {
      this.foodOpen = open;
    }
 
    if (open) {
      this.categoriesListLevel_1(categoryId);
    }
  }
  onChange(event: MouseEvent) {
    event.stopPropagation();
    this.homeData = null;
  }
 
  isGroceryCategory(id: string): boolean {
    // Check if the id corresponds to Grocery
    return id === this.groceryCategoryId; // Replace 'groceryId' with the actual Grocery id
  }
 
  isCategoryActive(index: number): boolean {
    return this.activeCategoryIndex === index;
  }
 
  // search
 
  onSearch(): void {
    if (this.searchQuery.trim()) {
      if (this.SubscribeOpen) {
        this.router.navigate(['/subscribe-list-pages'], {
          queryParams: { q: this.searchQuery.trim() }
        });
 
      }
      else {
        this.router.navigate(['/product-list-pages'], {
          queryParams: { q: this.searchQuery.trim() }
        });
 
      }
 
    }
  }
 
  getSubTotalPrice(): number {
    return this.cartItems.reduce((total, item) => {
      const sellingPrice = parseFloat(item.selectedPrice ?? '0');  // Use item.selectedPrice directly
      return total + (sellingPrice * item.quantity);
    }, 0);
  }
 
  getUserDetails() {
 
    this.http.get(environment.apiUrl + `/users/get?id=${this.userId}`).subscribe((res: any) => {
      if (res.status === "SUCCESS") {
        this.user_details = res.users;
        this.name = this.user_details.firstname
 
 
        // Handle imageinfo
        try {
          // Check if imageinfo is a valid string or object, else fallback to empty array
          this.user_details.imageinfo = typeof this.user_details.imageinfo === 'string'
            ? JSON.parse(this.user_details.imageinfo)
            : this.user_details.imageinfo || []; // Fallback to empty array
 
 
          if (Array.isArray(this.user_details.imageinfo) && this.user_details.imageinfo.length > 0) {
            const firstImage = this.user_details.id; // Corrected access
 
            // Set default profile image first
            this.profileImageUrl = "../../assets/images/profile-man.svg";
            // Fetch image data
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=users&dataForId=${firstImage}`).subscribe((datas: any) => {
 
              if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                const uploadId = datas.uploads[0].id;
 
                this.http.get(environment.apiUrl + `/uploads/get?id=${uploadId}`).subscribe((res: any) => {
                  if (res.status === "SUCCESS") {
 
                    // Check if base64 data is present and assign to profileImageUrl
                    // Check if base64 data is present
                    if (res.uploads && res.uploads.base64data) {
                      // Remove any extra prefix
                      const base64Data = res.uploads.base64data.trim();
                      const prefix = 'data:image/jpeg;base64,';
                      // Ensure the prefix is only added once
                      this.profileImageUrl = base64Data.startsWith(prefix)
                        ? base64Data
                        : prefix + base64Data;
 
                    } else {
                      console.error('No base64 data found in the image response.');
                    }
                  }
                });
              }
            });
          } else {
            // Handle empty or invalid imageinfo
            console.warn('No images found, using default profile image.');
            this.profileImageUrl = "../../assets/images/profile-man.svg"; // Default profile image
          }
        } catch (e) {
          console.error('Failed to parse imageinfo', e);
        }
      }
    });
  }
 
 
 
 
 
 
 
}
 
 