import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
 
@Component({
  selector: 'app-add-cart',
  templateUrl: './add-cart.component.html',
  styleUrls: ['./add-cart.component.css']
})
export class AddCartComponent {
  cartPage = true;
  productPage = true;
  orderPlaced = false;
  deliverAddress = false;
  deliverySchedule = false;
  placeOrderList = false;
  paymentProcess = false;
  orderEmpty = false;
  selectedDay: any;
  selectedAddressId: string = '';
  paymentActive: boolean = true;
  isNextButtonDisabled: boolean = true;
  selectedPaymentMethod: string | null = null;
  cartItems: any[] = [];
  variations: any[] = [];
  selectedItems: number[] = [];
  count: number | null = null;
  user_details: any = {};
  UserAddressList: any[] = [];
  DeliveryTimesLotsList: any[] = [];
  selectedSlots: any[] = [];
  selectedSlotId: string = '';
  isplaceOrderEnabled: boolean = false;
  currentStep: number = 0;
  tittle: string = 'cart';
  accessToken: string | null = null;
  userId: string = '';
  selectedVariationId:string | null = null;
  private variationsCache = new Map<string, any[]>();
  dataFromPreviousComponent: string | undefined;
  issubscribeProduct: boolean = false;
  private variationsCacheSubject = new BehaviorSubject<Map<string, any[]>>(this.variationsCache);
 
  constructor(public router: Router, private route: ActivatedRoute, private http: HttpClient, public auth: AuthService,private cartService: CartService) {
    console.log(this.userId,"userId")
    if (this.userId === '') {
      this.orderEmpty = true;
      this.cartPage = false;
      console.log(this.userId,"this.userId")
    }
    this.auth.userInfo.subscribe((data: any) => {
      this.userId = data.id;
      console.log(this.userId,"this.userId")
      if (this.userId) {
        this.orderEmpty = false;
        this.cartPage = true;
      }
      else{
        this.orderEmpty = true;
        this.cartPage = false;
 
      }
    });
    const navigation = this.router.getCurrentNavigation();
    const dataToPass = navigation?.extras?.state?.['dataToPass'];
 
    // Check if dataToPass exists and access its properties safely
    if (dataToPass) {
      this.dataFromPreviousComponent = dataToPass.datafrom;
 
      if (this.dataFromPreviousComponent === 'SubcribeProduct') {
        this.issubscribeProduct = true;
      this.cartList();
 
 
      } else {
        this.issubscribeProduct = false;
      }
    }else{
      this.cartList();
 
    }
 
  }
  // 1st page next btn
  productPage_next() {
    this.currentStep = 1;
    this.deliverAddress = true;
    this.productPage = false;
    this.placeOrderList = false;
 this.UserAddressListApi()
  }
  // 2nd page next btn
  deliverAddress_next() {
    this.currentStep = 2;
    this.deliverySchedule = true
    this.deliverAddress = false;
    this.placeOrderList = false;
 this.DeliveryTimesLots()
  }
  // 3rd page next btn
  deliverySchedule_next() {
    this.currentStep = 3;
    this.deliverySchedule = false;
    this.paymentProcess = true;
    this.isplaceOrderEnabled = true
    this.placeOrderList = true;
 
  }
 
  // 4th page next btn
  placeOrder() {
    this.currentStep = 4;
    if (this.isplaceOrderEnabled) {
      this.orderPlaced = true;
      this.cartPage = false;
    }
  }
 
  detectLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
      }, (error) => {
        console.error(error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }
 
  setActiveTittle(tittle: string): void {
    this.tittle = tittle;
  }
 
  onCheckboxChange(event: any, id: number) {
    if (event.target.checked) {
      // Add the id to the array if checkbox is checked
      this.selectedItems.push(id);
    } else {
      // Remove the id from the array if checkbox is unchecked
      this.selectedItems = this.selectedItems.filter(itemId => itemId !== id);
    }
    this.checkIfAnyCheckboxChecked();
  }
  checkIfAnyCheckboxChecked() {
    // If at least one item is selected, enable the "Next" button
    this.isNextButtonDisabled = this.selectedItems.length === 0;
  }
  // cart-list
  cartList() {
    const requestBody = { userId: this.userId };
 
    // Check if issubscribeProduct is true to decide which API to call
    const apiCall = this.issubscribeProduct
      ? this.http.post(environment.apiUrl + `/subcribe/list`, requestBody, { responseType: 'json' })
      : this.http.post(environment.apiUrl + `/cart/list`, requestBody, { responseType: 'json' });    
 
    apiCall.pipe(
      switchMap((data: any) => {
        if (data.status === 'SUCCESS') {
          this.cartItems = data.cart_output;
          this.count = data.count;
          const imageRequests = this.cartItems.map((item: any) => {
            return this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`).pipe(
              switchMap((datas: any) => {
                if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                  return this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`);
                } else {
                  return of({ status: 'ERROR' });
                }
              }),
              map((res: any) => {
                if (res.status === "SUCCESS") {
                  item.src = [res.uploads.base64data];
                } else {
                  item.src = [];
                }
                return item;
              })
            );
          });
 
          // Use forkJoin to wait for all image requests to complete
          return forkJoin(imageRequests).pipe(
            switchMap((itemsWithImages: any[]) => {
              // Get variations for all items
              const variationRequests = itemsWithImages.map((item: any) => {
                return this.variationApiCall(item.productid, item.productvariationid).pipe(
                  map((filteredVariations: any) => {
                    item.variations = filteredVariations;
 
                    const selectedVariation = item.variations.find((v: any) => v.id === item.productvariationid);
                    if (selectedVariation) {
                      item.selectedVariationId = selectedVariation.id;
                      item.selectedPrice = selectedVariation.sellingprice;
                      item.mrpPrice = selectedVariation.mrpprice;
                    }
 
                    return item;
                  })
                );
              });
 
              return forkJoin(variationRequests);
            })
          );
        } else {
          return of([]);
        }
      }),
      catchError(error => {
        console.error("Error in cartList API call:", error);
        return of([]);
      })
    ).subscribe(
      (updatedItems: any[]) => {
        this.cartItems = updatedItems;
        this.getMrpPrice();
        this.getSubTotalPrice();
      }
    );
  }
 
 
  UserAddressListApi(): void {
    const requestBody = {
      userId: this.userId,
    };
    this.http.post(environment.apiUrl + `/useraddress/list`, requestBody, { responseType: 'json' })
    .subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          if (Array.isArray(data.useraddress_output)) {
            this.UserAddressList = data.useraddress_output;
 
            // Find the default address and set selectedAddressId
            const defaultAddress = this.UserAddressList.find(address => address.isdefault === true);
            if (defaultAddress) {
              this.selectedAddressId = defaultAddress.id; // Set the default address as selected
            } else if (this.UserAddressList.length > 0) {
              // If no default address is found, optionally select the first address
              this.selectedAddressId = this.UserAddressList[0].id;
            }
          } else {
            console.error("Expected an array for useraddress_output");
          }
        }
      },
      (error) => {
        console.error("UserAddressList API Error:", error);
      }
    );
 
  }
 
  DeliveryTimesLots() {
    const requestBody = {
      cartItemIds: this.selectedItems,
     
    };
 
    this.http.post(environment.apiUrl + `/deliverytimeslots/list-next-7days-deliveryslot`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
       this.DeliveryTimesLotsList=data.deliverytimeslots
        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }
  selectDay(deliverytimelist: any) {
   
    this.selectedSlots = deliverytimelist.slots || []; // Get the slots for the selected day
    this.selectedSlotId = ''; // Reset the selected slot ID
    this.selectedDay = deliverytimelist;
 
  }
  variationApiCall(productid: any,productvariationid:any) {
    if (this.variationsCache.has(productid)) {
      return of(this.variationsCache.get(productid));
    }
 
    const requestBody = {
      productId: productid,
      productvariationId:productvariationid
    };
   
    return this.http.post(environment.apiUrl + `/products/list-productvariations`, requestBody, { responseType: 'json' }).pipe(
      map((data: any) => {
        if (data.status === 'SUCCESS') {
         
          const filteredVariations = data.productvariations_output.filter((variation: any) => variation.issale === true);
          this.variationsCache.set(productid, filteredVariations);
          this.variationsCacheSubject.next(this.variationsCache);
          return filteredVariations;
        } else {
          return [];
        }
      }),
      catchError(error => {
        console.error("Error in variationApiCall API call:", error);
        return of([]);
      })
    );
  }
 
  updatePrice(event: any, item: any): void {
    const selectedVariationId = event.target.value;
    const selectedVariation = item.variations.find((variation: any) => variation.id === selectedVariationId);
 
    if (selectedVariation) {
        // Update item.selectedVariationId instead of this.selectedVariationId
        item.selectedVariationId = selectedVariation.id;
        item.selectedPrice = selectedVariation.sellingprice;
        item.mrpPrice = selectedVariation.mrpprice;
        this.getMrpPrice();
        this.getSubTotalPrice();
        this.getPriceDifference();
    }
}
getMrpPrice(): number {
  return this.cartItems.reduce((total, item) => {
    const mrpPrice = parseFloat(item.mrpPrice ?? '0');  // Use item.mrpPrice directly
    return total + (mrpPrice * item.quantity);
  }, 0);
}
 
getSubTotalPrice(): number {
  return this.cartItems.reduce((total, item) => {
    const sellingPrice = parseFloat(item.selectedPrice ?? '0');  // Use item.selectedPrice directly
    return total + (sellingPrice * item.quantity);
  }, 0);
}
 
 
 
  getPriceDifference(): number {
   
    const mrpPrice = this.getMrpPrice();
    const subTotalPrice = this.getSubTotalPrice();
 
    return mrpPrice - subTotalPrice;
 
  }
 
  increaseQuantity(item: any): void {
    // Use the selectedVariationId from the item object
    const selectedVariationId = item.selectedVariationId || item.variations?.[0]?.id;
    // Find the selected variation
    const selectedVariation = item.variations.find((variation: any) => variation.id === selectedVariationId);
 
    if (selectedVariation) {
        if (item.quantity > 0) {
            item.quantity = Number(item.quantity) + 1;
            this.Add_to_cart(item.productid, selectedVariation.id, item.quantity);
        }
    }
}
 
decreaseQuantity(item: any): void {
    const selectedVariationId = item.selectedVariationId || item.variations?.[0]?.id;
    const selectedVariation = item.variations.find((variation: any) => variation.id === selectedVariationId);
 
    if (selectedVariation) {
        if (item.quantity > 0) {
            item.quantity = Number(item.quantity) - 1; // Convert to number and add 1
            this.Add_to_cart(item.productid, selectedVariation.id, item.quantity);
        }
    }
}
 
  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity
    };
 
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
       
        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }
 
// allow-substitute
 
 allow_substitute(AllowSubstitute:boolean,CartIds: any[]){
  const requestBody = {
    allowSubstitute: AllowSubstitute,
    cartitemIds: CartIds,
  };
 
  this.http.post(environment.apiUrl + `/cart/update-allow-substitute`, requestBody, { responseType: 'json' }).subscribe(
    (data: any) => {
      if (data.status === 'SUCCESS') {
      }
    },
    (error: any) => {
      // Handle the error (e.g., show an error message)
      console.error('An error occurred:', error);
    }
  );
 }
 
 onSubstituteChange(event: any, item: any): void {
  const isChecked = event.target.checked;
  const cartId = item.id;
 
  this.allow_substitute(isChecked, [cartId]);
}
 
onMasterCheckboxChange(event: any): void {
  const isChecked = event.target.checked;
  this.cartItems.forEach(item => {
    item.allowsubstitute = isChecked;
  });
  const cartIds = this.cartItems.map(item => item.id);
  this.allow_substitute(isChecked, cartIds);
}
 
// delete cart-items
deleteCartItem(cartId: any): void {
  const requestBody = {
    updatedBy: this.userId,  
    cartitemIds: [cartId],  
  };
 
  this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
    (data: any) => {
      if (data.status === 'SUCCESS') {
        this.cartItems = this.cartItems.filter(item => !cartId.includes(item.id));
        this.count = this.cartItems.length;
        this.cartService.updateCartCount(this.userId);
      }
    },
    (error: any) => {
      console.error('An error occurred:', error);
    }
  );
}
 
 
clearAllItems() {
  // Extract all cart IDs from cartItems
  const cartIds = this.cartItems.map(item => item.id);
 
  if (cartIds.length > 0) {
    // Call the delete function with all cart IDs
    this.deleteCartItem(cartIds);
  }
}
}
 
 