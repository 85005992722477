import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';

@Component({
  selector: 'app-add-cart',
  templateUrl: './add-cart.component.html',
  styleUrls: ['./add-cart.component.css']
})
export class AddCartComponent {
  cartPage = true;
  productPage = true;
  orderPlaced = false;
  deliverAddress = false;
  deliverySchedule = false;
  paymentProcess = false;
  orderEmpty = false;
  paymentActive: boolean = true;
  selectedPaymentMethod: string | null = null;
  cartItems: any[] = [];
  variations: any[] = [];
  count: number | null = null;
  isplaceOrderEnabled: boolean = false;
  currentStep: number = 0;
  tittle: string = 'cart';
  accessToken: string | null = null;
  userId: string = '';
  selectedVariationId:string | null = null;
  private variationsCache = new Map<string, any[]>();
  private variationsCacheSubject = new BehaviorSubject<Map<string, any[]>>(this.variationsCache);

  constructor(public router: Router, private route: ActivatedRoute, private http: HttpClient, public auth: AuthService,private cartService: CartService) {
    this.auth.userInfo.subscribe((data: any) => {
      this.userId = data.id;
      this.cartList();
    });
  }
  // 1st page next btn
  productPage_next() {
    this.currentStep = 1;
    this.deliverAddress = true;
    this.productPage = false;
  }
  // 2nd page next btn
  deliverAddress_next() {
    this.currentStep = 2;
    this.deliverySchedule = true
    this.deliverAddress = false;
  }
  // 3rd page next btn
  deliverySchedule_next() {
    this.currentStep = 3;
    this.deliverySchedule = false;
    this.paymentProcess = true;
    this.isplaceOrderEnabled = true
  }

  // 4th page next btn
  placeOrder() {
    this.currentStep = 4;
    if (this.isplaceOrderEnabled) {
      this.orderPlaced = true;
      this.cartPage = false;
    }
  }

  detectLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
      }, (error) => {
        console.error(error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  setActiveTittle(tittle: string): void {
    this.tittle = tittle;
  }

  // cart-list
  cartList() {
    const requestBody = { userId: this.userId };

    this.http.post(environment.apiUrl + `/cart/list`, requestBody, { responseType: 'json' }).pipe(
        switchMap((data: any) => {
            if (data.status === 'SUCCESS') {
                this.cartItems = data.cart_output;
                this.count = data.count;

                const imageRequests = this.cartItems.map((item: any) => {
                    return this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`).pipe(
                        switchMap((datas: any) => {
                            if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                                return this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`);
                            } else {
                                return of({ status: 'ERROR' });
                            }
                        }),
                        map((res: any) => {
                            if (res.status === "SUCCESS") {
                                item.src = [res.uploads.base64data];
                            } else {
                                item.src = [];
                            }
                            return item;
                        })
                    );
                });

                // Use forkJoin to wait for all image requests to complete
                return forkJoin(imageRequests).pipe(
                    switchMap((itemsWithImages: any[]) => {
                        // Get variations for all items
                        const variationRequests = itemsWithImages.map((item: any) => {
                            return this.variationApiCall(item.productid,item.productvariationid).pipe(
                                map((filteredVariations: any) => {
                                    item.variations = filteredVariations;
                                    
                                    const selectedVariation = item.variations.find((v: any) => v.id === item.productvariationid);
                                    if (selectedVariation) {
                                      item.selectedVariationId = selectedVariation.id;
                                      item.selectedPrice = selectedVariation.sellingprice;
                                      item.mrpPrice = selectedVariation.mrpprice;
                                    }
                                    
                                    return item;
                                })
                            );
                        });

                        return forkJoin(variationRequests);
                    })
                );
            } else {
                return of([]);
            }
        }),
        catchError(error => {
            console.error("Error in cartList API call:", error);
            return of([]);
        })
    ).subscribe(
        (updatedItems: any[]) => {
            this.cartItems = updatedItems;
            this.getMrpPrice();
            this.getSubTotalPrice();
        }
    );
}


  variationApiCall(productid: any,productvariationid:any) {
    if (this.variationsCache.has(productid)) {
      return of(this.variationsCache.get(productid));
    }

    const requestBody = { 
      productId: productid,
      productvariationId:productvariationid 
    };
    
    return this.http.post(environment.apiUrl + `/products/list-productvariations`, requestBody, { responseType: 'json' }).pipe(
      map((data: any) => {
        if (data.status === 'SUCCESS') {
          
          const filteredVariations = data.productvariations_output.filter((variation: any) => variation.issale === true);
          this.variationsCache.set(productid, filteredVariations);
          this.variationsCacheSubject.next(this.variationsCache);
          return filteredVariations;
        } else {
          return [];
        }
      }),
      catchError(error => {
        console.error("Error in variationApiCall API call:", error);
        return of([]);
      })
    );
  }

  updatePrice(event: any, item: any): void {
    const selectedVariationId = event.target.value;
    const selectedVariation = item.variations.find((variation: any) => variation.id === selectedVariationId);

    if (selectedVariation) {
        // Update item.selectedVariationId instead of this.selectedVariationId
        item.selectedVariationId = selectedVariation.id;
        item.selectedPrice = selectedVariation.sellingprice;
        item.mrpPrice = selectedVariation.mrpprice;
        this.getMrpPrice();
        this.getSubTotalPrice();
        this.getPriceDifference();
    }
}
getMrpPrice(): number {
  return this.cartItems.reduce((total, item) => {
    const mrpPrice = parseFloat(item.mrpPrice ?? '0');  // Use item.mrpPrice directly
    return total + (mrpPrice * item.quantity);
  }, 0);
}

getSubTotalPrice(): number {
  return this.cartItems.reduce((total, item) => {
    const sellingPrice = parseFloat(item.selectedPrice ?? '0');  // Use item.selectedPrice directly
    return total + (sellingPrice * item.quantity);
  }, 0);
}


  
  getPriceDifference(): number {
    
    const mrpPrice = this.getMrpPrice();
    const subTotalPrice = this.getSubTotalPrice();

    return mrpPrice - subTotalPrice;

  }

  increaseQuantity(item: any): void {
    // Use the selectedVariationId from the item object
    const selectedVariationId = item.selectedVariationId || item.variations?.[0]?.id;
    // Find the selected variation
    const selectedVariation = item.variations.find((variation: any) => variation.id === selectedVariationId);

    if (selectedVariation) {
        if (item.quantity > 0) {
            item.quantity = Number(item.quantity) + 1; 
            this.Add_to_cart(item.productid, selectedVariation.id, item.quantity);
        }
    } 
}

decreaseQuantity(item: any): void {
    const selectedVariationId = item.selectedVariationId || item.variations?.[0]?.id;
    const selectedVariation = item.variations.find((variation: any) => variation.id === selectedVariationId);

    if (selectedVariation) {
        if (item.quantity > 0) {
            item.quantity = Number(item.quantity) - 1; // Convert to number and add 1
            this.Add_to_cart(item.productid, selectedVariation.id, item.quantity);
        }
    } 
}
  
  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity
    };
 
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
        
        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }
 
// allow-substitute

 allow_substitute(AllowSubstitute:boolean,CartIds: any[]){
  const requestBody = {
    allowSubstitute: AllowSubstitute,
    cartIds: CartIds,
  };

  this.http.post(environment.apiUrl + `/cart/update-allow-substitute`, requestBody, { responseType: 'json' }).subscribe(
    (data: any) => {
      if (data.status === 'SUCCESS') {
      } 
    },
    (error: any) => {
      // Handle the error (e.g., show an error message)
      console.error('An error occurred:', error);
    }
  );
 }

 onSubstituteChange(event: any, item: any): void {
  const isChecked = event.target.checked;
  const cartId = item.id; 

  this.allow_substitute(isChecked, [cartId]);
}

onMasterCheckboxChange(event: any): void {
  const isChecked = event.target.checked;
  this.cartItems.forEach(item => {
    item.allowsubstitute = isChecked;
  });
  const cartIds = this.cartItems.map(item => item.id);
  this.allow_substitute(isChecked, cartIds);
}

// delete cart-items
deleteCartItem(cartId: any): void {
  const requestBody = {
    updatedBy: this.userId,  
    cartIds: [cartId],  
  };

  this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
    (data: any) => {
      if (data.status === 'SUCCESS') {
        this.cartItems = this.cartItems.filter(item => !cartId.includes(item.id));
        this.count = this.cartItems.length;
        this.cartService.updateCartCount(this.userId);
      } 
    },
    (error: any) => {
      console.error('An error occurred:', error);
    }
  );
}


clearAllItems() {
  // Extract all cart IDs from cartItems
  const cartIds = this.cartItems.map(item => item.id);

  if (cartIds.length > 0) {
    // Call the delete function with all cart IDs
    this.deleteCartItem(cartIds);
  }
}
}
