<div class="container fixed-margin-top">
  <div class="m-2 product-list-card">
      <div class="row ">
          <div *ngFor="let product of productList" class="col-md-3 d-lg-block d-none mb-4 mt-5">
              <div class="card me-md-2 me-1" style="cursor: pointer;">
                  <div class="d-flex">
                      <!-- <div class="offer">{{ product.offer }}</div> -->
                      <div class="offer">Save Upto 20%</div>
 
                      <div class="hover-zoom" (click)="productList_page(product.id,product.selectedVariation)">
                          <img [src]="product.src?.[0]" class="card-img d-block" alt="product">
                      </div>
                      <div class="like-border me-5 d-none d-sm-block">
                        <!-- If product is in the wishlist (heart icon) -->
                        <span *ngIf="product.wishlistIcon; else noWishListIcon" (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                          <img src="../../assets/images/heart-icon.svg" class="like" alt="heart-icon">
                        </span>
                        
                        <!-- If product is not in the wishlist (border heart icon) -->
                        <ng-template #noWishListIcon>
                          <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                            <img src="../../assets/images/heart-border-web.png" class="like" alt="like">
                          </span>
                        </ng-template>
                        
                        <img src="../../assets/images/view-border.svg" class="view" alt="view"
                             (click)="openProductDialog(product, product.selectedVariation)">
                      </div>
                      <!-- for mobile -->
                      <div class="mobile-like mt-2 d-sm-none d-block">
                     
                        <span *ngIf="product.wishlistIcon; else noWishListIcon" (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                          <img src="../../assets/images/heart-icon.svg" class="heartlike" alt="like">
                        </span>
                        
                        <!-- If product is not in the wishlist (border heart icon) -->
                        <ng-template #noWishListIcon>
                          <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                            <img src="../../assets/images/heartborder.svg" class="heartlike" alt="like">
                          </span>
                        </ng-template>
                    </div>
                  </div>
 
                  <div class="card-body px-2">
                    <h6 class="card-title mb-md-2 mb-2 mt-2 text text-truncate">{{ product.title }}</h6>
                    <div class="card-text mt-2">
                      <button *ngFor="let variation of product.variations"   (click)="selectSize(product.id, variation)"
                      class="btn size-btn me-1" [ngClass]="{'selected-btn': variation.priceToShow === product.price}">                                  {{ variation.variationvalue }} {{ variation.variationshortname }}
                    </button>
                    </div>
                    <div class="text-center d-md-flex justify-content-md-between align-items-md-center  mb-2 mx-2 mt-2">
                        <div class="mt-sm-0">
                          <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                          <span class="amount2 m-0"><del>{{ product.originalPrice | currency:'GBP' }}</del></span>
                        </div>
                        <div class="add-btn text-center m-1">
                            <a class="btn mt-1">
                                <img src="../../assets/images/cart.svg" alt="cart"> Subscribe
                            </a>
                        </div>
                    </div>
                </div>
 
 
 
 
 
 
                  <!-- <div class="card-body px-sm-2">
                      <h6 class="card-title mb-md-2 mb-2 text-truncate">{{ product.title }}</h6>
                      <div class="card-text d-flex mb-2">
                          <p class="m-1 " >{{product.variationvalue}}</p>
                      </div>
                      <div class="text-center d-md-flex justify-content-md-between mt-1 mt-md-0 mb-2 mx-2">
                          <div class="mt-md-0 ">
                              <p class="amount mb-0">{{ product.price | currency:'GBP' }}</p>
                              <span class="amount2 m-0"><del>{{ product.originalPrice  | currency:'GBP' }}</del></span>
                          </div>
                          <div class="add-btn text-center m-0">
                              <a class="btn mt-1"><img src="../../assets/images/cart.svg" alt="cart"> Add to Cart</a>
                          </div>
                      </div>
                  </div> -->
              </div>
          </div>
          <!-- for mobile -->
          <div *ngFor="let product of productList" class="col-12 d-lg-none d-block">
              <div class=""  style="cursor: pointer;">
                <div class="row">
                  <!-- <div class="offer mx-1 px-1">{{ product.offer }}</div> -->
                  <!-- <div class="offer mx-1 px-1">50% off</div> -->
 
                  <div class="col-4" >
                    <img [src]="product.src?.[0]" class="card-img mx-1" alt="product" (click)="productList_page(product.id,product.selectedVariation)">
                  </div>
                    <!-- <div class="like-border me-md-3 me-0 d-none d-sm-block">
                    <img src="../../assets/images/like.svg" class="like" alt="like">
                    <img src="../../assets/images/view.svg" class="view" alt="view" (click)="openProductDialog(product)">
                  </div> -->
                  <div class="col-8">
                    <div class="d-flex align-items-center justify-content-between mt-3">
                  <h6 class="card-title mb-md-2 mb-0 mt-4 mb-3">{{ product.title }}</h6>
                  <div class="mobile-like d-sm-none d-block col-4">
                    <span *ngIf="product.wishlistIcon; else noWishListIcon" (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                      <img src="../../assets/images/Wishlist-like.svg" class="heart-like" alt="heart-icon">
                    </span>
                   
                    <!-- If product is not in the wishlist (border heart icon) -->
                    <ng-template #noWishListIcon>
                      <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                        <img src="../../assets/images/like.svg" class="like" alt="like">
                      </span>
                    </ng-template>
                  </div>
                  </div>
                  <div class="offer mt-0 mx-1 px-1">50% off</div>
                  <div class="card-body px-sm-2">
 
                    <div class="card-text d-flex mb-2 mt-2">
                      <button *ngFor="let variation of product.variations"   (click)="selectSize(product.id, variation)"
                      class="btn size-btn me-2" [ngClass]="{'selected-btn': variation.priceToShow === product.price}">                                  {{ variation.variationvalue }} {{ variation.variationshortname }}
                    </button>
                    </div>
                    <div class="text-center d-flex justify-content-between align-items-center mt-1 mt-md-0">
                      <div class="mt-md-0 d-flex">
                        <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                        <span class="amount2"><del>{{ product.originalPrice | currency:'GBP' }}</del></span>
                      </div>
                      <div class="add-btn text-center m-1">
                        <a class="btn mt-1 p-0"><img src="../../assets/images/cart.svg" alt="cart" class="mx-1">Subscribe</a>
                      </div>
                    </div>
                  </div>
              </div>
                 
                <!-- </div> -->
 
                </div>
                <!-- <div class="card-body px-sm-2">
 
                  <div class="card-text d-flex mb-2">
                    <p class="mx-1" >{{product.variationvalue}}</p>
                  </div>
                  <div class="text-center d-md-flex justify-content-md-between mt-1 mt-md-0 mx-2">
                    <div class="mt-md-0 ">
                      <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                      <span class="amount2 m-0"><del>{{ product.originalPrice | currency:'GBP' }}</del></span>
                    </div>
                    <div class="add-btn text-center m-1">
                      <a class="btn mt-1"><img src="../../assets/images/cart.svg" alt="cart"> Add to Cart</a>
                    </div>
                  </div>
                </div> -->
                <hr>
              </div>
            </div>
      </div>
 
      <div class="row">
          <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center">
                  <li class="page-item disabled">
                      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                  </li>
                  <li class="page-item active"><a class="page-link" href="#">1</a></li>
                  <li class="page-item " aria-current="page">
                      <a class="page-link" href="#">2</a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                      <a class="page-link" href="#">Next</a>
                  </li>
              </ul>
          </nav>
      </div>
  </div>
</div>
 
 