import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { ProductPopupDialogComponent } from '../product-popup-dialog/product-popup-dialog.component';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-best-selling',
  templateUrl: './best-selling.component.html',
  styleUrls: ['./best-selling.component.css']
})
export class BestSellingComponent implements AfterViewInit {
  responsiveOptions: any[] | undefined;
  productList: any[] = [];
  categoryList: any[] = []; // List of categories
  productIds: string[] = []; // Property to store category IDs
  count: number = 4; // Default number of products to show
  page: number = 1;
  categoryListIds: string[] = []
  categoryname: string | undefined;
  numVisible: number = 4; // default visible items
  mrpPrice: number | undefined;
  priceToShow: number | undefined;
  variationDisplay: string | undefined;
  selectedVariation: any;
  userId: string | null = null;
  add_cart: boolean = true;
  loadMoreRecentlyViewed: boolean = false;
  isLoadingRecentlyViewed: boolean = false;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
  downArrow: boolean = false;
  upArrow: boolean = false;
  @Input() data: any;
  wishlistIcon = false;
  isLoggedIn: boolean = false;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  @ViewChild('elemt', { static: false }) parentElement!: ElementRef;
  constructor(public router: Router, private http: HttpClient, private productService: ProductService, private dialog: MatDialog, public auth: AuthService, private cartService: CartService) {

    this.auth.userInfo.subscribe((data: any) => {
      if (data) {
        this.userId = data?.id;
        this.isLoggedIn = !!data;
      }
    });

  }
  ngAfterViewInit(): void {

  }

  ngOnInit() {

    if (Array.isArray(this.data)) {
      this.data.forEach(item => {
        this.categoryListCall(item.categorykey);
      });
    } else {
      this.categoryListCall(this.data.categorykey);
      this.categoryname = this.data.name;
    }



    this.updateVisibleItems();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItems();
  }
  openProductDialog(product: any, selectedVariationId: string): void {
    const bestSelling = "bestSelling";

    const variationId = this.selectedVariation || selectedVariationId;

    this.dialog.open(ProductPopupDialogComponent, {
      data: {
        id: product.id,
        datafrom: bestSelling,
        variationId: variationId
      },
      width: '90%',
      height: '90%'
    });
    this.selectedVariation = ''
  }

  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    } else {
      // this.startX = e.touches[0].pageX - el.offsetLeft;
      // this.startY = e.touches[0].pageY - el.offsetTop;
    }



    this.scrollLeft = el.scrollLeft;
    // setTimeout(() => { this.isDragging = true }, 300);
  }

  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    // setTimeout(() => { this.isDragging = false });
    this.mouseDown = false;
  }

  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    // e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    } else {
      // x = e.touches[0].pageX - el.offsetLeft;
      // y = e.touches[0].pageY - el.offsetTop;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }


  onScroll(e: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, child: any) {
    if ((child.clientWidth - el.scrollLeft) <= el.clientWidth + 1) {
      // this.loadMoreProducts();

      // if (this.showMoreReview && !this.reviewLoading) {
      //   this.reviewLoading = true;
      //   this.loadmore();
      // }
    }
  }







  updateVisibleItems() {
    const width = window.innerWidth;
    if (width > 1199) {
      this.numVisible = 4;
    } else if (width > 991) {
      this.numVisible = 3;
    } else if (width > 767) {
      this.numVisible = 2;
    } else {
      this.numVisible = 1;
    }
  }

  get showPrevArrow(): boolean {
    return this.productList.length > this.numVisible;
  }

  get showNextArrow(): boolean {
    return this.productList.length > this.numVisible;
  }
  viewAll() {
    this.router.navigate(['/product-list-pages'], { queryParams: { category: this.data.categorykey } });
  }

  categoryListCall(categoryKey: string): void {
    const requestBody = {
      "status": 1,
      "categoryKey": categoryKey
    };

    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.categoryList = data.categories_output;
          this.productIds = []; // Initialize productIds here

          // Extract and store category IDs
          this.categoryList.forEach((category: any) => {
            const categoryList_id = category.id;
            this.productIds.push(categoryList_id); // Store IDs in the property
          });
          if (!this.isLoadingRecentlyViewed) {
            this.ProductListCall(this.productIds, this.count, "scroll");

          }
          // Call ProductListCall with the IDs
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
  selectSize(id: any, variation: any): void {
    let productIndex = this.productList.findIndex((x: any) => x.id == id);

    if (productIndex != -1) {
      this.selectedVariation = variation.productvariationid;
      this.productList[productIndex].selectedVariation = variation.productvariationid;
      this.productList[productIndex].price = variation.priceToShow;
      this.productList[productIndex].originalPrice = variation.originalPrice;
    }

  }

  loadMoreProducts(productIds: string[], datafrom: any): void {

    if (!this.isLoadingRecentlyViewed && this.loadMoreRecentlyViewed) {

      this.ProductListCall(productIds, this.count, datafrom);
    }
  }
  // onScrollOrClickArrow(): void {
  //   // this.page += 1;
  //   // this.count =4;
  //   this.loadMoreProducts(this.productIds, "arrow");

  // }
  onScrollOrClick() {
    // this.page += 1;
    // this.count =4;
    this.loadMoreProducts(this.productIds, "scroll");
    

  }
  ProductListCall(productIds: string[], count: number, dataFrom: any): void {
    this.isLoadingRecentlyViewed = true;

    const requestBody: any = {
      status: 1,
      categoryIds: productIds,
      isSale: true,
      page: this.page,
      count: count
    };
    if (this.userId) {
      requestBody.userId = this.userId;
    }

    this.http.post(environment.apiUrl + `/products/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          data.products_output.forEach((product: any) => {
            if (product.productvariations && product.productvariations.length > 0) {
              product.variations = [];
              product.productvariations.forEach((variation: any) => {
                if (product.productvariations[0]) {
                  // Set the first variation as default
                  product.originalPrice = product.productvariations[0].mrpprice;
                  product.variationvalue = product.productvariations[0].variationvalue;
                  product.price = product.productvariations[0].sellingprice;
                  product.originalPrice = product.productvariations[0].mrpprice;
                  product.variationvalue = product.productvariations[0].variationvalue;
                  product.variationshortname = product.productvariations[0].variationshortname;
                  product.selectedVariation = product.productvariations[0].productvariationid
                  product.cartquantity = product.productvariations[0].cartquantity
                  product.cartid = product.productvariations[0].cartid
                  product.variations.push({
                    originalPrice: variation.mrpprice,
                    priceToShow: variation.sellingprice,
                    variationvalue: variation.variationvalue,
                    variationshortname: variation.variationshortname,
                    productvariationid: variation.productvariationid
                  });

                }
              });
            }
            //  this.wishlistIcon = this.isInWishlist || false;
          });

          data.products_output.forEach((x: any) => {
            x.src = [];
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${x.id}`).subscribe((datas: any) => {
              if (datas.status === "SUCCESS") {
                if (datas.uploads.length > 0) {
                  this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      x.src.push(res.uploads.base64data);
                    }
                  });
                }
              }
            });
          });

          if (dataFrom === "arrow" && this.productList.length > 0) {
            this.upArrow = true; // Show "See Less" after clicking "See More"
          }
          const filteredActivities = data.products_output.filter((activity: any) => {
            return this.productList != activity.id;
          });

          this.productList = [...this.productList, ...data.products_output];

          if (data.count > this.productList.length) {
            this.loadMoreRecentlyViewed = true;
            this.page += 1;
            this.upArrow = true; // Hide both arrows if no new products
            this.downArrow = true;
          }
          else {
            this.loadMoreRecentlyViewed = false;
            this.downArrow = false;
            this.upArrow = false;

          }
          this.isLoadingRecentlyViewed = false;
          if(this.userId){
            this.wish_list();

          }


        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }

  scrollToRight(): void {

    if (this.parentElement) {
      this.parentElement.nativeElement.scrollLeft += 200; // Adjust this value as needed
    }
  }

  scrollToBottom(): void {
    if (this.parentElement) {
      this.parentElement.nativeElement.scrollTop += 200; // Use this for vertical scrolling
    }
  }

  productList_page(productId: string, selectedVariation: string): void {
    const variationId = this.selectedVariation || selectedVariation;
    const bestSelling = "bestSelling"
    this.router.navigate(['/product-pages', productId], {
      state: { dataToPass: { datafrom: bestSelling, variationId: variationId } }
    });
  }

  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    if (!this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity
    };

    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const product = this.productList.find(item => item.id === productId);
          if (product) {
            product.cartquantity = quantity;
          }

          this.addedToCart.set(productId, true);
          this.cartService.updateCartCount(data.newCartCount);

        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }

  getQuantity(productId: number): number {

    return this.quantities.get(productId) || 1;

  }
  increaseQuantity(productId: number, productVariationId: string) {
    const product = this.productList.find(item => item.id === productId);
    if (product) {
      product.cartquantity += 1;
      this.Add_to_cart(productId, productVariationId, product.cartquantity);
    }
  }

  decreaseQuantity(productId: number, productVariationId: string, cartId: any) {
    const product = this.productList.find(item => item.id === productId);

    if (product) {
      if (product.cartquantity > 1) {
        // Decrease quantity and update the cart
        product.cartquantity -= 1;
        this.Add_to_cart(productId, productVariationId, product.cartquantity);
      } else if (product.cartquantity === 1) {
        // If the quantity is 1, set it to 0 and call delete function
        product.cartquantity = 0;
        this.deleteCartItem(cartId);  // Call delete function
      }
    }
  }

  deleteCartItem(cartId: any): void {
    const requestBody = {
      updatedBy: this.userId,
      cartIds: [cartId],
    };

    this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          if (this.userId !== null && this.userId !== undefined) {
            this.cartService.updateCartCount(this.userId);
          } else {
            console.error('User ID is null or undefined. Cannot update cart count.');
          }

        }
      },
      (error: any) => {
        console.error('An error occurred:', error);
      }
    );
  }


  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
    event.stopPropagation(); // Prevent triggering parent events
  
    const newStatus = !product.wishlistIcon;

    product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
    if (this.userId) {
      const requestBody = {
        status: newStatus ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId
      };
  
      this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
        .subscribe(
          (data: any) => {
            // Handle the success response here if needed
          },
          (error) => {
            console.error("API Error:", error);
          }
        );
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigate(['/login']);
    }
  }
  
  wish_list(): void {
    const requestBody = {
      userId: this.userId,
    };
  
    this.http.post(environment.apiUrl + `/wishlistitems/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            const wish_list_product = data.wishlistitems_output;
  
            // Loop through productList and check if product is in the wishlist
            this.productList.forEach((product: any) => {
              const isInWishlist = wish_list_product.some((item: any) => item.productid === product.id);
              product.wishlistIcon = isInWishlist; // Set wishlist icon to true if in wishlist
              
            });
          }
        },
        (error) => {
          console.error("Wishlist API Error:", error);
        }
      );
  }
  


 
}


