import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-gpstore-banner',
  templateUrl: './gpstore-banner.component.html',
  styleUrls: ['./gpstore-banner.component.css']
})
export class GpstoreBannerComponent {
  constructor(public dialogRef: MatDialogRef<GpstoreBannerComponent>) { }
  closeDialog(): void {
    this.dialogRef.close();
  }
}
