import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input'
import { BehaviorSubject, catchError, forkJoin, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { ProductService } from '../services/product.service';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

declare const google: any;

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css']
})

export class ProfilePageComponent implements OnInit {
  activeSection: string = 'profile';
  userId: string | null = null;
  wishlistitems_output: any[] = [];
  user_details: any = {};
  gender: string | null = null;
  src: any;
  price: any;
  count: any;
  productList: any[] = [];
  cartItems: any[] = [];
  wishList: any[] = [];
  profileImageUrl: string = '';
  myGroup: FormGroup;
  priceToShow!: number;
  newMobileInput: boolean = false;
  NewMobileInputOpen: boolean = false;
  NewEmailInputOpen: boolean = false;
  verifyEmailBtn: boolean = false;
  NewMobileSavebtn: boolean = false;
  isNameEditing: boolean = false;
  isGenderEditing: boolean = false;
  isPhoneEditing: boolean = false;
  isEmailEditable: boolean = false;
  isUserDetailEmail: boolean = false;
  showsubscriptionPopup = false;
  showCardDeletePopup = false;
  isEditMode: boolean = false;
  passwordInput: string = '';
  isVerifyDisabled: boolean = true;
  latitude: number | undefined | null;
  longitude: number | undefined | null;
  isLoggedIn: boolean = false;
  isVerifyPasswordDisabled: boolean = true;
  showSignoutPopup = false;
  showOtpVerifyPopup = false;
  showChangePasswordOtpVerifyPopup: boolean = false;
  showSubscribePaymentDetails: boolean = false;
  selectedPaymentMethod: boolean = false;
  UserAddressList: any[] = [];
  selectedAddressId: string = '';
  showPhoneOtpVerifyPopup = false;
  showOrderDetails: boolean = false;
  showSubscribeDetails: boolean = false;
  showPauseSubscribeDetails: boolean = false;
  showCardPaymentContent = false;
  showCardAddressContent = false;
  showNewAddress = false;
  isMapVisible: boolean = true;
  isPasswordEditable: boolean = false;
  oldemail: string = 'example@email.com';
  currentPassword: string = '********';
  newPassword: string = '';
  showPasswordVerifyPopup = false;
  mobileNum: string | null = null;
  addedToCart = new Map<number, boolean>();
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: any;  // Depending on the format from ngx-intl-tel-input
  selectedCountryISO: CountryISO = CountryISO.India; // Default value
  deliveryArea: string = '';
  address: string = '';
  doorNo: string = '';
  landmark: string = '';
  postalCode: string = '';
  selectedAddressType: string = 'Home';
  selectedPaymentDetailsMethod: boolean = false;
  showDeletePopup: boolean = false;  // Track whether the popup is shown
  addressToDelete: any = null;
  isEmailEditing: boolean = false;
  showEmailOtpVerifyPopup: boolean = false;
  isOldEmail: boolean = true;
  otpMessage: string = '';
  otpCode: string = '';
  phoneToVerify: string = '';
  isOldPhone: boolean = true;
  isChangePassword: boolean = false;
  // map
  map!: google.maps.Map;
  selectedAddress: string = '';
  private variationsCache = new Map<string, any[]>();
  private variationsCacheSubject = new BehaviorSubject<Map<string, any[]>>(this.variationsCache);
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  @ViewChild('fileInput') fileInput: ElementRef | undefined;

  constructor(private route: ActivatedRoute, private router: Router, private elementRef: ElementRef, private productService: ProductService, public auth: AuthService, private http: HttpClient, private cartService: CartService, private fb: FormBuilder, private renderer: Renderer2, private snackBar: MatSnackBar) {
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {

      if (data) {

        this.userId = data?.id;
        this.isLoggedIn = !!data;
        this.cartList();
        this.getUserDetails();
      } else {
        this.isLoggedIn = false;
      }
    });

    this.myGroup = this.fb.group({
      firstname: [{ value: this.user_details.firstname, disabled: true }],
      lastname: [{ value: this.user_details.lastname, disabled: true }],
      gender: [{ value: this.user_details.gender, disabled: true }],
      mobileInput: [{ value: this.user_details.phone, disabled: true }],
      newEmailInput: [{ value: '', disabled: false }],
      currentPassword: [{ value: '', disabled: true }],
      newPassword: [{ value: '', disabled: false }],
      otpCode: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
      oldEmail: [{ value: this.user_details.email, disabled: true }],
      newEmail: ['', [Validators.required, Validators.email]],
      newMobileInput: ['', [ // Add an initial value (empty string)
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(15),
        Validators.pattern(/^\+?\d{10,15}$/) // Regex for 10-15 digit phone numbers
      ]],
    });


  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['section']) {
        this.activeSection = params['section'];
        if (this.activeSection == 'address') {
          this.UserAddressListApi();
        }
      }
      const element = this.elementRef.nativeElement.querySelector('.profile-container');
      if (element) {
        window.scroll(0, 0);
      }

    });
    this.whish_list();
  }

  toggleNameEdit() {
    this.isNameEditing = !this.isNameEditing;
    if (this.isNameEditing) {
      this.myGroup.get('firstname')?.enable();
      this.myGroup.get('lastname')?.enable();
    } else {
      this.myGroup.get('firstname')?.disable();
      this.myGroup.get('lastname')?.disable();
    }
  }

  toggleGenderEdit() {
    this.isGenderEditing = !this.isGenderEditing;
    if (this.isGenderEditing) {
      this.myGroup.get('gender')?.enable();
    } else {
      this.myGroup.get('gender')?.disable();
    }
  }

  whish_list() {
    const requestBody = {
      userId: this.userId,
    }
    this.http.post(environment.apiUrl + `/wishlistitems/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.count = data.count

          this.wishList = data.wishlistitems_output
          const element = this.elementRef.nativeElement.querySelector('.profile-container');
          if (element) {
            window.scroll(0, 0);
          }

          const imageRequests = this.wishList.map((item: any) =>
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`).pipe(
              switchMap((datas: any) => {

                if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                  return this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`);
                } else {
                  return of({ status: 'ERROR' });
                }
              }),
              map((res: any) => {
                if (res.status === "SUCCESS") {
                  item.src = [res.uploads.base64data];
                } else {
                  item.src = [];
                }
                return item;
              })
            )
          );
          forkJoin(imageRequests).subscribe((results) => {
            this.wishList = results;
          });

          data.wishlistitems_output.forEach((item: any) => {
            this.getPriceToShow(item);
            item.src = [];
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.id}`).subscribe((datas: any) => {
              if (datas.status === "SUCCESS") {
                if (datas.uploads.length > 0) {
                  this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      item.src.push(res.uploads.base64data);
                    }
                  });
                }
              }
            });
          })

          this.wishlistitems_output = data.wishlistitems_output;
        }
      })
  }

  getUserDetails() {
    this.http.get(environment.apiUrl + `/users/get?id=${this.userId}`).subscribe((res: any) => {
      if (res.status === "SUCCESS") {
        this.user_details = res.users;
        this.gender = this.user_details.gender;
        if (this.user_details.email == null) {
          this.isUserDetailEmail = false
        } else {
          this.isUserDetailEmail = true
        }
        if (this.user_details.gender) {
          this.gender = this.user_details.gender.charAt(0).toUpperCase() + this.user_details.gender.slice(1).toLowerCase();
        } else {
          this.gender = null;
        }
       

        if (this.user_details.phone) {
          this.mobileNum = this.user_details.phone.replace(/^\+91/, '');
          this.isPhoneEditing = true
        } else {

          this.mobileNum = null;
          this.isPhoneEditing = false
        }
        const MobileInputControl = this.myGroup.get('mobileInput');
        if (MobileInputControl) {
          if (this.isPhoneEditing) {
            MobileInputControl.disable(); // Enable if editing
          } else if(this.NewMobileSavebtn){
            MobileInputControl.enable(); // Disable if not editing
          }
          else {
            MobileInputControl.disable();
          }
        }
        const oldEmailInputControl = this.myGroup.get('oldEmail');

        if (oldEmailInputControl) {
          if (this.isUserDetailEmail) {
            oldEmailInputControl.disable(); // Enable if editing
          } else if(this.verifyEmailBtn){
            oldEmailInputControl.enable(); // Disable if not editing
          }
          else {
            oldEmailInputControl.disable();
          }
          
        }
        this.myGroup.patchValue({
          firstname: this.user_details.firstname,
          lastname: this.user_details.lastname,
          gender: this.gender,
          mobileInput: this.mobileNum,
          oldEmail: this.user_details.email
        });

        try {
          // Check if imageinfo is a valid string or object, else fallback to empty array
          this.user_details.imageinfo = typeof this.user_details.imageinfo === 'string'
            ? JSON.parse(this.user_details.imageinfo)
            : this.user_details.imageinfo || []; // Fallback to empty array


          if (Array.isArray(this.user_details.imageinfo) && this.user_details.imageinfo.length > 0) {
            const firstImage = this.user_details.id; // Corrected access

            // Set default profile image first
            this.profileImageUrl = "../../assets/images/profile-man.svg";
            // Fetch image data
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=users&dataForId=${firstImage}`).subscribe((datas: any) => {

              if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                const uploadId = datas.uploads[0].id;

                this.http.get(environment.apiUrl + `/uploads/get?id=${uploadId}`).subscribe((res: any) => {
                  if (res.status === "SUCCESS") {

                    // Check if base64 data is present and assign to profileImageUrl
                    // Check if base64 data is present
                    if (res.uploads && res.uploads.base64data) {
                      // Remove any extra prefix
                      const base64Data = res.uploads.base64data.trim();
                      const prefix = 'data:image/jpeg;base64,';
                      // Ensure the prefix is only added once
                      this.profileImageUrl = base64Data.startsWith(prefix)
                        ? base64Data
                        : prefix + base64Data;

                    } else {
                      console.error('No base64 data found in the image response.');
                    }
                  }
                });
              }
            });
          } else {
            // Handle empty or invalid imageinfo
            console.warn('No images found, using default profile image.');
            this.profileImageUrl = "../../assets/images/profile-man.svg"; // Default profile image
          }
        } catch (e) {
          console.error('Failed to parse imageinfo:', e);
          this.profileImageUrl = "../../assets/images/profile-man.svg"; // Fallback to default
        }
      }
    });
  }
  GoBack() {
    console.log(this.showNewAddress, this.isMapVisible, "kkl");

    if (this.showNewAddress) {
      // If the address form is open, go back to the map
      this.showNewAddress = false; // Hide the new address form
      this.isMapVisible = true;
      setTimeout(() => {
        this.initMap();
      }, 0);
    } else if (this.isMapVisible) {
      // If the map is visible, close it and hide the address card
      this.showCardAddressContent = false;
      this.isMapVisible = false;
      this.isEditMode = false;     // Reset edit mode
      this.UserAddressListApi();   // Reload or refresh the address list if needed
    }
  }


  UserAddressListApi(): void {
    const requestBody = {
      userId: this.userId,
    };

    this.http.post(environment.apiUrl + `/useraddress/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            if (Array.isArray(data.useraddress_output)) {
              this.UserAddressList = data.useraddress_output;

              // Find the default address and set selectedAddressId
              const defaultAddress = this.UserAddressList.find(address => address.isdefault === true);
              if (defaultAddress) {
                this.selectedAddressId = defaultAddress.id; // Set default address as selected
              } else if (this.UserAddressList.length > 0) {
                // If no default address, select the first address in the list
                this.selectedAddressId = this.UserAddressList[0].id;
              }
            } else {
              console.error("Expected an array for useraddress_output");
            }
          }
        },
        (error) => {
          console.error("UserAddressList API Error:", error);
        }
      );
  }
  UserEditAddress(id: any): void {
    this.isEditMode = true;
    // Make the GET request to retrieve the address by id
    this.http.get(environment.apiUrl + `/useraddress/get?id=${id}`, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            // Assuming the response contains the address data
            const addressData = response.useraddress;

            // Set the component variables to the retrieved address values
            this.firstName = addressData.firstname;
            this.lastName = addressData.lastname;
            this.phone = addressData.phone;
            this.selectedCountryISO = CountryISO.India;
            this.email = addressData.email;
            this.deliveryArea = addressData.deliveryarea;
            this.address = addressData.address;
            this.doorNo = addressData.doorno;
            this.landmark = addressData.landmark;
            this.postalCode = addressData.postalcode;
            this.latitude = addressData.latitude;
            this.longitude = addressData.longitude;
            this.selectedAddressType = addressData.type
            this.showNewAddress = true;
            this.showCardAddressContent = false;
          } else {
            console.error('Failed to retrieve address:', response);
          }
        },
        (error) => {
          console.error('Error retrieving address:', error);
        }
      );
  }
  deleteAddressConfirmation(address: any): void {
    this.addressToDelete = address;  // Store the address to delete
    this.showDeletePopup = true;     // Show the popup
  }

  confirmDeleteAddress(): void {
    if (this.addressToDelete) {
      // Call your delete API here and pass this.addressToDelete.id
      this.http.delete(environment.apiUrl + `/useraddress/delete?id=${this.addressToDelete.id}`)
        .subscribe(
          (response: any) => {
            if (response.status === 'SUCCESS') {
              this.UserAddressList = this.UserAddressList.filter(address => address.id !== this.addressToDelete.id);

            } else {
              console.error('Failed to delete address');
            }
          },
          (error) => {
            console.error('Error deleting address:', error);
          }
        );
      this.showDeletePopup = false; // Close popup after deletion
    }
  }

  cancelDelete(): void {
    this.showDeletePopup = false;  // Close the popup without deleting
  }


  toogleSelectedPaymentDetailsMethod() {
    this.selectedPaymentDetailsMethod = !this.selectedPaymentDetailsMethod;
  }

  saveAddress(): void {
    if (this.isEditMode) {
      this.updateUserAddress();
    } else {
      this.createUserAddress();
    }
  }
  createUserAddress(): void {
    const requestBody = {
      id: this.selectedAddressId,  // Or you can dynamically generate an ID if necessary
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone?.e164Number, // Assuming this is a method to get the phone number
      deliveryArea: this.selectedAddress,
      address: this.address,
      doorNo: this.doorNo,
      landmark: this.landmark,
      postalCode: this.postalCode,
      latitude: this.latitude,
      longitude: this.longitude,
      createdBy: this.userId,
      userId: this.userId,
      type: this.selectedAddressType,
    };

    this.http.post(environment.apiUrl + '/useraddress/create', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.showNewAddress = false;
            this.isMapVisible = true;
            this.GoBack()
            // Handle success (e.g., show a success message, navigate, or update the UI)
          } else {
            console.error('Failed to create address', response);
          }
        },
        (error) => {
          console.error('Error creating address:', error);
        }
      );
  }

  updateUserAddress(): void {
    const requestBody = {
      id: this.selectedAddressId,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone?.e164Number,
      deliveryArea: this.deliveryArea,
      address: this.address,
      doorNo: this.doorNo,
      landmark: this.landmark,
      postalCode: this.postalCode,
      latitude: this.latitude,
      longitude: this.longitude,
      updatedBy: this.userId,
      type: this.selectedAddressType,
    };

    // Send HTTP PUT request to update the address
    this.http.post(environment.apiUrl + '/useraddress/update', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.showNewAddress = false;
            this.isMapVisible = true;
            this.GoBack();
            // Handle success, such as showing a success message or updating UI
          } else {
            console.error('Failed to update address', response);
          }
        },
        (error) => {
          console.error('Error updating address:', error);
        }
      );
  }
  setAddressType(type: string): void {
    this.selectedAddressType = type;
  }
  resetAddressForm(): void {
    this.firstName = '';
    this.lastName = '';
    this.phone = '';
    this.email = '';
    this.deliveryArea = '';
    this.address = '';
    this.doorNo = '';
    this.landmark = '';
    this.postalCode = '';
    this.selectedAddressType = ''; // or 'home', 'office', etc. if you have a default
    this.latitude = null;
    this.longitude = null;
  }
  toggleCardAddressContent() {
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
    this.selectedAddress = '';
    this.showCardAddressContent = !this.showCardAddressContent; // Toggle showCardAddressContent
    if (this.showCardAddressContent) {
      this.isMapVisible = true; // Show map only if the card content is visible
      this.showNewAddress = false;
      this.resetAddressForm();
      setTimeout(() => {
        this.initMap();
      }, 0);
    } else {
      this.isMapVisible = false; // Hide map if card content is hidden
    }
  }

  initMap() {
    const defaultLat = -33.8688;
    const defaultLng = 151.2195;

    // Use the selected latitude and longitude if available, else default coordinates
    const centerLat = this.latitude || defaultLat;
    const centerLng = this.longitude || defaultLng;

    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        center: { lat: centerLat, lng: centerLng },
        zoom: 13,
        mapTypeId: "roadmap",
      }
    );

    // Add a marker at the center of the map
    let centerMarker = new google.maps.Marker({
      position: { lat: centerLat, lng: centerLng },
      map: map,
      title: "Selected Location", // Optional: You can set a title for the marker
    });

    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
    });

    let markers: google.maps.Marker[] = [];

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places: google.maps.places.PlaceResult[] | undefined = searchBox.getPlaces();

      if (!places || places.length === 0) {
        return;
      }

      // Remove the center marker if a new place is selected
      if (centerMarker) {
        centerMarker.setMap(null); // Hide the center marker
      }

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }

        if (place.formatted_address) {
          this.selectedAddress = place.formatted_address; // Set the selected address

          // Initialize the geocoder
          const geocoder = new google.maps.Geocoder();

          // Geocode the address
          geocoder.geocode({ address: this.selectedAddress }, (results: {
            geometry: {
              location: {
                lat(): number | null | undefined; lng: () => number | null | undefined;
              };
            };
          }[], status: string) => {
            if (status === google.maps.GeocoderStatus.OK) {
              // Get latitude and longitude from the result
              this.latitude = results[0].geometry.location.lat();
              this.longitude = results[0].geometry.location.lng();
              console.log(this.latitude, this.longitude, "Location coordinates updated");
            } else {
              console.error('Geocoding failed: ' + status);
            }
          });
        }

        const icon = {
          url: place.icon as string,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for the selected place.
        markers.push(
          new google.maps.Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      map.fitBounds(bounds);
    });
  }



  saveNameChanges() {
    this.saveChanges();
  }
  toggleShowPaymentDetails() {
    this.selectedPaymentMethod = !this.selectedPaymentMethod;
  }


  saveGenderChanges() {
    this.saveChanges();
  }

  saveChanges() {
    // this.showPhoneOtpVerifyPopup = true;
    const requestBody: any = {
      updatedBy: this.userId,
      userType: "user",
      id: this.userId
    }; // Base request

    // Retrieve updated phone value from the form control
    const formValue = this.myGroup.value;

    // Add fields to requestBody if they have values
    if (formValue.firstname) requestBody.firstName = formValue.firstname;
    if (formValue.lastname) requestBody.lastName = formValue.lastname;
    if (formValue.gender) requestBody.gender = formValue.gender;
    if (formValue.email) requestBody.email = formValue.email;
    if (formValue.mobileInput) requestBody.phone = formValue.mobileInput;
    // Log the request body for debugging purposes
    if (this.myGroup.valid) {
      // Handle form submission here
    } else {
    }
    this.http.post(environment.apiUrl + `/users/user-update`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        // Reload user data after successful update
        this.getUserDetails();
        this.isNameEditing = false;
        this.isEmailEditable = false;
        this.isGenderEditing = false;
        this.isPhoneEditing = false;


      }
    );
  }

  cancelNameEdit() {
    this.isNameEditing = false;
  }

  cancelGenderEdit() {
    this.isGenderEditing = false;
  }

  sendOtpForPhoneUpdate() {
    const newMobileInputValue = this.myGroup.get('newMobileInput')?.value;
    const oldMobileInputValue = this.myGroup.get('mobileInput')?.value;
    let phoneToUpdate: string = oldMobileInputValue?.e164Number ?? newMobileInputValue?.e164Number ?? this.user_details.phone ?? '';

    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      userType: 'user',
      phone: phoneToUpdate,  // Send either old or new phone number
    };

    // Make the API call to send the OTP
    this.http.post(
      environment.apiUrl + `/users/phone-send-code-userphone-update`,
      requestBody,
      { responseType: 'json' }
    ).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          // Show OTP popup and enable "Verify" button
          if(this.isPasswordEditable){
            this.showPhoneOtpVerifyPopup = false;
            this.showChangePasswordOtpVerifyPopup=true
          }else{
            this.showPhoneOtpVerifyPopup = true;
          }
          this.isVerifyDisabled = false;  // Enable the "Verify" button after OTP is sent
          this.myGroup.get('otpCode')?.reset();
          // Set isOldPhone based on whether a new number was provided
          if (newMobileInputValue) {
            this.isOldPhone = false;  // Indicate that this is for the new number
            this.otpMessage = 'OTP is sent to your new number';
            this.phoneToVerify = newMobileInputValue.e164Number;  // Track the new phone number
          } else {
            this.isOldPhone = true;  // Indicate that this is for the old number
            this.otpMessage = 'OTP is sent to your old number';
            this.phoneToVerify = this.user_details.phone;  // Track the old phone number
          }
        } else {
          console.error('Failed to send OTP:', response);
        }
      },
      (error) => {
        console.error('API error:', error);

        // Check if the error response contains the specific error message
        if (error.error && error.error.errors && error.error.errors.phone && error.error.errors.phone.length > 0) {
          const errorMessage = error.error.errors.phone[0]; // Get the first error message
          // Show the specific error message in a snackbar
          this.snackBar.open(errorMessage, 'Close', {
            duration: 3000, // Duration in milliseconds
          });
        } else {
          // Optionally handle other errors
          this.snackBar.open('An error occurred. Please try again.', 'Close', {
            duration: 3000, // Duration in milliseconds
          });
        }
      }
    );
  }

  verifyOtpForPhoneUpdate() {
    // Get the OTP and phone values from the form and user details
    const otpCode = this.myGroup.get('otpCode')?.value;
    // Retrieve the phone value based on which input is active
    const phone = this.NewMobileInputOpen
      ? this.myGroup.get('newMobileInput')?.value?.e164Number
      : this.myGroup.get('mobileInput')?.value?.e164Number;


    // Check if OTP and phone values are present before making the API call
    if (!otpCode || !phone) {
      console.error('OTP or phone number is missing');
      this.snackBar.open('OTP or phone number is missing. Please try again.', 'Close', {
        duration: 3000,
      });
      return; // Exit if validation fails
    }

    // Prepare the request body for the API call
    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      phoneCode: otpCode,
      phone: phone
    };

    // Make the API call to validate OTP and update phone
    this.http.post(
      `${environment.apiUrl}/users/phone-code-validator-userphone-update`,
      requestBody,
      { responseType: 'json' }
    ).subscribe(
      (response: any) => {

        if (response.status === 'SUCCESS') {
          this.snackBar.open('Phone number verified successfully!', 'Close', {
            duration: 3000,

          });
          if (!this.isOldPhone ||!this.isPhoneEditing) {
            this.NewMobileInputOpen = false;
            this.NewMobileSavebtn=false

          } else {
            this.NewMobileInputOpen = true;
          }

          this.getUserDetails();
          this.toggleVerifyPhoneOtpPopup(false); // Close the OTP popup
        } else if (response.status === 'ERROR') {
          if (response.errors && response.errors.action && response.errors.action.length > 0) {
            const errorMessage = response.errors.action[0];
            this.snackBar.open(errorMessage, 'Close', {
              duration: 3000,
            });
          } else if (response.errors && response.errors.phone && response.errors.phone.length > 0) {
            const phoneErrorMessage = response.errors.phone[0]; // Handle specific phone validation errors
            this.snackBar.open(phoneErrorMessage, 'Close', {
              duration: 3000,
            });
          } else {
            this.snackBar.open('An unknown error occurred. Please try again.', 'Close', {
              duration: 3000,
            });
          }
        }
      },
      (error) => {
        console.error('API error:', error); // Log the full error for debugging
        let errorMessage = 'An error occurred. Please try again.';

        // Handle specific HTTP status errors or API error responses
        if (error.status === 451) {
          errorMessage = 'The request could not be processed due to legal reasons.';
        } else if (error.error && error.error.message) {
          errorMessage = error.error.message; // Use the API's error message
        }

        this.snackBar.open(errorMessage, 'Close', {
          duration: 3000,
        });
      }
    );
  }

  toggleVerifyPhoneOtpPopup(isOldPhone: boolean): void {
    this.showPhoneOtpVerifyPopup = !this.showPhoneOtpVerifyPopup;
    if (this.showPhoneOtpVerifyPopup) {
      this.myGroup.get('otpCode')?.reset();
    }
    this.isOldPhone = isOldPhone;
  }
  toggleCancel() {
    this.newMobileInput = false;
  }

    togglePhoneEdit() {
      const oldPhoneInputControl = this.myGroup.get('mobileInput');
      if (oldPhoneInputControl) {
        if (!oldPhoneInputControl.value?.number) {
          oldPhoneInputControl.enable();
        } 
        else {
          oldPhoneInputControl.disable();
        }
      }
      this.NewMobileSavebtn = true;
    }
    

    toggleEmailEdit() {
      const oldEmailInputControl = this.myGroup.get('oldEmail');
    
      if (oldEmailInputControl) {
        // Check if the oldEmail field has no value
        if (!oldEmailInputControl.value) {
          oldEmailInputControl.enable(); 
        } else {
          oldEmailInputControl.disable(); 
        }
      }
      
      this.verifyEmailBtn = true;
    }
    

  // Function to cancel email editing
  toggleCancelEmail() {
    this.isEmailEditing = false;
    this.myGroup.get('newEmail')?.reset();
  }

  // Function to send OTP for email verification
  sendOtpForEmailUpdate() {
    const newEmailInputValue = this.myGroup.get('newEmail')?.value;
    const oldEmailInputValue = this.myGroup.get('oldEmail')?.value;

    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      userType: 'user',
      email: newEmailInputValue || oldEmailInputValue, // Use new email if provided, else use old email
    };

    this.http.post(`${environment.apiUrl}/users/email-send-code-useremail-update`, requestBody)
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {

            if(this.isPasswordEditable){
              this.showEmailOtpVerifyPopup = false;
              this.showChangePasswordOtpVerifyPopup=true
            }else{
              this.showEmailOtpVerifyPopup = true;
            }
            this.isOldEmail = !newEmailInputValue; // Set based on whether a new email was provided
            this.myGroup.get('otpCode')?.reset();
           
            if (newEmailInputValue) {
              this.isOldEmail = false;  // Indicate that this is for the new number
              this.otpMessage = 'OTP is sent to your newEmailInputValue';
            } else {
              this.isOldEmail = true;  // Indicate that this is for the old number
              this.otpMessage = 'OTP is sent to your oldEmailInputValue';
            }


          } else {
            console.error('Failed to send OTP:', response);
          }
        },
        (error) => {
          console.error('API error:', error);
          this.snackBar.open('An error occurred. Please try again.', 'Close', { duration: 3000 });
        }
      );
  }

  // Function to verify the OTP for email update
  verifyOtpForEmailUpdate() {
    const otpCode = this.myGroup.get('otpCode')?.value;
    const email = this.isOldEmail ? this.myGroup.get('oldEmail')?.value : this.myGroup.get('newEmail')?.value;

    if (!otpCode || !email) {
      this.snackBar.open('OTP or email address is missing. Please try again.', 'Close', { duration: 3000 });
      return;
    }

    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      emailCode: otpCode,
      email: email,
    };

    this.http.post(`${environment.apiUrl}/users/email-code-validator-useremail-update`, requestBody)
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.snackBar.open('Email verified successfully!', 'Close', { duration: 3000 });
           
            if (!this.isOldEmail ||!this.isUserDetailEmail) {
              this.NewEmailInputOpen = false;
              this.verifyEmailBtn = false
              this.NewMobileSavebtn=false
            } else {
              this.NewEmailInputOpen = true;
            }
            this.getUserDetails(); // Refresh user details after update
            this.toggleVerifyEmailOtpPopup(); // Close OTP popup
          } else {
            this.snackBar.open('Verification failed. Please check your OTP and try again.', 'Close', { duration: 3000 });
          }
        },
        (error) => {
          console.error('API error:', error);
          this.snackBar.open('An error occurred. Please try again.', 'Close', { duration: 3000 });
        }
      );
  }

  // Function to toggle the OTP verification popup for email
  toggleVerifyEmailOtpPopup() {
    this.showEmailOtpVerifyPopup = !this.showEmailOtpVerifyPopup;
    if (this.showEmailOtpVerifyPopup) {
      this.myGroup.get('otpCode')?.reset();
    }
  }

  cancelEmailEdit() {
    this.isEmailEditable = false;
  }
  toggleShowSubscribePaymentDetails() {
    this.showSubscribePaymentDetails = !this.showSubscribePaymentDetails;
  }


  toggleVerifyOtpPopup() {
    this.showOtpVerifyPopup = !this.showOtpVerifyPopup;
  }






  highlightBorder() {
    const inputOtpElement = document.querySelector('.otp-input');
    inputOtpElement?.classList.add('highlighted');
    const inputPasswordElement = document.querySelector('.password-input');
    inputPasswordElement?.classList.add('highlighted');
    const inputPhoneElement = document.querySelector('.phone-otp-input');
    inputPhoneElement?.classList.add('highlighted');
  }

  resetBorder() {
    const inputElement = document.querySelector('.otp-input');
    inputElement?.classList.remove('highlighted');
    const inputPasswordElement = document.querySelector('.password-input');
    inputPasswordElement?.classList.remove('highlighted');
  }

// change password
editPassword() {
  this.isPasswordEditable = true;
  if (this.user_details.phone !== null && this.user_details.email !== null) {
    this.sendOtpForPhoneUpdate();
    this.sendOtpForEmailUpdate();
  } 
  else if (this.user_details.phone !== null) {
    this.sendOtpForPhoneUpdate();
  } 
  else if (this.user_details.email !== null) {
    this.sendOtpForEmailUpdate();
  }
}


  cancelPasswordEdit() {
    this.isChangePassword = false;
  }
 // Function to toggle the OTP verification popup for email
 toggleVerifyChangePasswordOtpPopup() {
  this.showChangePasswordOtpVerifyPopup = !this.showChangePasswordOtpVerifyPopup;
  if (this.showChangePasswordOtpVerifyPopup) {
    this.myGroup.get('otpCode')?.reset();
  }
}


 // Function to verify the OTP for email update
 verifyOtpForChangePasswordUpdate() {
  const otpCode = this.myGroup.get('otpCode')?.value;

  if (!this.userId) {
    console.error('User ID not found in local storage'); // Debugging log
    this.snackBar.open('User ID not found', 'close', { duration: 3000, verticalPosition: 'top' });
    return;
  }
  const requestBody = {
    updatedBy: this.userId,
    id: this.userId,
    code: otpCode
  };
  this.http.post(`${environment.apiUrl}/users/forgotpassword-code-validator`, requestBody).subscribe({
    next: (data: any) => {
      if (data.status === 'SUCCESS') {
        this.showChangePasswordOtpVerifyPopup=false
        this.isChangePassword=true
        this.snackBar.open('OTP verified successfully', 'close', { duration: 3000, verticalPosition: 'top' });
        // this.showChangePassword();
      } else {
        this.snackBar.open('Invalid OTP', 'close', { duration: 3000, verticalPosition: 'top' });
      }
    },
    error: (err) => {
      console.error('Error during OTP verification:', err); // Debugging log
      this.snackBar.open('Error occurred while verifying OTP', 'close', { duration: 3000, verticalPosition: 'top' });
    }
  });
}
savePassword() {
  // Add logic to save the new password
  this.isChangePassword = false;
  this.showPasswordVerifyPopup = true;
  this.onChangePassword()
}

onChangePassword(): void {
  const newPassword = this.myGroup.get('newPassword')?.value;
  
  // const userId = localStorage.getItem('userId');
  
  if (!this.userId) {
    console.error('User ID not found in local storage'); // Debugging log
    this.snackBar.open('User ID not found', 'close', { duration: 3000, verticalPosition: 'top' });
    return;
  }
  const requestBody = {
    updatedBy: this.userId,
    id: this.userId,
    newPassword: newPassword
  };

  this.http.post(`${environment.apiUrl}/users/change-password-update`, requestBody).subscribe({
    next: (data: any) => {
      if (data.status === 'SUCCESS') {
        this.isChangePassword=false
        this.snackBar.open('Password changed successfully', 'close', { duration: 3000, verticalPosition: 'top' });
        localStorage.removeItem('userId'); // Clear userId from local storage
      } else {
        this.snackBar.open('Failed to change password', 'close', { duration: 3000, verticalPosition: 'top' });
      }
    },
    error: () => {
      this.snackBar.open('Error occurred while changing password', 'close', { duration: 3000, verticalPosition: 'top' });
    }
  });
}
  // end
  toggleVerifyPasswordPopup() {
    this.showPasswordVerifyPopup = !this.showPasswordVerifyPopup;
  }



  toggleCardPaymentContent() {
    this.showCardPaymentContent = !this.showCardPaymentContent;
  }


  showNewAddressContainer() {
    this.showNewAddress = true; // Show the "Add New Address" form
    this.isMapVisible = false;  // Hide the map
  }


  checkPassword() {
    this.isVerifyPasswordDisabled = this.passwordInput.trim() === '';

  }

  verifyPassword() {
    if (!this.isVerifyPasswordDisabled) {
    }
  }

  toggleOrderDetails(): void {
    this.showOrderDetails = !this.showOrderDetails;
  }

  toggleSubscribeDetails() {
    this.showSubscribeDetails = !this.showSubscribeDetails;
  }
  togglePauseSubscribeDetails() {
    this.showPauseSubscribeDetails = !this.showPauseSubscribeDetails;
  }

  toggleSignoutPopup() {
    this.showSignoutPopup = !this.showSignoutPopup;
  }

  toggleSubscriptionPopup() {
    this.showsubscriptionPopup = !this.showsubscriptionPopup;
  }

  toggleCardDeletePopup() {
    this.showCardDeletePopup = !this.showCardDeletePopup;
  }

  onSectionChange(section: string): void {
    this.activeSection = section;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { section: section },
      queryParamsHandling: 'merge', // Preserve other query params if there are any
    });
  }

  removeWishList(productId: any, productVariationId: any) {
    const requestBody = {
      status: -1,
      createdBy: this.userId,
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId
    };

    this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        const index = this.wishlistitems_output.findIndex(
          (item: any) => item.productid === productId && item.productvariationid === productVariationId
        );

        if (index !== -1) {
          this.wishlistitems_output.splice(index, 1);
        }
        this.count = this.wishlistitems_output.length;
      })
  }

  cartList() {
    const requestBody = { userId: this.userId };

    this.http.post(environment.apiUrl + `/cart/list`, requestBody, { responseType: 'json' }).pipe(
      switchMap((data: any) => {
        if (data.status === 'SUCCESS') {
          this.cartItems = data.cart_output;
          this.count = data.count;

          const imageRequests = this.cartItems.map((item: any) => {
            return this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`).pipe(
              switchMap((datas: any) => {
                if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                  return this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`);
                } else {
                  return of({ status: 'ERROR' });
                }
              }),
              map((res: any) => {
                if (res.status === "SUCCESS") {
                  item.src = [res.uploads.base64data];
                } else {
                  item.src = [];
                }
                return item;
              })
            );
          });

          // Use forkJoin to wait for all image requests to complete
          return forkJoin(imageRequests).pipe(
            switchMap((itemsWithImages: any[]) => {
              // Get variations for all items
              const variationRequests = itemsWithImages.map((item: any) => {
                return this.variationApiCall(item.productid, item.productvariationid).pipe(
                  map((filteredVariations: any) => {
                    item.variations = filteredVariations;

                    const selectedVariation = item.variations.find((v: any) => v.id === item.productvariationid);
                    if (selectedVariation) {
                      item.selectedVariationId = selectedVariation.id;
                      item.selectedPrice = selectedVariation.sellingprice;
                      item.mrpPrice = selectedVariation.mrpprice;
                    }

                    return item;
                  })
                );
              });

              return forkJoin(variationRequests);
            })
          );
        } else {
          return of([]);
        }
      }),
      catchError(error => {
        console.error("Error in cartList API call:", error);
        return of([]);
      })
    ).subscribe(
      (updatedItems: any[]) => {
        this.cartItems = updatedItems;
        // this.getMrpPrice();
        // this.getSubTotalPrice();

      }
    );
  }

  variationApiCall(productid: any, productvariationid: any) {
    if (this.variationsCache.has(productid)) {
      return of(this.variationsCache.get(productid));
    }

    const requestBody = {
      productId: productid,
      productvariationId: productvariationid
    };

    return this.http.post(environment.apiUrl + `/products/list-productvariations`, requestBody, { responseType: 'json' }).pipe(
      map((data: any) => {
        if (data.status === 'SUCCESS') {

          const filteredVariations = data.productvariations_output.filter((variation: any) => variation.issale === true);
          this.variationsCache.set(productid, filteredVariations);
          this.variationsCacheSubject.next(this.variationsCache);
          return filteredVariations;
        } else {
          return [];
        }
      }),
      catchError(error => {
        console.error("Error in variationApiCall API call:", error);
        return of([]);
      })
    );
  }

  getPriceToShow(variation: any): number {
    if (variation.issubscribe && variation.issale) {
      if (variation.dataFromPreviousComponent === 'bestSelling') {
        this.priceToShow = variation.sellingprice; // Set priceToShow when dataFromPreviousComponent is 'bestSelling'
      } else if (variation.dataFromPreviousComponent === 'subscribeProduct') {
        this.priceToShow = variation.priceupto7days; // Set priceToShow when dataFromPreviousComponent is 'subscribe'
      } else {
        this.priceToShow = variation.priceupto7days; // Default to priceupto7days if both issubscribe and issale are true
      }
    } else if (variation.issubscribe) {
      this.priceToShow = variation.priceupto7days; // Set priceToShow if only issubscribe is true
    } else if (variation.issale) {
      this.priceToShow = variation.sellingprice; // Set priceToShow if only issale is true
    } else {
      this.priceToShow = variation.mrpprice; // Default to mrpPrice if neither issubscribe nor issale is true
    }
    return this.priceToShow; // Return the updated priceToShow
  }

  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity
    };

    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const product = this.productList.find(item => item.id === productId);
          if (product) {
            product.cartquantity = quantity;
          }

          this.addedToCart.set(productId, true);
          this.cartService.updateCartCount(data.newCartCount);

        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }

  triggerUpload() {
    this.renderer.selectRootElement(this.fileInput?.nativeElement).click();
  }

  handleLicenceChange(event: any): void {
    const file = event.target.files[0];
    let reader = new FileReader();

    // if(this.ifMobileApp() && reader["__zone_symbol__originalInstance"]) {
    //   reader = reader["__zone_symbol__originalInstance"];
    // }

    reader.onload = () => {
      const base64String = reader.result as string;
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        const maxWidth = 2400;
        const maxHeight = 1800;
        const thumbnailSize = 350;

        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }

        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);

          const compressedBase64String = canvas.toDataURL('image/jpeg', 0.7); // Adjust quality as needed

          // Create thumbnail
          const thumbnailCanvas = document.createElement('canvas');
          const thumbnailCtx: CanvasRenderingContext2D | null = thumbnailCanvas.getContext('2d');

          thumbnailCanvas.width = thumbnailSize;
          thumbnailCanvas.height = thumbnailSize;

          if (thumbnailCtx) {
            thumbnailCtx.drawImage(img, 0, 0, thumbnailSize, thumbnailSize);

            const thumbnailBase64String = thumbnailCanvas.toDataURL('image/jpeg', 0.7); // Adjust quality as needed
            const data = {

              firstname: this.user_details.firstname,
              lastname: this.user_details.lastname,
              gender: this.gender,
              profilepic: compressedBase64String,
              updatedBy: this.userId,
              userType: this.user_details.usertype,
              id: this.userId
            };

            this.http.post(environment.apiUrl + `/users/user-update`, data, { responseType: 'json' }).subscribe(async (data: any) => {
              if (data.status === "SUCCESS") {
                //   this.licence = thumbnailBase64String;
                //   this.drivinglicenceUploadId = data.data.id;
                //   this.data.drivinglicenceId = data.data.id;
                //   this.merchantBookingForm.get('bookingDetailsJson')?.setValue(this.data);
                //   this.snackBar.open('Licence added successfully.', 'close', { duration: 3000, verticalPosition: 'top' });
                // } else {
                //   this.snackBar.open('Some error occurred while adding Image.', 'close', { duration: 3000, verticalPosition: 'top' });
              }
            });
          }
        }
      };

      img.src = base64String;
    };

    reader.readAsDataURL(file);
  }

}








