<div class="banner-img">
    <button class="close border-0" (click)="closeDialog()">
        <img src="../../assets/icon/close.svg" alt="Close">
    </button>
    <img src="../../assets/play-store/Gp store banner.svg" alt="Banner" class="fit-content d-lg-block d-none">
    <img src="../../assets/icon/banner_mobile.svg" alt="Mobile Banner" class="d-lg-none mobile-banner">
    <div class="play-store-img">
        <img src="../../assets/play-store/app-store.svg" alt="App Store" class="me-2">
        <img src="../../assets/play-store/google-store.svg" alt="Google Store">
    </div>
</div>








<!-- <div class="banner-img d-none d-md-block">
    <button class="close border-0" (click)="closeDialog()"><img src="../../assets/icon/close.svg" alt=""></button>
        <img src="../../assets/play-store/Gp store banner.svg" alt="" class="fit-content ">
    <div class="play-store-img ">
        <img src="../../assets/play-store/app-store.svg" alt="" class="me-2"> 
        <img src="../../assets/play-store/google-store.svg" alt="">
    </div>
</div>

<div class="banner-img d-md-none d-block">
    <button class="close border-0" (click)="closeDialog()">
        <img src="../../assets/icon/close.svg" alt="Close">
    </button>
    <img src="../../assets/icon/banner_mobile.svg" alt="Mobile Banner" class=" mobile-banner">
    <div class="play-store-img">
        <img src="../../assets/play-store/app-store.svg" alt="App Store" class="me-2">
        <img src="../../assets/play-store/google-store.svg" alt="Google Store">
    </div>
</div> -->