import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar'; 
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { catchError, forkJoin, of } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  currentSection: string = 'mobileVerification';
  // currentSection: string = 'newRegister';


  otpForm!: FormGroup;
  SignInForm!: FormGroup;
  mobileVerificationForm!: FormGroup;
  isVerifyEnabled: boolean = false;
  isVerifyOTPEnabled: boolean = false
  isSubmitting: boolean = false;
  
  passwordVisible: boolean = false;
  confirmPasswordVisible: boolean = false; 
  showEmailOtpVerification: boolean = false;
  showMobileOtpVerification: boolean = false;
  userId: string = '';
  userEmail: string = '';
  userPhone: string = '';
  emailOtpError: string | null = null;
  phoneOtpError: string | null = null;
  otpError: string | null = null;
  mobileError: string | null = null;
  emailError: string | null = null;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  emailOTPForm: FormGroup = this.formBuilder.group({
    emailOTP: ['', Validators.required]
  });

  mobileOTPForm: FormGroup = this.formBuilder.group({
    mobileOTP: ['', Validators.required]
  });

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {
    this.mobileOTPForm = this.formBuilder.group({
      mobileOTP: ['', [Validators.required, Validators.pattern('^[0-9]+$')]]
    });
    this.emailOTPForm = this.formBuilder.group({
      emailOTP: ['', [Validators.required, Validators.pattern('^[0-9]+$')]]
    });

    this.otpForm = this.formBuilder.group({
      mobileOTP: [''],
      emailOTP: ['']
    }, { validator: this.atLeastOneRequired });


    this.SignInForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.passwordMatchValidator
    });
  }

  ngOnInit(): void {


    this.mobileVerificationForm = this.formBuilder.group({
      mobileInput: ['', [Validators.required, this.mobileValidator]],
      emailInput: ['', [Validators.required, this.emailValidator]],
      // phone: ['' ]
    });

    this.mobileVerificationForm.valueChanges.subscribe(() => {
      const mobileInputControl = this.mobileVerificationForm.get('mobileInput');
      this.enableVerifyButton();
    });

  }

  atLeastOneRequired(control: AbstractControl): { [key: string]: boolean } | null {
    const mobileOTP = control.get('mobileOTP');
    const emailOTP = control.get('emailOTP');
    if (mobileOTP?.value || emailOTP?.value) {
      return null;
    }
    return { atLeastOneRequired: true }; // Invalid
  }
  mobileValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const phoneNumber = control.value?.e164Number || '';
    const isValid = phoneNumber.length === 12; // Adjust this based on the exact length you want to validate (e.g., 12 for '+country_code + 10 digits')
    return isValid ? null : { invalidMobile: true };
  }


  emailValidator(control: any) {
    if (control.value && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(control.value)) {
      return { 'invalidEmail': true };
    }
    return null;
  }

  passwordMatchValidator(formGroup: FormGroup): { [key: string]: boolean } | null {
    const password = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { 'mismatch': true };
  }
  get f(): { [key: string]: AbstractControl } {
    return this.mobileVerificationForm.controls;
  }

  onSubmit(): void {
    if (this.SignInForm.invalid) {
      return;
    }

    const formData = {
      firstName: this.SignInForm.get('firstName')?.value,
      lastName: this.SignInForm.get('lastName')?.value,
      password: this.SignInForm.get('password')?.value,
      updatedBy: this.userId,
      id: this.userId,
      status: 1,
      userType:"user"
    };

    this.http.post(`${environment.apiUrl}/users/register-update`, formData)
      .subscribe({
        next: (response: any) => {
          if (response.status === 'SUCCESS') {
            this.router.navigate(['/login']);
          }

        },
        error: (error) => {
          console.error('Error occurred during registration:', error);
        }
      });
  }
  onFocus(controlName: string): void {
    if (controlName === 'mobileInput') {
      this.mobileError = '';
    } else if (controlName === 'emailInput') {
      this.emailError = '';
    }

  }

  onBlur(controlName: string): void {
    const control = this.mobileVerificationForm.get(controlName);
    if (control && control.invalid && control.touched) {
      if (controlName === 'mobileInput') {
        this.mobileError = 'Enter a 10-digit mobile number.';
      } else if (controlName === 'emailInput') {
        this.emailError = 'Enter a valid email address.';
      }
    }
  }
  isValidPhoneNumber(phoneNumber: any): boolean {
    // You need to ensure that `phoneNumber` has a structure that includes `e164Number`.
    // Adjust the validation logic as per your specific requirements
    if (phoneNumber && phoneNumber.e164Number && phoneNumber.e164Number.length > 10) {
      return true;
    }
    return false;
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }
  toggleConfirmPasswordVisibility(): void {
    this.confirmPasswordVisible = !this.confirmPasswordVisible;
  }
  enableVerifyButton(): void {
    const mobileInputControl = this.mobileVerificationForm.get('mobileInput');
    const emailInputControl = this.mobileVerificationForm.get('emailInput');

    const isMobileValid = mobileInputControl && this.isValidPhoneNumber(mobileInputControl.value);
    const isEmailValid = emailInputControl ? emailInputControl.valid : false;


    this.isVerifyEnabled = isMobileValid || isEmailValid;
  }


  enableOTPVerifyButton(): void {
    const mobileOtpControl = this.otpForm.get('mobileOTP');
    const emailOtpControl = this.otpForm.get('emailOTP');

    // Check if mobile OTP is valid (non-empty)
    const isMobileOtpValid = mobileOtpControl ? mobileOtpControl.value.trim().length > 0 : false;

    // Check if email OTP is valid (non-empty)
    const isEmailOtpValid = emailOtpControl ? emailOtpControl.value.trim().length > 0 : false;

    // Enable the button if one or both OTPs are valid
    this.isVerifyOTPEnabled = isMobileOtpValid || isEmailOtpValid;

    // If both fields are present, only enable the button when both are filled
    if (this.showMobileOtpVerification && this.showEmailOtpVerification) {
      this.isVerifyOTPEnabled = isMobileOtpValid && isEmailOtpValid;
    }
  }




  verifyInput(): void {
    const mobileInputControl = this.mobileVerificationForm.get('mobileInput');
    const emailInputControl = this.mobileVerificationForm.get('emailInput');

    // Retrieve and trim inputs
    const mobileInput = mobileInputControl?.value || '';
    const emailInput = emailInputControl?.value || '';

    // Initialize validation status
    let mobileValidated = false;
    let emailValidated = false;

    // Prepare request body
    const requestBody: any = {};
    if (mobileInput && mobileInput.e164Number) {
        requestBody.phone = mobileInput.e164Number;
    }
    if (emailInput) {
        requestBody.email = emailInput;
    }

    // Check if at least one input is provided
    if (mobileInput || emailInput) {
        this.http.post(`${environment.apiUrl}/users/register-send-code`, requestBody).subscribe({
            next: (data: any) => {
                if (data.status === 'SUCCESS') {
                    // Handle successful response
                    if (mobileInput && mobileInput.e164Number) {
                        this.userId = data.users.id;
                        this.userPhone = mobileInput.e164Number;
                        this.showMobileOtpVerification = true;
                        mobileValidated = true;
                    }
                    if (emailInput) {
                        this.userId = data.users.id;
                        this.userEmail = emailInput;
                        this.showEmailOtpVerification = true;
                        emailValidated = true;
                    }
                    this.checkAndNavigate(mobileValidated, emailValidated);
                    
                }
            },
            error: (err) => {
                // Handle error response
                let errorMessage = 'Error sending OTP.';
                if (err.error.errors) {
                    if (err.error.errors.phone) {
                        errorMessage = err.error.errors.phone[0];
                    } else if (err.error.errors.email) {
                        errorMessage = err.error.errors.email[0];
                    }
                }
                this.snackBar.open(errorMessage, 'Close', {
                    duration: 5000,
                    verticalPosition: 'top'
                });
            }
        });
    }
}


  checkAndNavigate(mobileValidated: boolean, emailValidated: boolean): void {
    if (mobileValidated || emailValidated) {
      this.currentSection = 'otpVerification';
    }
  }


  onOtpSubmit(): void {
    // if (this.otpForm.invalid) {
    //   return;
    // }


    const mobileOtp = this.otpForm.get('mobileOTP')?.value;
    const emailOtp = this.otpForm.get('emailOTP')?.value;
    const verificationTasks: Promise<void>[] = [];

    if (this.showMobileOtpVerification && mobileOtp) {


      const mobileOtpVerification = {
        phoneCode: mobileOtp,
        updatedBy: this.userId,
        id: this.userId,
        phone: this.userPhone
      };

      verificationTasks.push(this.verifyMobileOtp(mobileOtpVerification));
    }

    if (this.showEmailOtpVerification && emailOtp) {
      const emailOtpVerification = {
        emailCode: emailOtp,
        updatedBy: this.userId,
        id: this.userId,
        email: this.userEmail
      };
      verificationTasks.push(this.verifyEmailOtp(emailOtpVerification));
    }

    Promise.all(verificationTasks).then(() => {
      this.currentSection = 'newRegister';
      this.otpError = null; // Clear error message on success
    }).catch((error) => {
      this.otpError = error; // Display the first error encountered
    });
  }

  verifyMobileOtp(mobileOtpVerification: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http.post(`${environment.apiUrl}/users/phone-code-validator`, mobileOtpVerification).subscribe({
        next: (data: any) => {
          if (data.status === 'SUCCESS') {
            this.showMobileOtpVerification = false;
            resolve();
          } else {
            reject(data.message);
          }
        },
        error: (err) => {
          reject(err.error.errors.action[0]);
        }
      });
    });
  }

  verifyEmailOtp(emailOtpVerification: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http.post(`${environment.apiUrl}/users/email-code-validator`, emailOtpVerification).subscribe({
        next: (data: any) => {
          if (data.status === 'SUCCESS') {
            this.showEmailOtpVerification = false;
            resolve();
          } else {
            reject(data.message);
          }
        },
        error: (err) => {
          reject(err.error.errors.action[0]);
        }
      });
    });
  }



  // 

  // onMobileOtpSubmit(): void {
  //   if (this.mobileOTPForm.invalid) {
  //     return;
  //   }


  //   const mobileOtpVerification = {
  //     phoneCode: this.mobileOTPForm.get('mobileOTP')?.value,
  //     updatedBy: this.userId,
  //     id: this.userId,
  //     phone: this.userPhone
  //   };

  //   this.http.post(`${environment.apiUrl}/users/phone-code-validator`, mobileOtpVerification).subscribe({
  //     next: (data: any) => {

  //       if (data.status === 'SUCCESS') {
  //         this.currentSection = 'newRegister';
  //         this.showMobileOtpVerification = false;
  //         this.phoneOtpError = null;  // Clear error message on success
  //       } 
  //     },
  //     error: (err) => {
  //       console.error('Network or unexpected error:', err);

  //       this.phoneOtpError = JSON.stringify(err.error.errors.action[0]);
  //     }
  //   });

  // }


  // onEmailOtpSubmit(): void {

  //   if (this.emailOTPForm.invalid) {
  //     return;
  //   }

  //   const emailOtpVerification = {
  //     emailCode: this.emailOTPForm.get('emailOTP')?.value,
  //     updatedBy: this.userId,
  //     id: this.userId,
  //     email: this.userEmail
  //   };


  //   this.http.post(`${environment.apiUrl}/users/email-code-validator`, emailOtpVerification)
  //     .subscribe({
  //       next: (data: any) => {
  //         if (data.status === 'SUCCESS') {
  //           this.currentSection = 'newRegister';
  //           this.showEmailOtpVerification = false;
  //           this.emailOtpError = null;  // Clear error message on success
  //         } 
  //       },
  //       error: (err) => {
  //         this.emailOtpError = JSON.stringify(err.error.errors.action[0]);
  //       }
  //     });
  // }

  // const mobileInputControl = this.mobileVerificationForm.get('mobileInput');
  // const emailInputControl = this.mobileVerificationForm.get('emailInput');

  

  // let mobileValidated = false;
  // let emailValidated = false;

  // const requestBody: any = {};
  // if (mobileInput && mobileInput.e164Number) {
  //     requestBody.phone = mobileInput.e164Number.replace(/^\+/, '');
  // }
  // if (emailInput) {
  //     requestBody.email = emailInput;
  // }


  onResendOtp(): void {
    const mobileInputControl = this.mobileVerificationForm.get('mobileInput');
    const emailInputControl = this.mobileVerificationForm.get('emailInput');

    // Retrieve and trim inputs
    const mobileInput = mobileInputControl?.value || '';
    const emailInput = emailInputControl?.value || '';

    // Initialize validation status
    let mobileValidated = false;
    let emailValidated = false;

    // Prepare request body
    const requestBody: any = {};
    if (mobileInput && mobileInput.e164Number) {
        requestBody.phone = mobileInput.e164Number;
    }
    if (emailInput) {
        requestBody.email = emailInput;
    }
    // Check if at least one of phone or email is provided
    if (mobileInput || emailInput) {
        this.http.post(`${environment.apiUrl}/users/register-send-code`, requestBody)
            .subscribe(
                (data: any) => {
                    if (data.status === 'SUCCESS') {
                        // Show OTP input fields if corresponding OTPs are expected
                        if (mobileInput) this.showMobileOtpVerification = true;
                        if (emailInput) this.showEmailOtpVerification = true;

                        // Show success snackbar
                        this.snackBar.open('OTP sent successfully.', 'Close', {
                          duration: 3000,
                          horizontalPosition: 'center', 
                          verticalPosition: 'top', 
                      });
                      
                    } else {
                        this.emailOtpError = 'Failed to resend OTP. Please try again.';
                    }
                },
                error => {
                    console.error('Error occurred while resending OTP:', error);
                    this.emailOtpError = 'An error occurred while resending OTP. Please try again.';
                }
            );
    } else {
        this.emailOtpError = 'Please provide either a mobile number or email to resend the OTP.';
    }
}


// Helper function to handle OTP response
handleOtpResponse(mobileOtpSuccess: boolean, emailOtpSuccess: boolean): void {
    if (mobileOtpSuccess || emailOtpSuccess) {
        // Show success snackbar
        this.snackBar.open('OTP sent successfully.', 'Close', {
            duration: 3000,
            horizontalPosition: 'center', 
            verticalPosition: 'top'
        });
    }

    // Enable the verify button only when both mobile and email OTPs are entered
    this.isVerifyOTPEnabled = (this.otpForm.get('mobileOTP')?.value || this.otpForm.get('emailOTP')?.value) ? true : false;
}




}
