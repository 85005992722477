import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input'
import { BehaviorSubject, catchError, forkJoin, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { ProductService } from '../services/product.service';
@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css']
})
export class ProfilePageComponent implements OnInit {
  activeSection: string = 'profile'; // Default section
  userId: string | null = null;
  wishlistitems_output: any[] = [];
  categoryList: any[] = [];
  user_details: any = {};
  gender: string | null = null;
  src: any;
  price: any;
  count: any;
  productList: any[] = [];
  cartItems: any[] = [];
  wishList: any[] = [];
  profileImageUrl: string = '';
  myGroup: FormGroup;
  priceToShow!: number;
   mobileNum: string | null = null; 
  addedToCart = new Map<number, boolean>();
  // preferredCountries: string[] = ['in', 'us', 'gb'];
  private variationsCache = new Map<string, any[]>();
  private variationsCacheSubject = new BehaviorSubject<Map<string, any[]>>(this.variationsCache);

  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  CountryISO = CountryISO; 
  SearchCountryField = SearchCountryField; 
  
  @ViewChild('fileInput') fileInput: ElementRef | undefined;

  constructor(private route: ActivatedRoute, private router: Router, private elementRef: ElementRef, private productService: ProductService, public auth: AuthService, private http: HttpClient, private cartService: CartService, private fb: FormBuilder, private renderer: Renderer2,

  ) {
    this.auth.userInfo.subscribe((data: any) => {
      this.userId = data?.id;
      this.cartList();
      this.getUserDetails();
    });
    this.myGroup = this.fb.group({
      firstname: [{ value: this.user_details.firstname, disabled: true }],
      lastname: [{ value: this.user_details.lastname, disabled: true }],
      gender: [{ value: this.user_details.gender, disabled: true }],
      mobileInput: [{ value: this.user_details.phone, disabled: true }],
      email: [{ value: this.user_details.email, disabled: true }],
      currentPassword: [{ value: '', disabled: true }],
      newPassword: [{ value: '', disabled: true }]
    });
    
  }

  ngOnInit(): void {
    // Get the query params to set the initial active section
    this.route.queryParams.subscribe(params => {
      if (params['section']) {
        this.activeSection = params['section'];
      }
      const element = this.elementRef.nativeElement.querySelector('.profile-container');
      if (element) {
        window.scroll(0, 0);
      }

    });
    // this.productService.getData().subscribe((data:any) => {
    // })
    // this.myGroup = this.fb.group({
    //   mobileInput: [this.user_details.phone, [Validators.required]], // Initialize the control with the user's phone number
    //   // other form controls...
    // });


    // this.ProductListCall( this.wishlistitems_output)
    this.whish_list();
  }
  isEditing: boolean = false;
  isNameEditing: boolean = false;
  isGenderEditing: boolean = false;
  isPhoneEditing: boolean = false;

  toggleNameEdit() {
    this.isNameEditing = !this.isNameEditing;
    if (this.isNameEditing) {
      this.myGroup.get('firstname')?.enable();
      this.myGroup.get('lastname')?.enable();
    } else {
      this.myGroup.get('firstname')?.disable();
      this.myGroup.get('lastname')?.disable();
    }
  }
  
  toggleGenderEdit() {
    this.isGenderEditing = !this.isGenderEditing;
    if (this.isGenderEditing) {
      this.myGroup.get('gender')?.enable();
    } else {
      this.myGroup.get('gender')?.disable();
    }
  }
  
  togglePhoneEdit() {
    // this.isPhoneEditing = !this.isPhoneEditing;
    this.showPhoneOtpVerifyPopup = true;
    if (this.isPhoneEditing) {
      this.myGroup.get('mobileInput')?.enable();
    } else {
      this.myGroup.get('mobileInput')?.disable();
    }
    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      userType: 'user',
      phone: '+91'+ this.user_details.phone,
      // "phone":"+91" +""
    };
    this.http
      .post(
        environment.apiUrl + `/users/phone-send-code-userphone-update`,
        requestBody,
        { responseType: 'json' }
      )
      .subscribe((data: any) => {
        if (data.status === 'SUCCESS') {
          console.log(data);
         
        }
      });
  }
 
 
  
  toggleEmailEdit() {
    this.isEmailEditable = !this.isEmailEditable;
    if (this.isEmailEditable) {
      this.myGroup.get('email')?.enable();
    } else {
      this.myGroup.get('email')?.disable();
    }
  }
  
  whish_list() {
    const requestBody = {
      userId: this.userId,
    }
    this.http.post(environment.apiUrl + `/wishlistitems/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.count = data.count
          //  if(data.wishlistitems_output.status === 1){

          //  }
          this.wishList = data.wishlistitems_output
          const element = this.elementRef.nativeElement.querySelector('.profile-container');
          if (element) {
            window.scroll(0, 0);
          }

          // Prepare all image requests
          const imageRequests = this.wishList.map((item: any) =>
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`).pipe(
              switchMap((datas: any) => {

                if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                  return this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`);
                } else {
                  return of({ status: 'ERROR' });
                }
              }),
              map((res: any) => {
                if (res.status === "SUCCESS") {
                  item.src = [res.uploads.base64data]; // Corrected this to use the correct property path
                } else {
                  item.src = [];
                }
                return item;
              })
            )
          );
          forkJoin(imageRequests).subscribe((results) => {
            this.wishList = results; // Update the wishlist with the loaded images
          });

          data.wishlistitems_output.forEach((item: any) => {
            this.getPriceToShow(item);
            item.src = [];
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.id}`).subscribe((datas: any) => {
              if (datas.status === "SUCCESS") {
                if (datas.uploads.length > 0) {
                  this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      item.src.push(res.uploads.base64data);
                    }
                  });
                }
              }
            });
          })

          this.wishlistitems_output = data.wishlistitems_output;
        }
      })
  }
  toggleEdit() {
    this.isEditing = true;
  }
  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = control.value && control.value.isValidNumber ? control.value.isValidNumber() : false;
      return isValid ? null : { invalidPhoneNumber: true };
    };
  }
  getUserDetails() {
    this.http.get(environment.apiUrl + `/users/get?id=${this.userId}`).subscribe((res: any) => {
      if (res.status === "SUCCESS") {
        this.user_details = res.users;
        this.gender = this.user_details.gender;
  
        // Log user details to ensure data is fetched
  
        if (this.user_details.gender) {
          this.gender = this.user_details.gender.charAt(0).toUpperCase() + this.user_details.gender.slice(1).toLowerCase();
        } else {
          this.gender = null;
        }
        if (this.user_details.phone) {
           this.mobileNum= this.user_details.phone.replace(/^\+91/, '');

        }else {
           this.mobileNum = null;
        }
        this.myGroup.patchValue({
          firstname: this.user_details.firstname,
          lastname: this.user_details.lastname,
          gender: this.gender,
          mobileInput: this.mobileNum,
          email: this.user_details.email
        });
  
        // Handle imageinfo
        try {
          // Check if imageinfo is a valid string or object, else fallback to empty array
          this.user_details.imageinfo = typeof this.user_details.imageinfo === 'string'
            ? JSON.parse(this.user_details.imageinfo)
            : this.user_details.imageinfo || []; // Fallback to empty array
  
  
          if (Array.isArray(this.user_details.imageinfo) && this.user_details.imageinfo.length > 0) {
            const firstImage = this.user_details.id; // Corrected access
  
            // Set default profile image first
            this.profileImageUrl = "../../assets/images/profile-man.svg"; 
            // Fetch image data
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=users&dataForId=${firstImage}`).subscribe((datas: any) => {
              
              if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                const uploadId = datas.uploads[0].id;
  
                this.http.get(environment.apiUrl + `/uploads/get?id=${uploadId}`).subscribe((res: any) => {
                  if (res.status === "SUCCESS") {
  
                    // Check if base64 data is present and assign to profileImageUrl
                    // Check if base64 data is present
                  if (res.uploads && res.uploads.base64data) {
                    // Remove any extra prefix
                    const base64Data = res.uploads.base64data.trim();
                    const prefix = 'data:image/jpeg;base64,';
                    // Ensure the prefix is only added once
                    this.profileImageUrl = base64Data.startsWith(prefix)
                      ? base64Data
                      : prefix + base64Data;

                    } else {
                      console.error('No base64 data found in the image response.');
                    }
                  }
                });
              }
            });
          } else {
            // Handle empty or invalid imageinfo
            console.warn('No images found, using default profile image.');
            this.profileImageUrl = "../../assets/images/profile-man.svg"; // Default profile image
          }
        } catch (e) {
          console.error('Failed to parse imageinfo:', e);
          this.profileImageUrl = "../../assets/images/profile-man.svg"; // Fallback to default
        }
      }
    });
  }
  
  

saveNameChanges(){
  this.saveChanges();
}
saveGenderChanges(){
  this.saveChanges();
}


  saveChanges() {
    this.showPhoneOtpVerifyPopup = true;
    const requestBody: any = {
      updatedBy: this.userId,
      userType: "user",
      id: this.userId
    }; // Base request

    // Retrieve updated phone value from the form control
    const formValue = this.myGroup.value;

    // Add fields to requestBody if they have values
    if (formValue.firstname) requestBody.firstName = formValue.firstname;
    if (formValue.lastname) requestBody.lastName = formValue.lastname;
    if (formValue.gender) requestBody.gender = formValue.gender;
    if (formValue.email) requestBody.email = formValue.email;
    if (formValue.mobileInput) requestBody.phone = formValue.mobileInput;
    // Log the request body for debugging purposes
    console.log(requestBody, "requestBodyuser");
    if (this.myGroup.valid) {
      // Handle form submission here
      console.log('Mobile number is valid and saved!');
    } else {
      console.log('Mobile number is invalid');
    }
    this.http.post(environment.apiUrl + `/users/user-update`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        // Reload user data after successful update
        this.getUserDetails();
        this.isNameEditing = false;
        this.isEmailEditable = false;
        this.isGenderEditing = false;
        this.isPhoneEditing = false;


      }
    );
  }


  cancelPhoneEdit() {
    this.isPhoneEditing = false;
  }
  cancelNameEdit() {
    this.isNameEditing = false;
  }
  cancelGenderEdit() {
    this.isGenderEditing = false;
  }


  isEmailEditable: boolean = false;
  isPasswordEditable: boolean = false;

  email: string = 'example@email.com';
  currentPassword: string = '********';
  newPassword: string = '';
  showPasswordVerifyPopup = false;


  editEmail() {
    this.isEmailEditable = true;
    this.showOtpVerifyPopup = true;
    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      userType: 'user',
      // phone: '+91'+ this.user_details.phone,
      email:this.user_details.email
      // "phone":"+91" +""
    };
    this.http
      .post(
        environment.apiUrl + `/users/email-send-code-useremail-update`,
        requestBody,
        { responseType: 'json' }
      )
      .subscribe((data: any) => {
        if (data.status === 'SUCCESS') {
          console.log(data);
         
        }
      });
 
  }
 

  cancelEmailEdit() {
    this.isEmailEditable = false;
  }

  editPassword() {
    this.isPasswordEditable = true;
  }

  cancelPasswordEdit() {
    this.isPasswordEditable = false;
  }
  toggleVerifyPasswordPopup() {
    this.showPasswordVerifyPopup = !this.showPasswordVerifyPopup;
  }

  savePassword() {
    // Add logic to save the new password
    this.isPasswordEditable = false;
    this.showPasswordVerifyPopup = true;
  }


  showCardPaymentContent = false;
  showCardAddressContent = false;
  showNewAddress = false;

  toggleCardPaymentContent() {
    this.showCardPaymentContent = !this.showCardPaymentContent;
  }


  toggleCardAddressContent() {
    this.showCardAddressContent = !this.showCardAddressContent;
    if (this.showCardAddressContent) {
      this.showNewAddress = false;
    }
  }

  showNewAddressContainer() {
    this.showNewAddress = true;
    this.showCardAddressContent = false;
  }
  otpInput: string = ''; // Model for the OTP input
  passwordInput: string = '';
  isVerifyDisabled: boolean = true; // Button is disabled by default
  isVerifyPasswordDisabled: boolean = true;
  // Check if the OTP input is empty or not
  checkOtp(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.otpInput = inputElement.value;
    this.isVerifyDisabled = this.otpInput.trim() === '';
    console.log( this.otpInput," this.otpInput");
   
  }
 
  checkPassword() {
    this.isVerifyPasswordDisabled = this.passwordInput.trim() === '';

  }
  // Change border color when input is focused
  highlightBorder() {
    const inputOtpElement = document.querySelector('.otp-input');
    inputOtpElement?.classList.add('highlighted');
    const inputPasswordElement = document.querySelector('.password-input');
    inputPasswordElement?.classList.add('highlighted');
    const inputPhoneElement = document.querySelector('.phone-otp-input');
    inputPhoneElement?.classList.add('highlighted');
  }

  // Reset border color when input loses focus
  resetBorder() {
    const inputElement = document.querySelector('.otp-input');
    inputElement?.classList.remove('highlighted');
    const inputPasswordElement = document.querySelector('.password-input');
    inputPasswordElement?.classList.remove('highlighted');
  }
  verifyOtp() {
    if (!this.isVerifyDisabled) {
      // Proceed with OTP verification logic
      console.log('OTP Verified!');
      const requestBody = {
        updatedBy: this.userId,
        id: this.userId,
        userType: 'user',
        emailCode:this.otpInput,
        email:this.user_details.email
        // "phone":"+91" +""
      };
      this.http
        .post(
          environment.apiUrl + `/users/email-code-validator-useremail-update`,
          requestBody,
          { responseType: 'json' }
        )
        .subscribe((data: any) => {
          if (data.status === 'SUCCESS') {
            console.log(data);
           
          }
        });
    }
  }
 
  verifyPhoneOtp() {
    if (!this.isVerifyDisabled) {
      // Proceed with OTP verification logic
      console.log('Phone OTP Verified!');
      // this.http
      // .post(
      //   environment.apiUrl + `/users/phone-send-code-userphone-update`,
      //   requestBody,
      //   { responseType: 'json' }
      // )  
      const requestBody={
        updatedBy:this.userId,
        id:this.userId,
        phoneCode:"7995",
        phone:"+91"+ this.user_details.phone
      }
   
  // this.http.post(environment.apiUrl + `/users/phone-code-validator-userphone-update`, requestBody, { responseType: 'json' })
    }
  }
 
  verifyPassword() {
    if (!this.isVerifyPasswordDisabled) {
      console.log('Password Verified!');
    }
  }
  showOrderDetails: boolean = false;

  // Method to toggle the display state
  toggleOrderDetails(): void {
    this.showOrderDetails = !this.showOrderDetails;
  }

  showSignoutPopup = false;
  showOtpVerifyPopup = false;
  showPhoneOtpVerifyPopup = false;

  toggleSignoutPopup() {
    this.showSignoutPopup = !this.showSignoutPopup;
  }
  toggleVerifyOtpPopup() {
    this.showOtpVerifyPopup = !this.showOtpVerifyPopup;
  }
  toggleVerifyPhoneOtpPopup() {
    this.showPhoneOtpVerifyPopup = !this.showPhoneOtpVerifyPopup;
  }
  // Method to display the OTP popup
  showOtpPopup() {
    this.showOtpVerifyPopup = true;
  }




  onSectionChange(section: string): void {
    this.activeSection = section;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { section: section },
      queryParamsHandling: 'merge', // Preserve other query params if there are any
    });
  }

  removeWishList(productId: any, productVariationId: any) {
    const requestBody = {
      status: -1,
      createdBy: this.userId,
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId
    };

    this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        const index = this.wishlistitems_output.findIndex(
          (item: any) => item.productid === productId && item.productvariationid === productVariationId
        );

        if (index !== -1) {
          this.wishlistitems_output.splice(index, 1);
        }
        this.count = this.wishlistitems_output.length;
      })
  }

  cartList() {
    const requestBody = { userId: this.userId };

    this.http.post(environment.apiUrl + `/cart/list`, requestBody, { responseType: 'json' }).pipe(
      switchMap((data: any) => {
        if (data.status === 'SUCCESS') {
          this.cartItems = data.cart_output;
          this.count = data.count;

          const imageRequests = this.cartItems.map((item: any) => {
            return this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`).pipe(
              switchMap((datas: any) => {
                if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                  return this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`);
                } else {
                  return of({ status: 'ERROR' });
                }
              }),
              map((res: any) => {
                if (res.status === "SUCCESS") {
                  item.src = [res.uploads.base64data];
                } else {
                  item.src = [];
                }
                return item;
              })
            );
          });

          // Use forkJoin to wait for all image requests to complete
          return forkJoin(imageRequests).pipe(
            switchMap((itemsWithImages: any[]) => {
              // Get variations for all items
              const variationRequests = itemsWithImages.map((item: any) => {
                return this.variationApiCall(item.productid, item.productvariationid).pipe(
                  map((filteredVariations: any) => {
                    item.variations = filteredVariations;

                    const selectedVariation = item.variations.find((v: any) => v.id === item.productvariationid);
                    if (selectedVariation) {
                      item.selectedVariationId = selectedVariation.id;
                      item.selectedPrice = selectedVariation.sellingprice;
                      item.mrpPrice = selectedVariation.mrpprice;
                    }

                    return item;
                  })
                );
              });

              return forkJoin(variationRequests);
            })
          );
        } else {
          return of([]);
        }
      }),
      catchError(error => {
        console.error("Error in cartList API call:", error);
        return of([]);
      })
    ).subscribe(
      (updatedItems: any[]) => {
        this.cartItems = updatedItems;
        // this.getMrpPrice();
        // this.getSubTotalPrice();

      }
    );
  }

  variationApiCall(productid: any, productvariationid: any) {
    if (this.variationsCache.has(productid)) {
      return of(this.variationsCache.get(productid));
    }

    const requestBody = {
      productId: productid,
      productvariationId: productvariationid
    };

    return this.http.post(environment.apiUrl + `/products/list-productvariations`, requestBody, { responseType: 'json' }).pipe(
      map((data: any) => {
        if (data.status === 'SUCCESS') {

          const filteredVariations = data.productvariations_output.filter((variation: any) => variation.issale === true);
          this.variationsCache.set(productid, filteredVariations);
          this.variationsCacheSubject.next(this.variationsCache);
          return filteredVariations;
        } else {
          return [];
        }
      }),
      catchError(error => {
        console.error("Error in variationApiCall API call:", error);
        return of([]);
      })
    );
  }


  getPriceToShow(variation: any): number {
    if (variation.issubscribe && variation.issale) {
      if (variation.dataFromPreviousComponent === 'bestSelling') {
        this.priceToShow = variation.sellingprice; // Set priceToShow when dataFromPreviousComponent is 'bestSelling'
      } else if (variation.dataFromPreviousComponent === 'subscribeProduct') {
        this.priceToShow = variation.priceupto7days; // Set priceToShow when dataFromPreviousComponent is 'subscribe'
      } else {
        this.priceToShow = variation.priceupto7days; // Default to priceupto7days if both issubscribe and issale are true
      }
    } else if (variation.issubscribe) {
      this.priceToShow = variation.priceupto7days; // Set priceToShow if only issubscribe is true
    } else if (variation.issale) {
      this.priceToShow = variation.sellingprice; // Set priceToShow if only issale is true
    } else {
      this.priceToShow = variation.mrpprice; // Default to mrpPrice if neither issubscribe nor issale is true
    }
    return this.priceToShow; // Return the updated priceToShow
  }


  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity
    };

    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const product = this.productList.find(item => item.id === productId);
          if (product) {
            product.cartquantity = quantity;
          }

          this.addedToCart.set(productId, true);
          this.cartService.updateCartCount(data.newCartCount);

        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }
  triggerUpload() {
    this.renderer.selectRootElement(this.fileInput?.nativeElement).click();
  }

  handleLicenceChange(event: any): void {
    const file = event.target.files[0];
    let reader = new FileReader();

    // if(this.ifMobileApp() && reader["__zone_symbol__originalInstance"]) {
    //   reader = reader["__zone_symbol__originalInstance"];
    // }

    reader.onload = () => {
      const base64String = reader.result as string;
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        const maxWidth = 2400;
        const maxHeight = 1800;
        const thumbnailSize = 350;

        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }

        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);

          const compressedBase64String = canvas.toDataURL('image/jpeg', 0.7); // Adjust quality as needed

          // Create thumbnail
          const thumbnailCanvas = document.createElement('canvas');
          const thumbnailCtx: CanvasRenderingContext2D | null = thumbnailCanvas.getContext('2d');

          thumbnailCanvas.width = thumbnailSize;
          thumbnailCanvas.height = thumbnailSize;

          if (thumbnailCtx) {
            thumbnailCtx.drawImage(img, 0, 0, thumbnailSize, thumbnailSize);

            const thumbnailBase64String = thumbnailCanvas.toDataURL('image/jpeg', 0.7); // Adjust quality as needed
            const data = {

              firstname: this.user_details.firstname,
              lastname: this.user_details.lastname,
              gender: this.gender,
              profilepic: compressedBase64String,
              updatedBy: this.userId,
              userType: this.user_details.usertype,
              id: this.userId
            };

            this.http.post(environment.apiUrl + `/users/user-update`, data, { responseType: 'json' }).subscribe(async (data: any) => {
              if (data.status === "SUCCESS") {
                console.log(data, "data");

                //   this.licence = thumbnailBase64String;
                //   this.drivinglicenceUploadId = data.data.id;
                //   this.data.drivinglicenceId = data.data.id;
                //   this.merchantBookingForm.get('bookingDetailsJson')?.setValue(this.data);
                //   this.snackBar.open('Licence added successfully.', 'close', { duration: 3000, verticalPosition: 'top' });
                // } else {
                //   this.snackBar.open('Some error occurred while adding Image.', 'close', { duration: 3000, verticalPosition: 'top' });
              }
            });
          }
        }
      };

      img.src = base64String;
    };

    reader.readAsDataURL(file);
  }

}






