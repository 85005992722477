<div class="container mt-4 scroll_top">
  <div class="d-lg-block d-none  product-container">
    <div class="row product-img">
      <!-- Thumbnail Images -->
      <div class="col-md-1 px-0">
        <div id="myCarousel" class="carousel slide position-relative" data-ride="carousel">
          <div class="carousel-inner" role="listbox">
            <!-- Loop through product images and display only 3 at a time -->
            <div class="img item m-0" *ngFor="let image of productImages.src.slice(startIndex, endIndex); let i = index" (click)="selectImage(startIndex + i)">
              <img [src]="image" alt="Product Image" class=" border mx-0 mb-2"
              [ngClass]="{'border': selectedIndex !== (startIndex + i), 'active-thumbnail': selectedIndex === (startIndex + i)}" />
            </div>
          </div>
          <!-- Carousel controls -->
          <div *ngIf="startIndex > 0" class="carousel-control-prev position-absolute" >
            <img
            [src]="prevImageSrc"
            alt="Previous"
            class="m-0  carousel-img-scroll"
            (click)="prevImage()"
            (mouseover)="hoverImage('prev', true)"
            (mouseout)="hoverImage('prev', false)"
            width="35px" height="35px"
          >
          </div>
          <div *ngIf="endIndex < productImages.src.length"  class="carousel-control-next position-absolute" >
            <img
            [src]="nextImageSrc"
            alt="Next"
            class="m-0  carousel-img-scroll"
            (click)="nextImage()"
            (mouseover)="hoverImage('next', true)"
            (mouseout)="hoverImage('next', false)"
            width="35px" height="35px"
          >
          </div>
        </div>
      </div>
 
      <!-- Main Product Image -->
      <div class="col-md-5 mainimage-border p-0">
        <img [src]="mainImage" alt="Product Image" class="main-product-image" />
      </div>
 
      <!-- Product Details -->
      <div class="col-md-5 productDetails">
        <h4>{{ productDetails.title }}</h4>
 
        <div class="promotions mb-3">
          <span class="badge">50% offer</span>
          <!-- <span class="badge"
            [ngClass]="{ 'bg-success': productDetails.type === 'discount', 'bg-info': productDetails.type === 'offer' }">
            {{ productDetails.label }}{{productDetails.id}}
          </span> -->
        </div>
 
        <div class="description-container">
          <p class="description" [innerHTML]="sanitizedDescription"></p>
        </div>
        <a href="javascript:void(0)" class="show-more" (click)="toggleShowMore()">Show more</a>
        <div class="pricing mt-3">
          <span class="new-price">{{ priceToShow | currency: 'GBP' }}</span>
          <span class="original-price ms-2 text-decoration-line-through">
            <del>{{ mrpPrice | currency: 'GBP' }}</del>
          </span>
          <span class="ms-2 tax">(inclusive of all taxes)</span>
        </div>
 
 
        <div class="size-selection mt-1">
          <div class="sizes p-1">
            <button *ngFor="let variation of productDetails.variations" (click)="selectSize( variation)"
              [ngClass]="{'selected-btn': variation === selectedVariation}" class="btn size-btn me-2">
              {{ variation.variationvalue }} {{ variation.variationshortname }}
            </button>
 
          </div>
        </div>
 
        <!-- delivery schedule -->
        <div class="delivery-selection mt-3" *ngIf="isDeliverySchedule">
          <div>
            <h6>Delivery Schedule</h6>
            <div>
              <select name="" id="" class="form-select">
                <option value="">Select Schedule</option>
              </select>
            </div>
          </div>
         </div>
         <!-- schedule date -->
         <div class="date-selection mt-3 row" *ngIf="isDeliverySchedule">
          <div class="col">
            <h6>Starts on</h6>
            <div class="">
              <input type="date" name="" id="" class="form-control">
            </div>
          </div>
          <div class="col">
            <h6>Ends on</h6>
            <div class="">
              <input type="date" name="" id="" class="form-control">
            </div>
          </div>
        </div>
 <!-- only for food product -->
        <div *ngIf="FoodDeliverySlot" >
          <div class="mt-2">
            <span class="slot">*Next Delivery Slot will be : </span><span class="slot-date"> 18/09/2024 - 11:00 am to 2:00 pm</span>
          </div>
          <div class="mt-2">
            <label for="" class="food-instruction">Flavor Instruction</label>
            <div class="mt-1">
            <select name="" id="" class="flavor-add">
              <option value="">Add food flavor instruction</option>
              <option value="">Normal</option>
              <option value="">Extra Spicy</option>
              <option value="">Extra Masala</option>
            </select>
          </div>
 
          </div>
        </div>
        <button class="btn add-card btn-lg" (click)="Add_to_cart(productDetails.id, productDetails.selectedVariation, 1)" *ngIf="!productDetails.cartquantity || productDetails.cartquantity === 0"> <img src="../../assets/images/bag-add.svg" alt="" class="mx-2">Add to cart</button>
       
        <div class="d-flex align-items-center justify-content-around add-border cart" *ngIf="productDetails.cartquantity > 0">
          <div class="text-center d-flex   justify-content-around" style="width: 240px;">
          <button class="btn p-0" (click)="decreaseQuantity(productDetails.id, productDetails.selectedVariation, productDetails.cartid)"><img src="../../assets/images/decrease_img.svg"
                  alt="Decrease" style="width: 50px; height: 25px;"></button>
          <span class="px-2" style="font-size: 16px; margin-top: 5px;">{{ productDetails.cartquantity }}</span>
          <button class="btn p-0" (click)="increaseQuantity(productDetails.id, productDetails.selectedVariation)"><img src="../../assets/images/increase_img.svg"
                  alt="Increase" style="width: 50px; height: 25px;"></button>
                </div>
            </div>
 
      </div>
 
      <!-- Share and Like Icons -->
      <div class="col-md-1">
        <div class="d-flex justify-content-between align-items-center">
          <div class="me-4">
          <img src="../../assets/icon/share.svg" alt="share">
        </div>
        <div class="me-4">
          <img src="../../assets/images/like.svg" alt="like" class="icon" />
        </div>
        </div>
      </div>
    </div>
  </div>
  <!-- for mobile -->
  <div class="d-lg-none d-block">
    <div class="row">
      <!-- Main Product Image -->
      <div class="col-md-5">
        <div class=" main-product-border m-0">
        <img [src]="mainImage" alt="Product Image" class="main-product-image d-block p-0" />
      </div>
      </div>
      <!-- h -->
      <div class="col-md-2 mt-3">
        <div class="parent" #elemt
             (mousedown)="startDragging($event, false, elemt, 'mouse', child, '')"
             (mouseup)="stopDragging($event, false, 'mouse')"
             (mouseleave)="stopDragging($event, false, 'mouse')"
             (mousemove)="moveEvent($event, elemt, 'mouse', child)">
          <div class="child position-relative" #child>
            <!-- Loop through product images and display them -->
            <div class="img item ps-2 m-0"
                 *ngFor="let image of productImages.src; let i = index"
                 (click)="selectImage(startIndex + i)">
              <img [src]="image" alt="Product Image" class="my-0 border"
                   [ngClass]="{'active-thumbnail': selectedIndex === (startIndex + i)}" />
            </div>
          </div>
        </div>
      </div>
     
      <!-- Thumbnail Images -->
      <!--<div class="col-md-2 mt-3">
          <div class="parent" #elemt (mousedown)="startDragging($event, false, elemt,'mouse',child, '')"
          (mouseup)="stopDragging($event, false,'mouse')" (mouseleave)="stopDragging($event, false,'mouse')"
          (mousemove)="moveEvent($event, elemt, 'mouse', child)" >
          <div class="child d-flex  position-relative" #child>
              Loop through product images and display only 3 at a time
              <div class="img item  ps-2 m-0 " *ngFor="let image of productImages.src.slice(startIndex, endIndex); let i = index" (click)="selectImage(startIndex + i)">
                <img [src]="image" alt="Product Image" class="my-0 border"
                     [ngClass]="{'active-thumbnail': selectedIndex === (startIndex + i)}" />
              </div>
            </div>
          </div>
      </div> -->
      <!-- Product Details -->
      <div class="col-md-5 mt-3">
 <div class="d-flex justify-content-between gap-3 mt-2">
 
        <h4>{{ productDetails.title }}</h4>
 <!-- like & share -->
  <div class="d-flex justify-content-between w-25">
  <img src="../../assets/icon/share.svg" alt="share"  class="icon">
  <img src="../../assets/images/like.svg" alt="like" class="icon" />
</div>
</div>
        <div class="promotions mb-3">
          <span class="promo_off">50% offer</span>
        <span class="combo_off mx-1">Combo offer available</span>
          <!-- <span class="badge"
            [ngClass]="{ 'bg-success': productDetails.type === 'discount', 'bg-info': productDetails.type === 'offer' }">
            {{ productDetails.label }}
          </span> -->
        </div>
 
        <div class="description-container">
          <p class="description" [innerHTML]="sanitizedDescription"></p>
        </div>
        <a href="javascript:void(0)" class="show-more" (click)="toggleShowMore()">Show more</a>
 
        <div class="pricing mt-3">
          <span class="new-price">{{ priceToShow | currency: 'GBP' }}</span>
          <span class="original-price ms-2 text-decoration-line-through">
            <del>{{ mrpPrice | currency: 'GBP' }}</del>
          </span>
          <span class="ms-2 tax">(inclusive of all taxes)</span>
        </div>
       
 
        <div class="size-selection mt-3 mb-3">
          <div class="sizes p-1">
            <button  *ngFor="let variation of productDetails.variations" (click)="selectSize( variation)"
              [ngClass]="{ 'selected-btn': variation === selectedVariation }" class="btn size-btn me-2">
              {{ variation.variationvalue }} {{ variation.variationshortname }}
            </button>
 
          </div>
        </div>
    <!-- delivery schedule -->
    <div class="delivery-selection" *ngIf="isDeliverySchedule">
      <div>
        <h6>Delivery Schedule</h6>
        <div>
          <select name="" id="" class="form-select">
            <option value="">Select Schedule</option>
          </select>
        </div>
      </div>
     </div>
     <!-- schedule date -->
     <div class="date-selection mt-3 mb-3 row" *ngIf="isDeliverySchedule">
      <div class="col-6">
        <h6>Starts on</h6>
        <div class="">
          <input type="date" name="" id="" class="form-control">
        </div>
      </div>
      <div class="col-6">
        <h6>Ends on</h6>
        <div class="">
          <input type="date" name="" id="" class="form-control">
        </div>
      </div>
    </div>
   
        <button class="btn add-card btn-lg mt-1" (click)="Add_to_cart(productDetails.id, productDetails.selectedVariation, 1)"  *ngIf="!productDetails.cartquantity || productDetails.cartquantity === 0"> <img src="../../assets/images/bag-add.svg" alt="">Add to cart</button>
       
        <div class="d-flex align-items-center justify-content-around add-border cart" *ngIf="productDetails.cartquantity > 0">
          <div class="text-center d-flex justify-content-around" style="width: 240px;">
          <button class="btn p-0" (click)="decreaseQuantity(productDetails.id, productDetails.selectedVariation, productDetails.cartid)"><img src="../../assets/images/decrease_img.svg"
                  alt="Decrease" style="width: 20px; height: 20px; margin-left: 20px;"></button>
          <span class="px-2" style="font-size: 14px;">{{ productDetails.cartquantity }}</span>
          <button class="btn p-0" (click)="increaseQuantity(productDetails.id, productDetails.selectedVariation)"><img src="../../assets/images/increase_img.svg"
                  alt="Increase" style="width: 20px; height: 20px; margin-right: 20px;"></button>
      </div>
                </div>
 
 
      </div>
 
      <!-- Share and Like Icons -->
      <!-- <div class="col-md-2">
        <div class="d-flex justify-content-center gap-3">
          <img src="../../assets/icon/share.svg" alt="share">
          <img src="../../assets/images/like.svg" alt="like" class="icon" />
        </div>
      </div> -->
    </div>
  </div>
  <!-- Tabs for Description and Product Details -->
  <div class="row mt-4" #tabSection>
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item p-2">
        <a href="javascript:void(0)" class="description-link mx-2"
          [ngClass]="{'active-tab': activeTab === 'description', 'inactive-tab': activeTab !== 'description'}"
          (click)="setActiveTab('description')" role="tab">
          Description
        </a>
      </li>
      <li class="nav-item p-2">
        <a href="javascript:void(0)" class="product-link mx-2"
          [ngClass]="{'active-tab': activeTab === 'product-details', 'inactive-tab': activeTab !== 'product-details'}"
          (click)="setActiveTab('product-details')" role="tab">
          Product details
        </a>
      </li>
    </ul>
 
    <div class="tab-content">
      <div class="tab-pane" [class.active]="activeTab === 'description'" id="description" role="tabpanel">
        <p class="description mt-3" [innerHTML]="sanitizedDescription"></p>
      </div>
      <div class="tab-pane" [class.active]="activeTab === 'product-details'" id="product-details" role="tabpanel">
        <div class="mt-3">
          <div class="form-group row" *ngFor="let key of getKeys(additionaldetails)">
            <label class="col-6">{{ key | titlecase }}</label>
            <div class="col-6">: {{ additionaldetails[key] || 'N/A' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <!-- Product Related Offers -->
  <div class="row mb-3">
    <div>
      <h5>Product related offers</h5>
    </div>
    <div class=" d-lg-block d-none">
      <div class="row gap-4 m-auto">
        <div class="card col-md-4">
          <div class="d-flex mt-3 gap-3 text-center">
            <div class="combo-offer">Combo offer</div>
            <div class="extra-offer">Save extra 20%</div>
          </div>
          <div class="d-flex justify-content-around align-items-center gap-3 mt-4 px-4">
            <img src="../../assets/images/johnsonoil.svg" class="card-img" alt="">
            <div style="color: #B3B3B3; font-size: 29px;">+</div>
            <img src="../../assets/images/johnson.svg" class="card-img" alt="">
          </div>
          <div class="card-body px-4">
            <h6 class="card-title">Johnson's & Johnson's Baby Shampoo</h6>
            <div class="text-center">+</div>
            <h6 class="card-title">Johnson's & Johnson's Baby Soap</h6>
          </div>
          <div class="d-flex justify-content-between align-items-center card-btn mt-2 px-1 mb-2">
            <div class="price-section">
              <span>(£4.99 + £1.99)</span>
              <div class="total-price">£ 6.98</div>
            </div>
            <button class="btn btn-warning add-btn">Add Combo to Cart</button>
          </div>
        </div>
        <div class="card col-md-4">
          <div class="d-flex mt-3 gap-3 text-center">
            <div class="combo-offer">Combo offer</div>
            <div class="extra-offer">Save extra 20%</div>
          </div>
          <div class="d-flex justify-content-around align-items-center gap-3 mt-4 px-4">
            <img src="../../assets/images/johnsonoil.svg" class="card-img" alt="">
            <div style="color: #B3B3B3; font-size: 29px;">+</div>
            <img src="../../assets/images/johnson.svg" class="card-img" alt="">
          </div>
          <div class="card-body px-4">
            <h6 class="card-title">Johnson's & Johnson's Baby Shampoo</h6>
            <div class="text-center">+</div>
            <h6 class="card-title">Johnson's & Johnson's Baby Soap</h6>
          </div>
          <div class="d-flex justify-content-between align-items-center card-btn mt-2 px-1 mb-2">
            <div class="price-section">
              <span>(£4.99 + £1.99)</span>
              <div class="total-price">£ 6.98</div>
            </div>
            <button class="btn btn-warning add-btn">Add Combo to Cart</button>
          </div>
        </div>
        <div class="card col-md-4">
          <div class="d-flex mt-3 gap-3 text-center">
            <div class="combo-offer">Combo offer</div>
            <div class="extra-offer">Save extra 20%</div>
          </div>
          <div class="d-flex justify-content-around align-items-center gap-3 mt-4 px-4">
            <img src="../../assets/images/johnsonoil.svg" class="card-img" alt="">
            <div style="color: #B3B3B3; font-size: 29px;">+</div>
            <img src="../../assets/images/johnson.svg" class="card-img" alt="">
          </div>
          <div class="card-body px-4">
            <h6 class="card-title">Johnson's & Johnson's Baby Shampoo</h6>
            <div class="text-center">+</div>
            <h6 class="card-title">Johnson's & Johnson's Baby Soap</h6>
          </div>
          <div class="d-flex justify-content-between align-items-center card-btn mt-2 px-1 mb-2">
            <div class="price-section">
              <span>(£4.99 + £1.99)</span>
              <div class="total-price">£ 6.98</div>
            </div>
            <button class="btn btn-warning add-btn">Add Combo to Cart</button>
          </div>
        </div>
      </div>
    </div>
    <!-- for mobile -->
    <div class="gap-5 m-auto d-lg-none d-block">
      <p-carousel [value]="offers" [numVisible]="numVisible" [numScroll]="1" [responsiveOptions]="responsiveOptions"
        [showIndicators]="false" class="custom-carousel">
        <ng-template let-offer pTemplate="item">
          <div class="card">
            <div class="d-flex mt-3 gap-3 text-center">
              <div class="combo-offer">{{ offer.comboOffer }}</div>
              <div class="extra-offer">{{ offer.extraOffer }}</div>
            </div>
            <div class="d-flex justify-content-around align-items-center gap-3 mt-4 px-4">
              <img [src]="offer.image1" class="card-img" alt="">
              <div style="color: #B3B3B3; font-size: 29px;">+</div>
              <img [src]="offer.image2" class="card-img" alt="">
            </div>
            <div class="card-body px-4">
              <h6 class="card-title">{{ offer.title1 }}</h6>
              <div class="text-center">+</div>
              <h6 class="card-title">{{ offer.title2 }}</h6>
            </div>
            <div class="d-flex justify-content-between align-items-center card-btn mt-2 px-2 mb-2">
              <div class="price-section">
                <span>{{ offer.individualPrices }}</span>
                <div class="total-price">{{ offer.price }}</div>
              </div>
              <button class="btn btn-warning add-btn">Add Combo to Cart</button>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
 
  <!-- <div class="row mb-3">
      <div>
        <h5>Product related offers</h5>
      </div>
      <div class="row gap-5 m-1">
        <div class="card col-md-4" *ngFor="let offer of relatedOffers">
          <div class="d-flex mt-3 gap-3 text-center">
            <div class="combo-offer">Combo offer</div>
            <div class="extra-offer">Save extra 20%</div>
          </div>
          <div class="d-flex justify-content-around align-items-center gap-3 mt-4 px-4">
            <img [src]="offer.firstProductImage" class="card-img" alt="">
            <div style="color: #B3B3B3; font-size: 29px;">+</div>
            <img [src]="offer.secondProductImage" class="card-img" alt="">
          </div>
          <div class="card-body px-4">
            <h6 class="card-title">{{ offer.firstProductTitle }}</h6>
            <div class="text-center">+</div>
            <h6 class="card-title">{{ offer.secondProductTitle }}</h6>
          </div>
 
          <div class="d-flex justify-content-between align-items-center card-btn mt-2 px-1 mb-2">
            <div class="price-section">
              <span>({{ offer.firstProductPrice }} + {{ offer.secondProductPrice }})</span>
              <div class="total-price">£{{ offer.totalPrice }}</div>
            </div>
            <button class="btn btn-warning add-btn" (click)="addComboToCart(offer)">Add Combo to Cart</button>
          </div>
        </div>
      </div>
    </div> -->
    <app-play-store></app-play-store>
  </div>
 
 
 
 