import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCartComponent } from './add-cart/add-cart.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { HomeComponent } from './home/home.component';
import { ProductListPagesComponent } from './product-list-pages/product-list-pages.component';
import { ProductPagesComponent } from './product-pages/product-pages.component';
import { SubscribeListProductComponent } from './subscribe-list-product/subscribe-list-product.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';

 
const routes: Routes = [
  {
    path: '', redirectTo: '/home', pathMatch: 'full'
  },
  {
    path: 'home', component: HomeComponent,
  },
  {
    path: 'login', component: LoginComponent,
  },
  {
    path: 'register', component: RegisterComponent,
  },
  {
    path: 'product-list-pages', component: ProductListPagesComponent,
  },
  {
    path: 'product-pages/:id', component: ProductPagesComponent,
  },
  {
    path: 'add-cart', component: AddCartComponent,
  },
  {
    path: 'subscribe-list-pages', component: SubscribeListProductComponent,
  },
  { path: 'profile', component: ProfilePageComponent },

  
  {path:'404-not-found',component:ErrorPageComponent},
  { path: '**', redirectTo: '404-not-found' }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
