<div class="profile-container d-flex">
    <div class="profile-sidebar d-lg-block d-none">
        <div class="profile-header">
            <img [src]="profileImageUrl" alt="Profile Picture" class="profile-img">
            <div class="profile-info">
                <span class="greeting">Hello</span>
                <h6 class="username">{{user_details.firstname}}</h6>
            </div>
        </div>
        <div class="profile-section">
            <div class="menu-section">
                <h5 class="menu-header">Personal Information</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('profile')"
                        [class.active]="activeSection === 'profile'">
                        <img src="../../assets/icon/profile-icon.svg" alt="" class="icon-profile">
                        Profile
                    </li>
 
                    <li class="menu-item" (click)="onSectionChange('wishlist')"
                        [class.active]="activeSection === 'wishlist'">
                        <img src="../../assets/icon/wish-icon.svg" alt="" class="icon-profile">
                        My Wishlist
                        <span class="badge-num text-center">{{count}}</span>
 
                    </li>
                    <li class="menu-item" (click)="onSectionChange('orders')"
                        [class.active]="activeSection === 'orders'">
                        <img src="../../assets/icon/my-order.svg" alt="" class="icon-profile">
                        My Orders
                    </li>
                    <li class="menu-item" (click)="onSectionChange('subscriptions')"
                        [class.active]="activeSection === 'subscriptions'">
                        <img src="../../assets/icon/subscribt-product-icon.svg" alt="" class="icon-profile">
                        Subscribe Products
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Payment</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('wallet')"
                        [class.active]="activeSection === 'wallet'">
                        <img src="../../assets/icon/mywallet-icon.svg" alt="" class="icon-profile">
                        My Wallet
                    </li>
                    <li class="menu-item" (click)="onSectionChange('cards')" [class.active]="activeSection === 'cards'">
                        <img src="../../assets/icon/saved-icon.svg" alt="" class="icon-profile">
                        Saved Card Details
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Location</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('address')"
                        [class.active]="activeSection === 'address'">
                        <img src="../../assets/icon/addrress-icon.svg" alt="" class="icon-profile">
                        Manage Address
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Notifications</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('notifications')"
                        [class.active]="activeSection === 'notifications'">
                        <img src="../../assets/icon/notify-icon.svg" alt="" class="icon-profile">
                        Notification
                        <span class="badge-num text-center">2</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="signout">
            <a href="javascript:void(0)" class="signout-link" (click)="toggleSignoutPopup()">
                <img src="../../assets/icon/signout-icon.svg" alt="" class="icon-profile">
                Sign out
            </a>
        </div>
    </div>
    <div class="profile-content" *ngIf="activeSection === 'profile'" [formGroup]="myGroup">
        <div class="profile-picture-container">
            <div class="profile-picture">
                <img [src]="profileImageUrl" alt="Profile Picture" class="profile-man">
                <input type="file" accept="image/*" (change)="handleLicenceChange($event)" #fileInput class="d-none">
                <div (click)="triggerUpload()">
                    <img src="../../assets/icon/profile-edit-icon.svg" alt="Edit Profile" class="edit-icon">
                </div>
            </div>
        </div>
        <div class="user_name d-lg-block d-none">
            <div class="d-flex justify-content-between align-items-center">
                <label for="">First name / Last name</label>
                <a href="javascript:void(0)" class="edit-click" *ngIf="!isNameEditing"
                    (click)="toggleNameEdit()">Edit</a>
                <a href="javascript:void(0)" class="cancel-click" *ngIf="isNameEditing"
                    (click)="cancelNameEdit()">Cancel</a>
            </div>
            <div class="row d-lg-flex d-none">
                <div class="col-lg-5">
                    <input type="text" class="form-control name" placeholder="Enter the first name"
                        [disabled]="!isNameEditing" formControlName="firstname">
                </div>
                <div class="col-lg-5">
                    <input type="text" class="form-control name" placeholder="Enter the last name"
                        [disabled]="!isNameEditing" formControlName="lastname">
                </div>
                <div class="col-lg-2">
                    <input class="btn btn-primary save-name" type="button" value="Save" *ngIf="isNameEditing"
                        (click)="saveNameChanges()">
                </div>
            </div>
        </div>
        <!-- for mobile -->
        <div class="user-name-card d-lg-none d-block">
            <div class="p-4">
 
                <div class="d-flex justify-content-between align-items-center">
                    <label for="">First name / Last name</label>
                    <a href="javascript:void(0)" class="mobile-edit" *ngIf="!isNameEditing"
                        (click)="toggleNameEdit()">Edit</a>
                    <a href="javascript:void(0)" class="mobile-cancel" *ngIf="isNameEditing"
                        (click)="cancelNameEdit()">Cancel</a>
                </div>
                <div class="d-flex flex-column gap-3 d-lg-none">
                    <input type="text" class=" form-control name" placeholder="Enter the first name"
                        formControlName="firstname" [disabled]="!isNameEditing">
                    <input type="text" class=" form-control name" placeholder="Enter the last name"
                        formControlName="lastname" [disabled]="!isNameEditing">
                    <input class="btn btn-primary save-name" type="button" value="Save" *ngIf="isNameEditing"
                        (click)="saveNameChanges()">
                </div>
 
            </div>
        </div>
        <!-- gender -->
        <div class="user_gender d-lg-block d-none">
            <div class="d-flex justify-content-between align-items-center">
                <label for="">Gender</label>
                <a href="javascript:void(0)" class="edit-gender" *ngIf="!isGenderEditing"
                    (click)="toggleGenderEdit()">Edit</a>
                <a href="javascript:void(0)" class="cancel-gender" *ngIf="isGenderEditing"
                    (click)="cancelGenderEdit()">Cancel</a>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <input type="radio" name="gender" class="me-2" id="male" [value]="'Male'" formControlName="gender">
                    <label for="male">Male</label>
                </div>
                <div class="col">
                    <input type="radio" name="gender" class="me-2" id="female" [value]="'Female'"
                        formControlName="gender">
                    <label for="female">Female</label>
                </div>
                <div class="col" style="margin-top: -15px; padding-right: 0;">
                    <input class="btn btn-primary save-gender" type="button" value="Save" *ngIf="isGenderEditing"
                        (click)="saveGenderChanges()">
                </div>
            </div>
        </div>
        <!-- for mobile -->
        <div class="user_gender-card d-lg-none d-block">
            <div class="p-4">
                <div class="row">
                    <label for="" class="col">Gender</label>
                    <a href="javascript:void(0)" class="edit-gender col" *ngIf="!isGenderEditing"
                        (click)="toggleGenderEdit()">Edit</a>
                    <a href="javascript:void(0)" class="cancel-gender col" *ngIf="isGenderEditing"
                        (click)="cancelGenderEdit()">Cancel</a>
                </div>
                <div class="row mt-2">
                    <div class="col-4">
                        <input type="radio" name="gender" class="me-2" id="male" [value]="'male'"
                            formControlName="gender" [disabled]="!isGenderEditing">
                        <label for="male">Male</label>
                    </div>
                    <div class="col-8">
                        <input type="radio" name="gender" class="me-2" id="female" [value]="'female'"
                            formControlName="gender" [disabled]="!isGenderEditing">
                        <label for="female">Female</label>
                    </div>
 
                </div>
                <input class="btn btn-primary save-gender mt-3" type="button" value="Save" *ngIf="isGenderEditing"
                    (click)="saveGenderChanges()">
            </div>
        </div>
 
        <!-- mobile verify -->
        <div class="user_mobile d-lg-block d-none">
            <div class="row w-100">
                <div class="col-5 d-flex justify-content-between">
                    <label for="" *ngIf="!newMobileInput">Mobile number</label>
                    <label for="" *ngIf="newMobileInput">Old Mobile number</label>
                    <a href="javascript:void(0)" class="" *ngIf="isPhoneEditing"
                        (click)="togglePhoneEdit()">Edit</a>
                        <a href="javascript:void(0)" class="" *ngIf="!isPhoneEditing"
                        (click)="togglePhoneEdit()">Add </a>
                </div>
                <div class="col-5 d-flex justify-content-between new-mobile-label" *ngIf="newMobileInput">
                    <label for="">New Mobile number</label>
                    <a href="javascript:void(0)" (click)="toggleCancel()">Cancel</a>
                </div>
            </div>
 
            <div class="row mt-2">
                <!-- Old Mobile Number Input -->
                <div class="col-lg-5 d-flex">
                    <ngx-intl-tel-input class="d-block w-100 phone me-3"
                        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="false" [enablePlaceholder]="false" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                        [phoneValidation]="true" name="phone" formControlName="mobileInput"></ngx-intl-tel-input>
                </div>
                <!-- New Mobile Number Input -->
                <div class="col-lg-5" *ngIf="NewMobileInputOpen">
                    <ngx-intl-tel-input class="d-block w-100 phone me-3"
                        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="false" [enablePlaceholder]="false" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                        [phoneValidation]="true" formControlName="newMobileInput" name="phone"></ngx-intl-tel-input>
                </div>
                <div class="col-lg-2" *ngIf="NewMobileSavebtn">
                    <input class="btn btn-primary save-number" type="button" value="Save"
                        (click)="sendOtpForPhoneUpdate()"
                        [disabled]="!(myGroup.get('newMobileInput')?.value?.number?.length >= 10 || myGroup.get('mobileInput')?.value?.number?.length >= 10)">
                </div>
               
            </div>
 
            <!-- OTP Verify Popup -->
            <div class="phone-otp-overlay" *ngIf="showPhoneOtpVerifyPopup">
                <div class="phone-otp-verify-container">
                    <button class="close-btn" (click)="toggleVerifyPhoneOtpPopup(isOldPhone)">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="phone-otp-verify-content">
                        <form [formGroup]="myGroup"> <!-- Bind the form group to the form -->
                            <div class="phone-otp">
                                <div class="popup-message">
                                    <!-- Show message based on whether it's for old or new mobile -->
                                    {{ isOldPhone ? 'OTP is sent to your old number' : 'OTP is sent to your new number'
                                    }}
                                </div>
                                <div>
                                    <!-- Bind the otpCode control using formControlName -->
                                    <input type="text" placeholder="Enter OTP" formControlName="otpCode"
                                        class="phone-otp-input">
                                </div>
                                <div class="popup-actions">
                                    <button class="btn verify-btn" (click)="verifyOtpForPhoneUpdate()"
                                        [disabled]="!myGroup.get('otpCode')?.valid">
                                        Verify
                                    </button>
                                    <a class="resend-otp-btn" (click)="sendOtpForPhoneUpdate()">Resend OTP</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
 
        </div>
 
        <!-- for mobile -->
        <div class="user_mobile-card d-lg-none d-block p-4">
            <div class="d-flex justify-content-between align-items-center">
                <label for="" *ngIf="!newMobileInput">Mobile number</label>
                <label for="" *ngIf="newMobileInput">Old Mobile number</label>
                <a href="javascript:void(0)" class="edit-mobile" *ngIf="isPhoneEditing"
                    (click)="togglePhoneEdit()">Edit</a>
                    <a href="javascript:void(0)" class="edit-mobile" *ngIf="!isPhoneEditing"
                    (click)="togglePhoneEdit()">Add</a>
            </div>
            <div class="row mt-2">
                <!-- Old Mobile Number Input -->
                <div class="col-12 d-flex">
                    <ngx-intl-tel-input class="d-block w-100 phone me-3"
                        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="false" [enablePlaceholder]="false" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                        [phoneValidation]="true" name="phone" formControlName="mobileInput"></ngx-intl-tel-input>
                </div>
                <!-- New Mobile Number Input -->
                <div class="col-12 mt-3" *ngIf="NewMobileInputOpen">
                    <div class="d-flex justify-content-between">
                        <label for="">New Mobile number</label>
                        <a href="javascript:void(0)" class="cancel-click" (click)="toggleCancel()">Cancel</a>
                    </div>
                    <ngx-intl-tel-input class="d-block w-100 phone me-3"
                        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="false" [enablePlaceholder]="false" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                        [phoneValidation]="true" formControlName="newMobileInput" name="phone"></ngx-intl-tel-input>
                </div>
                <div class="col-12 mt-3" *ngIf="NewMobileSavebtn">
                    <input class="btn btn-primary save-number" type="button" value="Save"
                        (click)="sendOtpForPhoneUpdate()"
                        [disabled]="!(myGroup.get('newMobileInput')?.value?.number?.length >= 10 || myGroup.get('mobileInput')?.value?.number?.length >= 10)">
                </div>
            </div>
 
            <div class="phone-otp-overlay" *ngIf="showPhoneOtpVerifyPopup">
                <div class="phone-otp-verify-container">
                    <button class="close-btn" (click)="toggleVerifyPhoneOtpPopup(isOldPhone)">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="phone-otp-verify-content">
                        <form [formGroup]="myGroup"> <!-- Bind the form group to the form -->
                            <div class="phone-otp">
                                <div class="popup-message">
                                    {{ isOldPhone ? 'OTP is sent to your old number' : 'OTP is sent to your new number'
                                    }}
                                </div>
                                <div>
                                    <!-- Bind the otpCode control using formControlName -->
                                    <input type="text" placeholder="Enter OTP" formControlName="otpCode"
                                        class="phone-otp-input">
                                </div>
                                <div class="popup-actions">
                                    <button class="btn verify-btn" (click)="verifyOtpForPhoneUpdate()"
                                        [disabled]="!myGroup.get('otpCode')?.valid">Verify</button>
                                    <a class="resend-otp-btn" (click)="sendOtpForPhoneUpdate()">Resend OTP</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
 
        <!-- email verify -->
        <div class="user_email d-lg-block d-none">
            <div class="d-flex justify-content-between align-items-center">
                <label for="email">Email id</label>
                <a class="edit-mobile" href="javascript:void(0)" (click)="toggleEmailEdit()"
                    *ngIf="!isUserDetailEmail">Add Email</a>
                <a class="edit-mobile" href="javascript:void(0)" (click)="toggleEmailEdit()"
                    *ngIf="isUserDetailEmail">Edit Email</a>
                <!-- <a class="edit-mobile" href="javascript:void(0)"  (click)="toggleCancelEmail()">Cancel</a> -->
            </div>
            <div class="row">
                <div class="mt-2 col-lg-5">
                    <input type="email" class="form-control" placeholder="example@email.com" formControlName="oldEmail">
                </div>
                <div class="mt-2 col-lg-5" *ngIf="NewEmailInputOpen">
                    <input type="email" class="form-control" placeholder="New Email" formControlName="newEmail">
                </div>
                <div class="mt-2 col-lg-2">
                    <button class="btn btn-primary verify-email verify-phn-btn" (click)="sendOtpForEmailUpdate()"
                        *ngIf="verifyEmailBtn">
                        Verify
                    </button>
                </div>
            </div>
 
            <!-- OTP Verify Popup -->
            <div class="otp-overlay" *ngIf="showEmailOtpVerifyPopup">
                <div class="otp-verify-container">
                    <button class="close-btn" (click)="toggleVerifyEmailOtpPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="otp-verify-content">
                        <form [formGroup]="myGroup">
                            <div class="otp-email-input">
                                <div class="popup-message">
                                    {{ isOldEmail ? 'OTP is sent to your old email' : 'OTP is sent to your new email' }}
                                </div>
                                <div>
                                    <input type="text" placeholder="Enter OTP" formControlName="otpCode"
                                        class="phone-otp-input">
                                </div>
                                <div class="popup-actions">
                                    <button class="btn verify-btn" (click)="verifyOtpForEmailUpdate()"
                                        [disabled]="!myGroup.get('otpCode')?.valid">
                                        Verify
                                    </button>
                                    <a class="resend-otp-btn" (click)="sendOtpForEmailUpdate()">Resend OTP</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
 
        <!-- for mobile -->
        <div class="user_email-card d-lg-none d-block p-4">
            <div class="d-flex justify-content-between align-items-center">
                <label for="email">Email id</label>
                <a class="edit-mobile email-mobile" href="javascript:void(0)"
                    (click)="toggleEmailEdit()" *ngIf="!isUserDetailEmail">Add
                    Email</a>
                    <a class="edit-mobile email-mobile" href="javascript:void(0)"  *ngIf="isUserDetailEmail" (click)="toggleEmailEdit()">Edit Email</a>
                <a *ngIf="isEmailEditable" class="edit-mobile email-mobile" href="javascript:void(0)"
                    (click)="cancelEmailEdit()">Cancel</a>
           
            </div>
            <div class="row">
                <div class="mt-2 col-lg-5">
                    <input type="email" class="form-control" placeholder="example@email.com"
                        [disabled]="!isEmailEditable" formControlName="oldEmail">
                </div>
                <div class="mt-2 col-lg-5" *ngIf="NewEmailInputOpen">
                    <input type="email" class="form-control" placeholder="New Email" formControlName="newEmail">
                </div>
                <div class="mt-2 col-lg-2">
                    <button class="btn btn-primary verify-email verfiy-phn-btn" (click)="sendOtpForEmailUpdate()"
                    *ngIf="verifyEmailBtn">Verify</button>
                </div>
            </div>
            <div class="otp-overlay" *ngIf="showEmailOtpVerifyPopup">
                <div class="otp-verify-container">
                    <button class="close-btn" (click)="toggleVerifyEmailOtpPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
 
                    <div class="otp-verify-content">
                        <form [formGroup]="myGroup">
 
                        <div class="otp">
                            <div class="popup-message">  
                                {{ isOldEmail ? 'OTP is sent to your old email' : 'OTP is sent to your new email' }}
 
                            </div>
                            <div>
                                <input type="text" class="phone-otp-input"  placeholder="Enter OTP" formControlName="otpCode"
                                    (focus)="highlightBorder()" (blur)="resetBorder()">
                            </div>
                        </div>
                        <div class="popup-actions">
                            <button class="btn verify-btn" (click)="verifyOtpForEmailUpdate()"
                            [disabled]="!myGroup.get('otpCode')?.valid ">Verify</button>
                            <a class="resend-otp-btn" (click)="sendOtpForEmailUpdate()">Resend OTP</a>
                        </div>
                    </form>
 
                    </div>
 
                </div>
            </div>
        </div>
 
 
        <div class="user_password d-lg-block d-none mt-4">
            <div class="d-flex justify-content-between align-items-center">
                <label for="password">Password</label>
                <a *ngIf="!isChangePassword" class="edit-mobile" href="javascript:void(0)"
                    (click)="editPassword()">Change Password</a>
                <a *ngIf="isChangePassword" class="cancel-click" href="javascript:void(0)"
                    (click)="cancelPasswordEdit()">Cancel</a>
            </div>
            <div class="row">
                <div class="mt-2 col-lg-5">
                    <input type="password" class="form-control" [disabled]="!isChangePassword" placeholder="*********"
                        formControlName="currentPassword">
                </div>
                <div class="mt-2 col-lg-5" *ngIf="isChangePassword">
                    <input type="password" class="form-control" placeholder="Enter new Password"
                        formControlName="newPassword">
                    <small class="d-lg-block d-none">Password contains upper & lowercase, symbols, numbers, and 8
                        characters</small>
                </div>
                <div class="mt-2 col-lg-2" *ngIf="isChangePassword">
                    <button class="btn btn-primary save-password" (click)="savePassword()">Save</button>
                </div>
            </div>
            <!-- <div class="password-overlay" >
                <div class="password-verify-container">
                    <button class="close-btn" (click)="toggleVerifyPasswordPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="password-verify-content">
                        <div class="passwords">
                            <div class="popup-message">Enter your old password</div>
                            <div>
                                <input type="text" class="password-input" placeholder="Enter old password"
                                    [(ngModel)]="passwordInput" (input)="checkPassword()" (focus)="highlightBorder()"
                                    (blur)="resetBorder()">
                            </div>
                            <div class="forget-pswd">
                                <a class="forget-password-btn">Forget Password</a>
                            </div>
                        </div>
                        <div class="popup-actions verify-pswd">
                            <button class="btn verify-btn" [disabled]="isVerifyPasswordDisabled"
                                (click)="verifyPassword()">Verify</button>
                        </div>
                    </div>
                </div>
            </div> -->
             <!-- change password OTP Verify Popup -->
             <div class="otp-overlay" *ngIf="showChangePasswordOtpVerifyPopup">
                <div class="otp-verify-container">
                    <button class="close-btn" (click)="toggleVerifyChangePasswordOtpPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="otp-verify-content">
                        <form [formGroup]="myGroup">
                            <div class="otp-email-input">
                                <div class="popup-message">
                                    {{  'Enter the OTP '  }}
                                </div>
                                <div>
                                    <input type="text" placeholder="Enter OTP" formControlName="otpCode"
                                        class="phone-otp-input">
                                </div>
                                <div class="popup-actions">
                                    <button class="btn verify-btn" (click)="verifyOtpForChangePasswordUpdate()"
                                        [disabled]="!myGroup.get('otpCode')?.valid">
                                        Verify
                                    </button>
                                    <a class="resend-otp-btn" (click)="sendOtpForEmailUpdate()">Resend OTP</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- for mobile -->
        <div class="user_password-card d-lg-none d-block p-4">
            <div class="d-flex justify-content-between align-items-center">
                <label for="password">Password</label>
                <a *ngIf="!isPasswordEditable" class="edit-mobile email-mobile" href="javascript:void(0)"
                    (click)="editPassword()">Change Password</a>
                <a *ngIf="isPasswordEditable" class="cancel-click email-mobile" href="javascript:void(0)"
                    (click)="cancelPasswordEdit()">Cancel</a>
            </div>
            <div class="row">
                <div class="mt-2 col-lg-5">
                    <input type="password" class="form-control" [disabled]="!isPasswordEditable" placeholder="Password"
                        formControlName="currentPassword">
                </div>
                <div class="mt-2 col-lg-5" *ngIf="isPasswordEditable">
                    <input type="password" class="form-control" placeholder="Enter new Password"
                        formControlName="newPassword">
                    <small class="d-lg-block d-none">Password contains upper & lowercase, symbols, numbers, and 8
                        characters</small>
                </div>
                <div class="mt-2 col-lg-2" *ngIf="isPasswordEditable">
                    <button class="btn btn-primary save-password" (click)="savePassword()">Save</button>
                </div>
            </div>
            <div class="password-overlay" *ngIf="showPasswordVerifyPopup">
                <div class="password-verify-container">
                    <button class="close-btn" (click)="toggleVerifyPasswordPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="password-verify-content">
                        <div class="passwords">
                            <div class="popup-message">Enter your old password</div>
                            <div>
                                <input type="text" class="password-input" placeholder="Enter old password"
                                    [(ngModel)]="passwordInput" (input)="checkPassword()" (focus)="highlightBorder()"
                                    (blur)="resetBorder()">
                            </div>
                            <div class="forget-pswd">
                                <a class="forget-password-btn">Forget Password</a>
                            </div>
                        </div>
                        <div class="popup-actions verify-pswd">
                            <button class="btn verify-btn" [disabled]="isVerifyPasswordDisabled"
                                (click)="verifyPassword()">Verify</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wishlist-content" *ngIf="activeSection === 'wishlist'">
        <div>
            <h6>My Wishlist({{count}})</h6>
        </div>
        <hr>
        <div class="row wishlist-item align-items-center" *ngFor="let wishlistitems of wishlistitems_output ">
            <div class="col-3 col-lg-1">
                <!-- <img src="../../assets/images/johnsonoil.svg" alt="" class="wishlist-img"> -->
                <img [src]="wishlistitems.src?.[0]" alt="img" class=" image-src">
            </div>
            <div class="col-6 col-lg-6">
                <h6 class="product-name">{{wishlistitems.title}}</h6>
            </div>
            <div class="col-3 col-lg-2 text-end">
                <span class="product-price">£ {{getPriceToShow(wishlistitems)}}</span>
            </div>
            <div class="col-6 col-lg-2 add-cart mt-2 mt-md-0 text-center">
                <button class="d-lg-block d-none"
                    (click)="Add_to_cart(wishlistitems.productid,wishlistitems.productvariationid,1)">Add to
                    cart</button>
                <button class="d-lg-none d-block w-100 add-card-btn"
                    (click)="Add_to_cart(wishlistitems.productid,wishlistitems.productvariationid,1)">Add to
                    cart</button>
            </div>
            <div class="col-6 col-lg-1 text-center mt-2 mt-md-0 close-btns">
                <button class="btn d-lg-block d-none"><img src="../../assets/images/close.svg" alt=""
                        (click)="removeWishList(wishlistitems.productid,wishlistitems.productvariationid)"></button>
                <button class="btn remove-icon d-lg-none d-block w-100"><img src="../../assets/icon/remove-icon.svg"
                        alt="" class="me-2"
                        (click)="removeWishList(wishlistitems.productid,wishlistitems.productvariationid)">Remove</button>
            </div>
            <hr class="mt-1 d-lg-block d-none">
        </div>
        <!-- <hr>
        <div class="row wishlist-item align-items-center">
            <div class="col-3 col-md-1">
                <img src="../../assets/images/johnsonoil.svg" alt="" class="wishlist-img">
            </div>
            <div class="col-6 col-md-7">
                <h6 class="product-name">Johnson's & Johnson's Baby Shampoo</h6>
            </div>
            <div class="col-3 col-md-1 text-end">
                <span class="product-price">$2.99</span>
            </div>
            <div class="col-6 col-md-2 add-cart mt-2 mt-md-0 text-center">
                <button>Add to cart</button>
            </div>
            <div class="col-6 col-md-1 text-center mt-2 mt-md-0 close-btn">
                <button><img src="../../assets/icon/close.svg" alt=""></button>
            </div>
        </div> -->
    </div>
    <div class="order-content" *ngIf="activeSection === 'orders'">
        <div class="d-flex">
            <img src="../../assets/images/left_arrow.svg" alt="" class="me-2" *ngIf="showOrderDetails"
                (click)="toggleOrderDetails()" style="width: 15px; cursor: pointer;">
            <h6>My Orders</h6>
        </div>
        <hr>
        <div *ngIf="!showOrderDetails">
            <div class="order-item row align-items-center mb-4 mt-3" (click)="toggleOrderDetails()">
                <div class="col-2 col-md-1">
                    <img src="../../assets/images/johnsonoil.svg" alt="" class="order-img">
                </div>
                <div class="col-7 col-md-5 d-none d-lg-block">
                    <h6 class="product-name">Johnson's & Johnson's Baby Shampoo</h6>
                    <span class="variations">50 ml</span>
                </div>
                <div class="col-7 d-block d-lg-none mt-3">
                    <h6 class="product-name">Johnson's & Johnson's Baby Shampoo</h6>
                    <span class="product-amount">£2.99</span>
 
                </div>
                <div class="col-3 d-block d-lg-none variation-align">
                    <span class="variations">50 ml</span>
 
                </div>
                <div class="col-2 col-md-2 text-start text-md-center mt-2 mt-md-0 d-none d-lg-block">
                    <span class="product-name">£2.99</span>
                </div>
                <div class="d-block d-lg-none">
                    <div class="d-flex justify-content-end">
                        <span class="text-center delivery-card"><span class="status-dot yellow"></span> You item is
                            wait for delivery</span>
                    </div>
                </div>
                <div class=" col-12 col-md-4 text-center mt-2 mt-md-0 d-lg-block d-none">
                    <h6 class="product-name text-start"> <span class="status-dot yellow"></span>Delivery on Friday,
                        02.08.2024</h6>
                    <span class="m-auto d-block text-start delivery-card">You item is wait for delivery</span>
                </div>
            </div>
            <hr>
            <div class="order-item row align-items-center mb-4 mt-3" (click)="toggleOrderDetails()">
                <div class="col-2 col-md-1">
                    <img src="../../assets/images/food.svg" alt="" class="order-img">
                </div>
                <div class="col-7 col-md-5 d-none d-lg-block">
                    <h6 class="product-name">Fried Chicken</h6>
                    <span class="variations">10 pcs</span>
                </div>
                <div class="col-7 d-block d-lg-none mt-3">
                    <h6 class="product-name">Fried Chicken</h6>
                    <span class="product-amount">£2.99</span>
 
                </div>
                <div class="col-3 d-block d-lg-none variation-align">
                    <span class="variations">10 pcs</span>
 
                </div>
                <div class="col-2 col-md-2 text-start text-md-center mt-2 mt-md-0 d-none d-lg-block">
                    <span class="product-name">£2.99</span>
                </div>
                <div class="d-block d-lg-none">
                    <div class="d-flex justify-content-end">
                        <span class="text-center delivery-card"><span class="status-dot green"></span> You item is
                            delivery</span>
                    </div>
                </div>
                <div class=" col-12 col-md-4 text-center mt-2 mt-md-0 d-lg-block d-none">
                    <h6 class="product-name text-start"><span class="status-dot green"></span>Delivery on Friday,
                        02.08.2024</h6>
                    <span class="m-auto d-block text-start delivery-card">You item is delivery</span>
                </div>
            </div>
            <hr>
            <div class="order-item row align-items-center mb-4 mt-3" (click)="toggleOrderDetails()">
                <div class="col-2 col-md-1">
                    <img src="../../assets/images/food.svg" alt="" class="order-img">
                </div>
                <div class="col-7 col-md-5 d-none d-lg-block">
                    <h6 class="product-name">Fried Chicken</h6>
                    <span class="variations">10 pcs</span>
                </div>
                <div class="col-7 d-block d-lg-none mt-3">
                    <h6 class="product-name">Fried Chicken</h6>
                    <span class="product-amount">£2.99</span>
 
                </div>
                <div class="col-3 d-block d-lg-none variation-align">
                    <span class="variations">10 pcs</span>
 
                </div>
                <div class="col-2 col-md-2 text-start text-md-center mt-2 mt-md-0 d-none d-lg-block">
                    <span class="product-name">£2.99</span>
                </div>
                <div class="d-block d-lg-none">
                    <div class="d-flex justify-content-end">
                        <div class="cancel-delivery-details">
                        <span class="text-end delivery-card"><span class="status-dot red"></span>As Per request your
                            order items are canceled</span>
                        </div>
                    </div>
                </div>
                <div class=" col-12 col-md-4 text-center mt-2 mt-md-0 d-lg-block d-none">
                    <h6 class="product-name text-start"> <span class="status-dot red"></span> Order Cancelled</h6>
                    <span class="m-auto d-block text-center delivery-card">As Per request your order items are
                        canceled</span>
                </div>
            </div>
            <hr>
        </div>
        <div *ngIf="showOrderDetails" class="order-details">
            <div class="orders-content">
                <div class="row w-100 d-lg-flex d-none">
                    <!-- Product Details Section -->
 
                    <div class="col-8">
                        <h6 class="mb-4">Product Details</h6>
                        <div class="row">
                            <div class="col-2">
                                <img src="../../assets/images/johnsonoil.svg" alt="Product Image"
                                    class="product-detail-img">
                            </div>
                            <div class="col-10">
                                <div class="order-info">
                                    <span class="order-id">Order id: 1234567890</span>
                                    <h6 class="product-title">Johnson's & Johnson's Baby Shampoo</h6>
                                    <p class="product-size">50 ml</p>
                                    <span class="quantity-info">No of Quantity: 2</span>
                                    <p class="product-price">£5.98</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Order Status Section -->
                    <div class="col-4">
                        <h6 class="mb-4">Order Status</h6>
                        <div class="order-status">
                            <p><span class="status-dot green"></span> Order on Friday, 02.08.2024</p>
                            <p><span class="status-dot yellow"></span> Delivery on Friday, 02.08.2024
                                <small class="status-note text-center">You ordered item is waiting for delivery</small>
                            </p>
                            <button class="cancel-btn">Cancel Product</button>
                        </div>
                    </div>
                </div>
                <div class="d-block d-lg-none mobile-order-content">
                    <h6 class="mb-4 d-lg-none d-block">Product Details</h6>
                    <div class="row">
                        <div class="col-3">
                            <img src="../../assets/images/johnsonoil.svg" alt="" class="product-detail-mob-img">
                        </div>
                        <div class="col-9">
                            <div>
                                <div class="order-info">
                                    <span class="order-id">Order id: 1234567890</span>
                                    <h6 class="product-title text-truncate">Johnson's & Johnson's Baby Shampoo</h6>
                                    <p class="product-size">50 ml</p>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span class="quantity-info">No of Quantity: 2</span>
                                        <p class="product-price">£5.98</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <!-- Order Status Section -->
                    <div class="d-block d-lg-none">
                        <h6 class="mb-4">Order Status</h6>
                        <div class="order-status">
                            <p><span class="status-dot green"></span> Order on Friday, 02.08.2024</p>
                            <p><span class="status-dot yellow"></span> Delivery on Friday, 02.08.2024
                                <small class="status-note status-note-mobile text-center">You ordered item is waiting
                                    for delivery</small>
                            </p>
                            <button class="cancel-btn m-1">Cancel Product</button>
                        </div>
                    </div>
                </div>
 
            </div>
            <hr>
            <!-- Delivery Address Section -->
            <div class="address-info row">
                <div class="col-lg-8 col-12 mb-3" style="padding-right: 0;">
                    <h6>Delivery Address</h6>
                    <div class="order_details">
                        <span>Santhosh | 9876543210 | santhoshgmail.com</span>
                        <span>151, Sagotharar St, Chetti Kulam, Nagercoil, Tamil Nadu 629001</span>
                    </div>
                </div>
                <hr class="d-lg-none d-block">
                <div class="col-lg-4 col-12" style="padding-left: 0;">
                    <h6>Invoice</h6>
                    <button class="btn invoice-btn">Download invoice</button>
                </div>
            </div>
        </div>
    </div>
    <div class="subsciptions-content" *ngIf="activeSection === 'subscriptions'">
        <div class="d-flex">
            <img src="../../assets/images/left_arrow.svg" alt="" class="me-2" *ngIf="showSubscribeDetails"
                (click)="toggleSubscribeDetails()" style="width: 15px; cursor: pointer;">
            <h6 *ngIf=" !showPauseSubscribeDetails">My Subscriptions</h6>
            <h6 *ngIf=" showPauseSubscribeDetails">Play/Pause Subscription</h6>
 
        </div>
        <hr>
        <div *ngIf="!showSubscribeDetails">
            <div class="row align-items-center subscription-item d-lg-flex d-none" (click)="toggleSubscribeDetails()">
                <div class="col-lg-1">
                    <img src="../../assets/images/johnsonoil.svg" alt="" class="subsciption-img">
                </div>
                <div class="col-lg-5">
                    <h6 class="product-name">Anchor Unsalted Butter</h6>
                    <span class="variations">200 G</span>
                </div>
                <div class="col-lg-1 text-lg-center text-start">
                    <span class="product-price">£2.99</span>
                </div>
                <div class="col-lg-5">
                    <div class="d-flex align-items-center justify-content-lg-center justify-content-between gap-2">
                        <button class="btn btn-success active-btn">Active</button>
                        <h6 class="product-name text-center">Friday, 02.08.2024</h6>
                    </div>
                    <span class="m-auto d-block text-center delivery">Subsciption Starts</span>
                </div>
            </div>
            <hr class="d-lg-block d-none">
            <!-- for mobile -->
            <div class="subsciptions-content-card card d-lg-none d-block mb-3">
                <div class="row align-items-center subscription-item" (click)="toggleSubscribeDetails()">
                    <div class="col-2">
                        <img src="../../assets/images/johnsonoil.svg" alt="" class="subsciption-img">
                    </div>
                    <div class="col-5">
                        <h6 class="product-name">Anchor Unsalted Butter</h6>
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-success active-btn">Active</button>
                        </div>
                        <span class="product-price">£2.99</span>
                    </div>
                    <div class="col-5 text-end">
                        <span class="variations">200 G</span>
                        <div>
                            <!-- <div class="d-flex align-items-center justify-content-between gap-2"> -->
                            <h6 class="product-name mb-0 mt-1">Friday, 02.08.2024</h6>
                            <!-- </div> -->
                            <span class="m-auto d-block text-center delivery">Subsciption Starts</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <hr> -->
            <div class="row align-items-center subscription-item d-lg-flex d-none" (click)="toggleSubscribeDetails()">
                <div class="col-3 col-lg-1">
                    <img src="../../assets/images/johnsonoil.svg" alt="" class="subsciption-img">
                </div>
                <div class="col-7 col-lg-5">
                    <h6 class="product-name">Anchor Unsalted Butter</h6>
                    <span class="variations">200 G</span>
                </div>
                <div class="col-2 col-lg-1 text-lg-center text-start">
                    <span class="product-price">£2.99</span>
                </div>
                <div class="col-12 col-lg-5">
                    <div class="d-flex align-items-center justify-content-lg-center justify-content-between gap-2">
                        <button class="btn btn-danger pause-btn">Pause</button>
                        <h6 class="product-name">Friday, 02.08.2024</h6>
                    </div>
                    <span class="m-auto d-block text-center delivery">Subsciption Starts</span>
                </div>
            </div>
            <!-- for mobile -->
            <div class="subsciptions-content-card card d-lg-none d-block">
                <div class="row align-items-center subscription-item" (click)="toggleSubscribeDetails()">
                    <div class="col-2">
                        <img src="../../assets/images/johnsonoil.svg" alt="" class="subsciption-img">
                    </div>
                    <div class="col-5">
                        <h6 class="product-name">Anchor Unsalted Butter</h6>
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-danger pause-btn">Pause</button>
                        </div>
                        <span class="product-price">£2.99</span>
                    </div>
                    <div class="col-5 text-end">
                        <span class="variations">200 G</span>
                        <div>
                            <!-- <div class="d-flex align-items-center justify-content-between gap-2"> -->
                            <h6 class="product-name mb-0 mt-1">Friday, 02.08.2024</h6>
                            <!-- </div> -->
                            <span class="m-auto d-block text-center delivery">Subsciption Starts</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 
        <div *ngIf="showSubscribeDetails" class="subscribe-details">
            <div class="subscriptions-content">
                <div class="row w-100">
                    <!-- Product Details Section -->
                    <div class="col-12 col-lg-8">
                        <h6 class="mb-4">Product Details</h6>
                        <div class="row">
                            <div class="col-lg-2 col-3">
                                <img src="../../assets/images/johnsonoil.svg" alt="Product Image"
                                    class="product-detail-img">
                            </div>
                            <div class="col-lg-10 col-9">
                                <div class="order-info">
                                    <span class="order-id">Order id: 1234567890</span>
                                    <h6 class="product-title">Anchor Unsalted Butter</h6>
                                    <p class="product-size">200 G</p>
                                    <span class="quantity-info d-lg-block d-none">No of Quantity: 2</span>
                                    <p class="product-price d-lg-block d-none">£5.98</p>
                                    <div class="d-lg-none d-flex align-items-center justify-content-between">
                                        <span class="quantity-info">No of Quantity: 2</span>
                                        <p class="product-price">£5.98</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Subscription Status Section -->
                    <hr class="d-lg-none d-block">
                    <div class="col-lg-4 col-12">
                        <h6 class="mb-4">Subscription Status</h6>
                        <div class="order-status">
                            <div class="d-flex gap-3">
                                <button class="btn btn-success active-btn">Active</button>
                                <p>Friday, 02.08.2024
                                    <small class="status-note text-start">Subscription Starts</small>
                                </p>
                            </div>
                            <button class="cancel-btn d-lg-block d-none" (click)="togglePauseSubscribeDetails()">Pause
                                Subscription</button>
                            <button class="cancel-btn d-lg-block d-none" (click)="toggleSubscriptionPopup()">Cancel
                                Subscription</button>
                            <div class="d-lg-none d-block">
                                <div class="row subscribe-btn-container">
                                    <button class="btn col-6 subscribe-btn" (click)="togglePauseSubscribeDetails()">
                                        Pause
                                        Subscription
                                    </button>
                                    <button class="btn col-6 subscribe-btn" (click)="toggleSubscriptionPopup()">Cancel
                                        Subscription</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pause-subscription" *ngIf="showPauseSubscribeDetails">
                    <hr>
                    <div class="d-lg-flex d-block justify-content-center align-items-center gap-4">
                        <div class="d-flex align-items-center">
                            <input type="radio" class="radio-btn">
                            <label for="" class="subscription-pause-label">Pause subscription till when i resume</label>
                        </div>
                        <div class="d-flex align-items-center">
                            <input type="radio" class="radio-btn">
                            <label for="" class="subscription-pause-label">Pause subscription till the date</label>
                        </div>
                    </div>
                    <div class="d-lg-flex justify-content-center mt-3">
                        <div class="row">
                            <div class="col-lg-4 col-6">
                                <div>
                                    <label for="" class="form-label date-label">Starts on</label>
                                    <input type="date" class="form-control date-input" name="" id="">
                                </div>
                            </div>
                            <div class="col-lg-4 col-6">
                                <div>
                                    <label for="" class="form-label date-label">Ends on</label>
                                    <input type="date" class="form-control date-input" name="" id="">
                                </div>
                            </div>
                            <div class="col-lg-4 col-12 mt-4">
                                <button class="btn subscribe-pause-btn">
                                    Pause
                                    Subscription
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <!-- Delivery Address Section -->
            <div class="address-info">
                <h6>Delivery Address</h6>
                <div class="order_details">
                    <span>Santhosh | 9876543210 | santhoshgmail.com</span>
                    <span>151, Sagotharar St, Chetti Kulam, Nagercoil, Tamil Nadu 629001</span>
                </div>
            </div>
        </div>
        <div class="subscription-overlay" *ngIf="showsubscriptionPopup">
            <div class="subscription-popup-content">
                <div class="sign">
                    <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
                    <div class="popup-message">Do you want to cancel your subscription</div>
                </div>
                <hr class="hr-line">
                <div class="subscribe-popup-actions row">
                    <button class="btn col" (click)="toggleSubscriptionPopup()">No</button>
                    <button class="btn col">Yes</button>
                </div>
            </div>
        </div>
    </div>
    <div class="wallet-content" *ngIf="activeSection === 'wallet'">
        <div>
            <h6>My Wallet</h6>
        </div>
        <hr>
        <div class="row wallet-row" *ngIf="!showSubscribePaymentDetails">
            <div class="col-lg-6 col-md-12 mb-3">
                <div class="d-flex wallet-card-container">
                    <div class="card-container">
                    </div>
                    <div class="wallet-card p-3">
                        <div class="wallet-card-header">
                            <h6>Available Balance</h6>
                        </div>
                        <div class="wallet-card-body">
                            <div class="balance-section">
                                <div class="balance-details">
                                    <span class="currency-symbol">£</span>
                                    <span class="wallet-amount">2.99</span>
                                </div>
                                <div class="wallet-icon">
                                    <img src="../../assets/images/wallet-card-img.svg" alt="Wallet Icon">
                                </div>
                            </div>
                            <hr class="wallet-line">
                            <a href="javascript:void(0)" class="add-balance-link"
                                (click)="toggleShowSubscribePaymentDetails()">
                                <img src="../../assets/images/wallet-add-btn.svg" alt="Add Icon" class="add-icon"> Add
                                wallet balance
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 mb-3">
                <div class="transactions-card p-3">
                    <div class="transactions-card-header d-flex justify-content-between align-items-center">
                        <h6>Your Transactions</h6>
                        <select name="" id="" class="timeline-select">
                            <option value="">Time Line</option>
                            <option value="">Last 1 month</option>
                            <option value="">Last 2 months</option>
                            <option value="">Last 3 months</option>
                            <option value="">Old Transaction</option>
                        </select>
                    </div>
                    <hr>
                    <ul class="transactions-list">
                        <li class="transaction-item">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="transaction-details">
                                    <h6>Transaction successful</h6>
                                    <span class="payment-method">Card payment</span>
                                    <span class="transaction-date">Monday, 29.07.2024</span>
                                </div>
                                <div class="transaction-amount d-flex align-items-center">
                                    <span class="amount-text text-success me-4">£ 5.98</span>
                                    <img src="../../assets/icon/right-arrow.svg" alt="Arrow" class="right-arrow-icon"
                                        (click)="toogleSelectedPaymentDetailsMethod()">
                                </div>
                            </div>
                            <!-- view payment details  -->
                            <div class="payment-details-content mt-2 p-lg-3" *ngIf="selectedPaymentDetailsMethod">
                                <div class="row">
                                    <div class="col-3 col-lg-3">
                                        <p>Paid using</p>
                                    </div>
                                    <div class="col-2 col-lg-2">
                                        <p>HDFC</p>
                                    </div>
                                    <div class="col-3 col-lg-3">
                                        <p>Credit Card</p>
                                    </div>
                                    <div class="col-4 col-lg-4">
                                        <p>xxxx xxxx xxxx 9876</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3 col-lg-3">
                                        <p>Order id</p>
                                    </div>
                                    <div class="col-9 col-lg-9">
                                        <p>407-024444-8989898</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3 col-lg-3">
                                        <p>Item</p>
                                    </div>
                                    <div class="col-4 col-lg-4">
                                        <p>Johnson</p>
                                    </div>
                                    <div class="col-5 col-lg-5">
                                        <p class="text-end"><a href="" class="text-decoration-none">Show order
                                                details</a></p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <hr>
                        <li class="transaction-item d-flex justify-content-between align-items-center">
                            <div class="transaction-details">
                                <h6>Transaction unsuccessful</h6>
                                <span class="payment-method">Wallet payment</span>
                                <span class="transaction-date">Monday, 29.07.2024</span>
                            </div>
                            <div class="transaction-amount d-flex align-items-center">
                                <span class="amount-text text-danger me-4">£ 5.98</span>
                                <img src="../../assets/icon/right-arrow.svg" alt="Arrow" class="right-arrow-icon">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="wallet-balance-container" *ngIf="showSubscribePaymentDetails">
            <div>
                <div>
                    <input type="radio" (click)="toggleShowPaymentDetails()">
                    <label for="">Card Payment</label>
                </div>
                <div class="card_payment_content m-4" *ngIf="selectedPaymentMethod">
                    <!-- Card Payment Content -->
                    <div class="card_details">
                        <label>Saved Card details</label>
                        <div class="card mt-2">
                            <div class="card-body d-flex align-items-center justify-content-between">
                                <input type="radio" name="savedCard" class="me-3">
                                <div class="d-block">
                                    <span>Santhosh</span><br>
                                    <span>HDFC Bank</span>
                                </div>
                                <div>XXXXXX0987 9087</div>
                                <div>Debit card</div>
                                <div>
                                    <img src="../../assets/images/visa_bank.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
 
                    <div class="newcard_details mt-4">
                        <label>Pay with new card</label>
                        <div class="card mt-2">
                            <div class="card-body">
                                <div>
                                    <label for="cardHolderName" class="form-label">Card Holder name</label>
                                    <input type="text" id="cardHolderName" class="form-control content"
                                        placeholder="Card holder name">
                                </div>
                                <div class="mt-2">
                                    <label for="bankName" class="form-label">Bank name</label>
                                    <select id="bankName" class="form-select content">
                                        <option value=""></option>
                                    </select>
                                </div>
                                <div class="input-with-icon mt-2">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <label for="cardDetails" class="form-label">Card details</label>
                                        <div class="row">
                                            <img src="../../assets/images/visa_bank.svg" alt="" id="cardDetailsImg"
                                                class="col-4">
                                            <img src="../../assets/images/mastercard-img.svg" alt="" class="col-4">
                                            <img src="../../assets/images/discover-img.svg" alt="" class="col-4">
                                        </div>
                                    </div>
                                    <div class="input-container">
                                        <input type="text" id="cardDetails" class="form-control content"
                                            placeholder="1234 1234 1234 9876">
                                        <!-- <img src="../../assets/images/visa_bank.svg" alt="" id="cardDetailsImg"> -->
                                    </div>
                                </div>
 
                                <div class="mt-2">
                                    <label class="form-label">Card type</label>
                                    <div class="form-check-group card_detail">
                                        <div class="form-check card_type">
                                            <input type="radio" class="form-check-input content" name="cardType"
                                                id="debitCard">
                                            <label for="debitCard" class="form-check-label">Debit Card</label>
                                        </div>
                                        <div class="form-check card_type">
                                            <input type="radio" class="form-check-input content" name="cardType"
                                                id="creditCard">
                                            <label for="creditCard" class="form-check-label">Credit Card</label>
                                        </div>
                                    </div>
                                </div>
 
                                <div class="row mt-2 mb-2">
                                    <div class="col">
                                        <label for="expiryDate" class="form-label">Expiry date</label>
                                        <input type="date" id="expiryDate" class="form-control content">
                                    </div>
                                    <div class="col cvc-input-container">
                                        <label for="cvc" class="form-label">CVC</label>
                                        <input type="text" id="cvc" class="form-control content">
                                        <img src="../../assets/images/cvc_number_img.svg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-check mt-4">
                                    <input type="checkbox" id="saveCardDetails" class="form-check-input">
                                    <label for="saveCardDetails" class="form-check-label">Save your card details for
                                        later
                                        payment</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mt-4 text-end">
                                    <button class="btn payment-btn">Make Payment</button>
                                </div>
                            </div>
                        </div>
 
                    </div>
                </div>
            </div>
            <hr>
            <div>
                <input type="radio">
                <label for="">Pay with link</label>
            </div>
        </div>
    </div>
    <div class="card-content" *ngIf="activeSection === 'cards'">
        <div class="d-flex align-items-center justify-content-between" *ngIf="!showCardPaymentContent">
            <h6>Card details</h6>
            <button class="btn btn-outline-secondary add-address-btn" (click)="toggleCardPaymentContent()">+ Add Card
                details</button>
        </div>
        <div class="d-flex align-items-center justify-content-between" *ngIf="showCardPaymentContent">
            <h6><img src="../../assets/images/left_arrow.svg" alt="backIcon">Add new card details</h6>
        </div>
        <hr>
        <div *ngIf="!showCardPaymentContent">
            <div class="row d-lg-flex d-none">
                <div class="col-2">
                    <div>
                        <h6 class="card-detail-content">HDFC</h6>
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <h6 class="card-detail-content">XXXX XXXX XXXX 9876</h6>
                    </div>
                </div>
                <div class="col-2">
                    <div>
                        <h6 class="card-detail-content">Debit Card</h6>
                    </div>
                </div>
                <div class="col-2">
                    <div>
                        <img src="../../assets/images/visa_bank.svg" alt="">
                    </div>
                </div>
                <div class="col-1">
                    <div>
                        <a href="javascript:void(0)" class="edit-link" (click)="toggleCardPaymentContent()">Edit</a>
                    </div>
                </div>
                <div class="col-1">
                    <div>
                        <a href="javascript:void(0)" class="delete-link" (click)="toggleCardDeletePopup()">Delete</a>
                    </div>
                </div>
            </div>
            <!-- for mobile -->
            <div class="d-lg-none d-block">
                <div class="card-details-mobile p-2">
                    <div class="row mt-2">
                        <div class="col-6">
                            <div class="d-flex">
                                <h6 class="card-detail-content me-2">HDFC</h6>
                                <h6 class="card-detail-content">Debit Card</h6>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="text-end">
                                <img src="../../assets/images/visa_bank.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <div>
                                <h6 class="card-detail-content">XXXX XXXX XXXX 9876</h6>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-6">
                            <div>
                                <a href="javascript:void(0)" class="edit-link text-center d-block"
                                    (click)="toggleCardPaymentContent()">Edit</a>
                            </div>
                        </div>
                        <div class="col-6">
                            <div>
                                <a href="javascript:void(0)" class="delete-link text-center d-block"
                                    (click)="toggleCardDeletePopup()">Delete</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-delete-overlay" *ngIf="showCardDeletePopup">
                <div class="card-delete-content">
                    <div class="sign">
                        <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
                        <div class="popup-message">Do you want to delete your Card details</div>
                    </div>
                    <hr class="hr-line">
                    <div class="card-popup-actions row">
                        <button class="btn col" (click)="toggleCardDeletePopup()">No</button>
                        <button class="btn col">Yes</button>
                    </div>
                </div>
            </div>
            <hr class="d-lg-block d-none">
            <div class="row d-lg-flex d-none">
                <div class="col-2">
                    <div>
                        <h6 class="card-detail-content">HDFC</h6>
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <h6 class="card-detail-content">XXXX XXXX XXXX 9876</h6>
                    </div>
                </div>
                <div class="col-2">
                    <div>
                        <h6 class="card-detail-content">Debit Card</h6>
                    </div>
                </div>
                <div class="col-2">
                    <div>
                        <img src="../../assets/images/visa_bank.svg" alt="">
                    </div>
                </div>
                <div class="col-1">
                    <div>
                        <a href="" class="edit-link">Edit</a>
                    </div>
                </div>
                <div class="col-1">
                    <div>
                        <a href="" class="delete-link">Delete</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- add card btn click show content -->
        <div class="row align-items-center" *ngIf="showCardPaymentContent">
            <div class="card_payment_content">
                <div class="newcard_details">
                    <div class="mt-2">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <label for="cardHolderName" class="form-label">Card Holder name</label>
                                    <input type="text" id="cardHolderName" class="form-control content"
                                        placeholder="Card holder name">
                                </div>
                                <div class="col">
                                    <label for="cardHolderEmail" class="form-label">Email id</label>
                                    <input type="email" id="cardHolderEmail" class="form-control content"
                                        placeholder="Email id">
                                </div>
                            </div>
                            <div class="mt-2">
                                <label for="bankName" class="form-label">Bank name</label>
                                <select id="bankName" class="form-select content">
                                    <option value=""></option>
                                </select>
                            </div>
                            <div class="input-with-icon mt-2">
                                <label for="cardDetails" class="form-label">Card details</label>
                                <div class="input-container">
                                    <input type="text" id="cardDetails" class="form-control content"
                                        placeholder="1234 1234 1234 9876">
                                    <img src="../../assets/images/visa_bank.svg" alt="" id="cardDetailsImg">
                                </div>
                            </div>
 
                            <div class="mt-2">
                                <label class="form-label">Card type</label>
                                <div class="form-check-group card_detail">
                                    <div class="form-check card_type">
                                        <input type="radio" class="form-check-input content" name="cardType"
                                            id="debitCard">
                                        <label for="debitCard" class="form-check-label">Debit Card</label>
                                    </div>
                                    <div class="form-check card_type">
                                        <input type="radio" class="form-check-input content" name="cardType"
                                            id="creditCard">
                                        <label for="creditCard" class="form-check-label">Credit Card</label>
                                    </div>
                                </div>
                            </div>
 
                            <div class="row mt-2 mb-2">
                                <div class="col">
                                    <label for="expiryDate" class="form-label">Expiry date</label>
                                    <input type="date" id="expiryDate" class="form-control content">
                                </div>
                                <div class="col cvc-input-container">
                                    <label for="cvc" class="form-label">CVC</label>
                                    <input type="text" id="cvc" class="form-control content">
                                    <img src="../../assets/images/cvc_number_img.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-check mt-4">
                        <button class="btn save_btn">Save card</button>
                    </div>
                </div>
            </div>
 
        </div>
    </div>
    <div class="address-content" *ngIf="activeSection === 'address'">
        <div class="d-flex align-items-center justify-content-between"
            *ngIf="!showCardAddressContent && !showNewAddress">
            <h6>Address</h6>
            <button class="btn btn-outline-secondary add-address-btn" (click)="toggleCardAddressContent()">+ Add
                Address</button>
        </div>
        <div class="d-flex align-items-center justify-content-between" *ngIf="showCardAddressContent || showNewAddress">
            <h6>
                <img src="../../assets/images/left_arrow.svg" (click)="GoBack()" alt="backIcon" style="cursor:pointer">
                {{ isEditMode ? 'Edit Address' : 'Add New Address' }}
            </h6>
        </div>
 
        <hr>
        <!-- for mobile -->
        <div class="delivery-card-mobile d-lg-none d-block" *ngIf="!showCardAddressContent && !showNewAddress">
            <ul class="card mt-2 p-0" *ngFor="let address of UserAddressList">
                <li class="list-unstyled border-bottom border-top p-2">
                    <div class="d-flex">
                        <label class="delivery_details">
                            {{address.firstname}}
                            <!-- Conditionally display phone and email if they exist -->
                            <span *ngIf="address.phone"> | {{address.phone}}</span>
                            <span *ngIf="address.email"> | {{address.email}}</span>
                        </label>
                        <small class="work p-1 badge badge-secondary work-badge">{{address.type}}</small>
                    </div>
                    <p class="delivery_address">{{address.deliveryarea}}</p>
                    <hr>
                    <div class="
                  row ">
                        <a (click)="UserEditAddress(address.id)" class="col text-center edit-link">Edit</a>
                        <a class="col text-center delete-link">Cancel</a>
                    </div>
                </li>
 
            </ul>
        </div>
        <!-- for lap -->
        <div class="row align-items-center d-lg-flex d-none" *ngIf="!showCardAddressContent && !showNewAddress">
            <ul class="mt-2 p-0" *ngFor="let address of UserAddressList">
                <li class="list-unstyled border-bottom p-2">
                    <div class="d-flex justify-content-between">
                        <div>
                            <label class="delivery_details">
                                {{address.firstname}}
                                <span *ngIf="address.phone"> | {{address.phone}}</span>
                                <span *ngIf="address.email"> | {{address.email}}</span>
                            </label>
                            <small class="work p-1 badge-secondary badge work-badge">{{address.type}}</small>
                        </div>
                        <div>
                            <a (click)="UserEditAddress(address.id)" class="me-2 edit-link">Edit</a>
                            <a class="delete-link" (click)="deleteAddressConfirmation(address)">Delete</a>
                        </div>
                    </div>
                    <p class="delivery_address">{{address.deliveryarea}} , {{address.postalcode}}</p>
                </li>
            </ul>
        </div>
 
        <!-- Delete Confirmation Popup -->
        <div class="card-delete-overlay" *ngIf="showDeletePopup">
            <div class="card-delete-content">
                <div class="sign">
                    <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
                    <div class="popup-message">Do you want to delete your address details?</div>
                </div>
                <hr class="hr-line">
                <div class="card-popup-actions row">
                    <button class="btn col" (click)="cancelDelete()">No</button> <!-- Cancel Delete -->
                    <button class="btn col" (click)="confirmDeleteAddress()">Yes</button> <!-- Confirm Delete -->
                </div>
            </div>
        </div>
 
        <div class="map-container" *ngIf="isMapVisible && showCardAddressContent">
 
            <div id="map-content" class=" position-relative">
                <input id="pac-input" type="text" placeholder="Search for places..."
                    class="controls pac-target-input  position-absolute" autocomplete="off" />
                <div id="map"></div>
            </div>
 
            <div>
                <label for="deliveryArea" class="form-label">Delivery area</label>
                <div class="input-with-link">
                    <input type="text" class="form-control" id="deliveryArea" placeholder="Delivery area"
                        [value]="selectedAddress" readonly>
 
                </div>
            </div>
            <div class="btn-container mt-3">
                <button class="btn next-btn" (click)="showNewAddressContainer()">Next <img
                        src="../../assets/images/next_rightarrow.svg" class="next-btn-img" alt="nextIcon"></button>
            </div>
        </div>
 
 
        <!-- Add new address content -->
        <div class="new-address-container" *ngIf="showNewAddress">
            <div class="new-address-content">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">First name</label>
                        <input type="text" class="form-control" placeholder="Enter your First name"
                            [(ngModel)]="firstName">
                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Last name</label>
                        <input type="text" class="form-control" placeholder="Enter your Last name"
                            [(ngModel)]="lastName">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Mobile number</label>
                        <ngx-intl-tel-input
                        class="d-block w-100 phone"
                        [preferredCountries]="[CountryISO.India, CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectedCountryISO]="selectedCountryISO"
                        [(ngModel)]="phone">
                      </ngx-intl-tel-input>
                     
 
                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Email id</label>
                        <input type="text" class="form-control" placeholder="Enter your Email id" [(ngModel)]="email">
                    </div>
                </div>
                <div>
                    <label for="deliveryArea" class="form-label">Delivery area</label>
                    <input type="text" class="form-control" id="deliveryArea" placeholder="Delivery area"
                        [ngModel]="isEditMode ? deliveryArea : selectedAddress"
                        (ngModelChange)="isEditMode ? deliveryArea = $event : selectedAddress = $event">
                </div>
 
                <div>
                    <label for="" class="form-label">Address</label>
                    <input type="text" class="form-control" [(ngModel)]="address">
                </div>
                <div class="row">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Floor/Door no (optional)</label>
                        <input type="text" class="form-control" [(ngModel)]="doorNo">
                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Landmark</label>
                        <input type="text" class="form-control" [(ngModel)]="landmark">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Postal code</label>
                        <input type="text" class="form-control" placeholder="Enter your Postal code"
                            [(ngModel)]="postalCode">
                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Address type</label>
                        <div class="row">
                            <!-- Home -->
                            <div class="col-md-4 col-sm-4 col-4">
                                <div class="card home-cards" (click)="setAddressType('home')"
                                    [ngClass]="{'selected-address': selectedAddressType === 'home'}">
                                    <div class="card-body home-card-body">Home</div>
                                </div>
                            </div>
 
                            <!-- Office -->
                            <div class="col-md-4 col-sm-4 col-4">
                                <div class="card home-cards" (click)="setAddressType('office')"
                                    [ngClass]="{'selected-address': selectedAddressType === 'office'}">
                                    <div class="card-body home-card-body">Office</div>
                                </div>
                            </div>
 
                            <!-- Others -->
                            <div class="col-md-4 col-sm-4 col-4">
                                <div class="card home-cards" (click)="setAddressType('others')"
                                    [ngClass]="{'selected-address': selectedAddressType === 'others'}">
                                    <div class="card-body home-card-body">Others</div>
                                </div>
                            </div>
                        </div>
                    </div>
 
 
                </div>
                <div class="mt-3">
                    <button class="btn next-save-btn" (click)="saveAddress()">Save Address</button>
                </div>
            </div>
        </div>
 
    </div>
    <div class="notification-content" *ngIf="activeSection === 'notifications'">
        <h6>Notifications</h6>
        <hr>
        <div class="d-lg-block d-none">
            <div class="notification-item d-flex align-items-center">
                <div>
                    <img src="../../assets/images/johnsonoil.svg" alt="Order Image" class="order-img">
                </div>
                <div class="d-block flex-grow-1 mx-3">
                    <p class="mb-1 notification-text">Your order has been received successfully. We will reach out to you
                        soon</p>
                    <p class="mb-0 notification-date">02.08.2024</p>
                </div>
                <div>
                    <a href="javascript:void(0)" class="view-details-link" (click)="toggleOrderDetails()">View details</a>
                </div>
            </div>
            <hr>
            <div class="notification-item d-flex align-items-center">
                <div>
                    <img src="../../assets/images/johnsonoil.svg" alt="Order Image" class="order-img">
                </div>
                <div class="d-block flex-grow-1 mx-3">
                    <p class="mb-1 notification-text">Your order has been received successfully. We will reach out to you
                        soon</p>
                    <p class="mb-0 notification-date">02.08.2024</p>
                </div>
                <div>
                    <a href="javascript:void(0)" class="view-details-link" (click)="toggleOrderDetails()">View details</a>
                </div>
            </div>
            <hr>
            <div class="notification-item d-flex align-items-center">
                <div>
                    <img src="../../assets/images/johnsonoil.svg" alt="Order Image" class="order-img">
                </div>
                <div class="d-block flex-grow-1 mx-3">
                    <p class="mb-1 notification-text">Your order has been received successfully. We will reach out to you
                        soon</p>
                    <p class="mb-0 notification-date">02.08.2024</p>
                </div>
                <div>
                    <a href="javascript:void(0)" class="view-details-link" (click)="toggleOrderDetails()">View details</a>
                </div>
            </div>
        </div>
        <!-- for mobile -->
        <div class="d-lg-none d-block">
            <div class="notification-item row">
                <div class="col-2">
                    <img src="../../assets/images/johnsonoil.svg" alt="Order Image" class="order-img">
                </div>
                <div class="col-10">
                    <p class="mb-1 notification-mobile-text">Your order has been received successfully. We will reach out to you
                        soon</p>
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mb-0 notification-date">02.08.2024</p>
                    <a href="javascript:void(0)" class="view-details-mobile-link" (click)="toggleOrderDetails()">View details</a>
                </div>
            </div>  
            </div>
            <hr>
            <div class="notification-item row">
                <div class="col-2">
                    <img src="../../assets/images/johnsonoil.svg" alt="Order Image" class="order-img">
                </div>
                <div class="col-10">
                    <p class="mb-1 notification-mobile-text">Your order has been received successfully. We will reach out to you
                        soon</p>
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mb-0 notification-date">02.08.2024</p>
                    <a href="javascript:void(0)" class="view-details-mobile-link" (click)="toggleOrderDetails()">View details</a>
                </div>
            </div>  
            </div>
            <hr>
            <div class="notification-item row">
                <div class="col-2">
                    <img src="../../assets/images/johnsonoil.svg" alt="Order Image" class="order-img">
                </div>
                <div class="col-10">
                    <p class="mb-1 notification-mobile-text">Your order has been received successfully. We will reach out to you
                        soon</p>
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mb-0 notification-date">02.08.2024</p>
                    <a href="javascript:void(0)" class="view-details-mobile-link" (click)="toggleOrderDetails()">View details</a>
                </div>
            </div>  
            </div>
        </div>
        <hr>
    </div>
    <div class="signout-overlay" *ngIf="showSignoutPopup">
        <div class="signout-content">
            <div class="sign">
                <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
                <div class="popup-message">Do you want to logout your account</div>
            </div>
            <hr class="hr-line">
            <div class="popup-actions">
                <button class="btn signout-btn" (click)="toggleSignoutPopup()">No</button>
                <button class="btn signout-btn">Yes</button>
            </div>
        </div>
    </div>
</div>
<app-play-store></app-play-store>
 