import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from '../../environments/environment.development';
import {jwtDecode} from 'jwt-decode';
import { Router } from '@angular/router';


export interface loginResponse {
  locations: any;
  token: string,
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // login
searchKey:string =''
public accessToken = new BehaviorSubject<string | null>(null);
  public userName = new BehaviorSubject<string | null>(null);
  public userInfo = new BehaviorSubject<any>(null);
  public UserType = new BehaviorSubject<string | null>(null);
  public UserAction = new BehaviorSubject<any>(null);
  public viewHeader: boolean = true;

  constructor(private dialog: MatDialog,private http: HttpClient,private router: Router) {
    // banner-image close
    this.dialog.afterOpened.subscribe(() => {
      document.body.classList.add('no-scroll');
      
    });
    this.dialog.afterAllClosed.subscribe(() => {
      document.body.classList.remove('no-scroll');
    });
    // login
    this.initializeAuthState();
    // this.accessToken.next(localStorage.getItem('accessToken') || null);
    
    // this.userName.next(localStorage.getItem('userName') || null)
    // if (localStorage.getItem('accessToken')) {
    //    this.userInfo.next(this.decodeToken(this.accessToken.getValue()))
       
    //   }
    // this.UserType.next(localStorage.getItem('UserType') || null)
  }

  private initializeAuthState() {
    const token = localStorage.getItem('accessToken');
    const userName = localStorage.getItem('userName');
    const userType = localStorage.getItem('UserType');

    if (token) {
      this.accessToken.next(token);
      this.userInfo.next(this.decodeToken(token));
    }
    this.userName.next(userName || null);
    this.UserType.next(userType || null);
  }

isSubmitting = false;

// decodeToken(token: string) { 
//   if(token&&token!=='undefined')
//   return jwtDecode(token);
// }
decodeToken(token: string): any {
  return jwtDecode(token);
}
	
login(credentials: { emailOrphone: string; password: string; rememberme: boolean }) {
  const data: any = { ...credentials, appToken: localStorage.getItem('appToken') || null };
  return this.http
    .post<loginResponse>(`${environment.apiUrl}/users/login`, data)
    .pipe(
      map((response: any) => {
        const token = response.token;
        const decodedToken = this.decodeToken(token);

        // Store token and user data in localStorage and BehaviorSubjects
        localStorage.setItem('accessToken', token);
        localStorage.setItem('userName', decodedToken.firstname);
        localStorage.setItem('UserType', 'user');

        this.accessToken.next(token);
        this.userInfo.next(decodedToken);
        this.userName.next(decodedToken.firstname);
        this.UserType.next('user');
        this.UserAction.next(response.action);
      })
    );
}

validateGoogleUserSignIn(token:any){
  let data:any = token;
  data.userType = "user";
      // if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
      //   data.appToken = localStorage.getItem('appToken');
      // }
  return this.http.post<loginResponse>(environment.apiUrl + '/users/google-login', data)
  .pipe( map((response: any) => {
      const token = response.token;
      const decodedToken = this.decodeToken(token);
      localStorage.setItem('accessToken', token);
      localStorage.setItem('userName', decodedToken.firstname);
      localStorage.setItem('UserType', 'user');

      this.accessToken.next(token);
      this.userInfo.next(decodedToken);
      this.userName.next(decodedToken.firstname);
      this.UserType.next('user');
      this.UserAction.next(response.action);

  }));
}


logout() {
  localStorage.clear();
  this.userName.next(null);
  this.accessToken.next(null);
  this.userInfo.next(null);
  this.UserType.next(null);
  this.router.navigate(['/login']);
}

// getDataById(id: any) {
//   return this.http.get(environment.apiUrl + `/users/get?id=${id}`);
// }


// update(credentials: { id :number,firstName: string, lastName: string, email: string }) { 
//   return this.http.post<loginResponse>(environment.apiUrl + '/users/update', credentials, { responseType: 'json' }).pipe(map((data: any) => {

//     localStorage.setItem('userName',data?.users?.firstname);
//     this.userName.next(data?.users?.firstname);
// })); }

// validateGoogleUserSignIn(token:any){
//   let data:any = token;
//       if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
//         data.appToken = localStorage.getItem('appToken');
//       }
//   return this.http.post<loginResponse>(environment.apiUrl + '/users/googleSignIn', data, { responseType: 'json' }).pipe(map((data: any) => {
//     localStorage.setItem('accessToken', data.token);
//     localStorage.setItem('userName',data?.users?.firstname);
//     localStorage.setItem('UserType',"user");
//     this.UserType.next('user');
//     this.userName.next(data?.users?.firstname)
//     this.userInfo.next(this.decodeToken(data.token));
//   }));
// }

// validateWebAppleLogin(data:any){
//   let datas:any = data;
//   if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
//     data.appToken = localStorage.getItem('appToken');
//   }
//   return this.http.post<loginResponse>(environment.apiUrl + '/users/webAppleSignIn', datas, { responseType: 'json' }).pipe(map((data: any) => {
//     localStorage.setItem('accessToken', data.token);
//     localStorage.setItem('userName',data?.users?.firstname);
//     localStorage.setItem('UserType',"user");
//     this.UserType.next('user');
//     this.userName.next(data?.users?.firstname)
//     this.userInfo.next(this.decodeToken(data.token));
//   }));
// }



}


